import React, { useEffect } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import ChevronRight from 'assets/icons/chevron-right.svg';
import { ArticleContent } from 'components/Pages/Shared/ArticleContent';
import Image from 'next/image';

export const PhotoCarouselItem = ({
	image,
	title,
	content,
	carousel,
	innerBlocks,
	type = 'image',
}) => {
	const itemRef = React.createRef();

	const showNext = () => carousel?.next();
	const showPrev = () => carousel?.previous();

	// Add ResizeObserver to listen for resize events on each item
	useEffect(() => {
		const resizeObserver = new ResizeObserver(() => {
			if (carousel) {
				carousel.reloadCells();
			}
		});

		if (itemRef.current) {
			resizeObserver.observe(itemRef.current);
		}

		return () => {
			if (itemRef.current) {
				resizeObserver.unobserve(itemRef.current);
			}
		};
	}, [carousel, itemRef]);

	return (
		<div className="sb-photo-carousel__item sb-photo-carousel-item" ref={itemRef}>
			<div className="sb-photo-carousel-item__media">
				{type === 'image' && (
					<Image
						src={image?.url}
						alt={image?.alt}
						height={image?.sizes?.full?.height}
						width={image?.sizes?.full?.width}
					/>
				)}
				{type === 'embed' && <ArticleContent blocks={innerBlocks} post={{ content: {} }} />}
			</div>
			<div className="sb-photo-carousel-item__content">
				<ul className="sb-photo-carousel-item__nav">
					<li className="sb-photo-carousel-item__nav-item">
						<button
							className="sb-photo-carousel-item__nav-button sb-photo-carousel-item__nav-button--prev"
							aria-label="Go to previous slide"
							onClick={showPrev}
						>
							<ChevronRight />
						</button>
					</li>
					<li className="sb-photo-carousel-item__nav-item">
						<button
							className="sb-photo-carousel-item__nav-button sb-photo-carousel-item__nav-button--next"
							aria-label="Go to next slide"
							onClick={showNext}
						>
							<ChevronRight />
						</button>
					</li>
				</ul>
				<h3 className="sb-photo-carousel-item__title">{title}</h3>
				{content && (
					<p
						className="sb-photo-carousel-item__excerpt"
						dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
					/>
				)}
			</div>
		</div>
	);
};

import PropTypes from 'prop-types';

const SmallCapsLink = ({ href, label, accessibleLabel, opensInNewTab = false }) => {
	return (
		<a
			href={href}
			className="small-caps-cta"
			target={opensInNewTab ? '_blank' : '_self'}
			rel={opensInNewTab ? 'noopener noreferrer' : ''}
		>
			<span className="small-caps-cta__text">
				<span>{label}</span>
				{accessibleLabel && <span className="visually-hidden">{accessibleLabel}</span>}
			</span>
			<svg
				className="small-caps-cta__icon"
				xmlns="http://www.w3.org/2000/svg"
				width="5"
				height="9"
				viewBox="0 0 5 9"
			>
				<path
					fill="currentColor"
					d="M.2 8.2a.47.47 0 0 0 .66.66l4-4a.47.47 0 0 0 0-.66L.8.14A.47.47 0 1 0 .14.8l3.73 3.73L.2 8.2Z"
				/>
			</svg>
		</a>
	);
};

SmallCapsLink.propTypes = {
	href: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	accessibleLabel: PropTypes.string,
};

export default SmallCapsLink;

/* eslint-disable no-unused-vars */
import React from 'react';
import { ArticleContent } from 'components/Pages/Shared/ArticleContent';

export const HorizontalScrollItem = ({ innerBlocks }) => {
	return (
		<ArticleContent
			className="sb-xscroller__item"
			blocks={innerBlocks}
			post={{ content: {} }}
		/>
	);
};

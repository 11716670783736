import React from 'react';
import { __ } from '@wordpress/i18n';

export const NoResults = (props) => {
	const { menu, query, showTips = true } = props;
	let taxonomyTerms = [];

	// Filter out on the primary taxonomy terms
	if (menu && menu.length) {
		taxonomyTerms = menu.filter((item) => {
			return item.type === 'taxonomy';
		});
	}

	return (
		<div className="sb-search__no-results">
			{!query ? (
				<h2 style={{ textAlign: 'left' }}>{__('No results found', 'starbucks')}</h2>
			) : (
				<h2 style={{ textAlign: 'left' }}>
					{__('No results found for', 'starbucks')} {`"${query}"`}
				</h2>
			)}

			{showTips && (
				<>
					<h3>{__('Search tips', 'starbucks')}</h3>
					<ul>
						<li>{__('Check your spelling.', 'starbucks')}</li>
						<li>{__('Broaden your search by using general words.', 'starbucks')}</li>
						<li>{__('Browse by category instead.', 'starbucks')}</li>
					</ul>
				</>
			)}

			{taxonomyTerms.length > 0 && (
				<>
					<h3>{__('Browse by Category', 'starbucks')}</h3>
					{taxonomyTerms.length > 0 &&
						taxonomyTerms?.map((item) => (
							<p key={item.ID}>
								<a href={item.url}>{item.title}</a>
							</p>
						))}
				</>
			)}
		</div>
	);
};


import classnames from 'classnames';

export const LeadershipPosition = (props) => {

	const {
    post: { meta: { job_title } },
    className: classNameFromEditor,
  } = props;

	const className = classnames('sbx-leadership-position', classNameFromEditor);

	return (
		<p className={className}>{job_title}</p>
	);
};

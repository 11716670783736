import classnames from 'classnames';
import { RawHTML } from 'components/Html/Raw/html';

export const BlockAudio = ({
	autoplay,
	caption,
	loop,
	preload,
	src,
	anchor,
	clientId,
	align,
	className: classNameFromEditor,
}) => {
	const className = classnames('wp-block-audio', classNameFromEditor, {
		[`align${align}`]: align,
	});

	return (
		src && (
			<figure className={className} id={anchor || clientId}>
				<audio
					controls="controls"
					src={src}
					autoPlay={autoplay}
					loop={loop}
					preload={preload}
				/>
				{caption && <RawHTML value={caption} tagName="figcaption" />}
			</figure>
		)
	);
};

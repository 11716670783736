import React, { useState } from 'react';
import Share from 'assets/icons/share.svg';

// i18n
import { __ } from '@wordpress/i18n';
import { PullquoteShareList } from './List';

export const HighlightShare = ({ quote, url }) => {
	const [socialOpen, setSocialOpen] = useState(false);

	const triggerClick = (e) => {
		e.preventDefault();
		setSocialOpen(!socialOpen);
	};

	return (
		<>
			{quote}

			<span
				className={`sb-social-share${socialOpen ? ' sb-social-share--open' : ''}`}
				aria-labelledby="sb-social-share-button"
				role="region"
			>
				<span className="sb-social-share__wrapper">
					<button
						id="sb-social-share-button"
						className="sb-social-share__button"
						aria-expanded="true"
						onClick={triggerClick}
					>
						<span className="screen-reader-text">
							{__('Share This Article', 'starbucks')}
						</span>
						<Share className="sb-icon sb-social-share__icon" />
					</button>

					<PullquoteShareList quote={quote} url={url} label="Text Snippet" />
				</span>
			</span>
		</>
	);
};

import classnames from 'classnames';
import { getColorClassName } from 'utils/post';
import { RawHTML } from 'components/Html/Raw/html';

export const BlockButton = ({
	backgroundColor,
	borderRadius,
	customBackgroundColor,
	customTextColor,
	customGradient,
	linkTarget,
	gradient,
	rel,
	text,
	textColor,
	title,
	url,
	className,
	anchor,
	align,
}) => {
	const textClass = getColorClassName('color', textColor);
	const backgroundClass =
		!customGradient && getColorClassName('background-color', backgroundColor);

	const buttonClasses = classnames('wp-block-button__link', {
		'has-text-color': textColor || customTextColor,
		[textClass]: textClass,
		'has-background': backgroundColor || customBackgroundColor || customGradient || gradient,
		[backgroundClass]: backgroundClass,
		'no-border-radius': borderRadius === 0,
	});

	const buttonStyle = {
		background: customGradient || undefined,
		backgroundColor:
			backgroundClass || customGradient || gradient ? undefined : customBackgroundColor,
		color: textClass ? undefined : customTextColor,
		borderRadius: borderRadius ? `${borderRadius}px` : undefined,
	};

	const cn = classnames('wp-block-button', className, [align ? `align${align}` : '']);

	// The use of a `title` attribute here is soft-deprecated, but still applied
	// if it had already been assigned, for the sake of backward-compatibility.
	// A title will no longer be assigned for new or updated button block links.

	return (
		<div className={cn}>
			<RawHTML
				tagName="a"
				id={anchor}
				className={buttonClasses}
				href={url}
				title={title}
				style={buttonStyle}
				value={text}
				target={linkTarget}
				rel={rel}
			/>
		</div>
	);
};

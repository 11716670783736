import Link from 'next/link';
import classnames from 'classnames';
import { getColorClassName } from 'utils/post';

export const PostDate = (props) => {
	const {
		className: classNameFromEditor,
		fontFamily,
		fontSize,
		isLink,
		linkTarget,
		post,
		rel,
		textAlign,
		textColor,
		displayType = 'date',
	} = props;

	const textClass = getColorClassName('color', textColor);

	const className = classnames('wp-block-post-date', classNameFromEditor, {
		'has-text-color': textColor,
		[`has-text-align-${textAlign}`]: textAlign,
		[`has-font-size-${fontSize}`]: fontSize,
		[`has-${fontFamily}-font-family`]: fontFamily,
		[textClass]: textClass,
	});

	const displayDate = displayType === 'modified' ? post.date_modified : post.date;

	const url = new URL(post.link);

	return (
		<div className={className}>
			<time dateTime={post.date}>
				{isLink ? (
					<Link href={url.pathname} target={linkTarget} rel={rel}>
						{displayDate}
					</Link>
				) : (
					displayDate
				)}
			</time>
		</div>
	);
};

import { createPortal } from 'react-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Share from 'assets/icons/share.svg';
import CloseIcon from 'assets/icons/plus.svg';
import { __ } from '@wordpress/i18n';
import DownloadableImagesModalOpenContext from 'context/DownloadableImagesModalOpenContext';
import DownloadButtonWithIcon from 'components/DownloadButtonWithIcon';

/**
 * Download button toggling the asset download modal.
 */
const DownloadButton = ({ isGallery, wrapperDiv }) => {
	const openModalContext = useContext(DownloadableImagesModalOpenContext);
	const toggleButton = useRef();
	const [open, setOpen] = useState(false);
	const rootElement = useRef({ offsetHeight: 0 });

	// Caption in galleries is absolutely positioned over the image so we need
	// to place the button just above.
	const [positionStyle, setPositionStyle] = useState(undefined);
	const handleResize = () => {
		const image = wrapperDiv.querySelector('img');
		const button = wrapperDiv.querySelector('.download-button');
		if (isGallery) {
			const caption = wrapperDiv.parentNode.querySelector('figcaption');

			if (caption) {
				setPositionStyle({ bottom: caption.offsetHeight });
			}
		}

		button.setAttribute('style', `left: ${image.offsetLeft}px`);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		const image = wrapperDiv.querySelector('img');

		image.addEventListener('load', handleResize);
		if (image.complete) {
			handleResize();
		}

		return () => {
			window.removeEventListener('resize', handleResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openModal = () => {
		openModalContext.setIsDownloadAssetsModalOpen(true);
		openModalContext.setFocusElement(null);
		setOpen(false);
	};

	return (
		<span
			className={`download-image-button sb-social-share${
				open ? ' sb-social-share--open' : ''
			}`}
			aria-labelledby="download-image-button"
			role="region"
			ref={rootElement}
			style={positionStyle}
		>
			<span className="download-image-button__wrapper">
				<button
					id="download-image-button"
					className={`download-image-button__button ${
						open ? ' download-image-button__close' : ''
					}`}
					aria-expanded={open ? 'true' : 'false'}
					onClick={() => {
						setOpen(!open);
					}}
				>
					<span className="screen-reader-text">
						{__('Share This Image', 'starbucks')}
					</span>
					{open ? (
						<CloseIcon className="sb-icon download-image-button__icon" />
					) : (
						<Share className="sb-icon download-image-button__icon" />
					)}
				</button>
				{open && (
					<ul className="sb-social-share__list">
						<li className="sb-social-share__item">
							<DownloadButtonWithIcon
								onClick={openModal}
								ref={toggleButton}
								label={__('Download', 'starbucks')}
							/>
						</li>
					</ul>
				)}
			</span>
		</span>
	);
};

/**
 * Appends a download modal toggle button to every downloadable image on the page.
 *
 * @param {object} props
 */
export const DownloadableImages = ({ children, post }) => {
	const [downloadableImages, setDownloadableImages] = useState([]);

	/**
	 * Wraps the image in a div for easier positioning of the button.
	 *
	 * @param {HTMLImageElement} image
	 */
	const wrapImageInDiv = (image) => {
		const wrapperDiv = document.createElement('div');
		wrapperDiv.setAttribute('class', 'downloadable-image-wrapper');
		image.parentNode.insertBefore(wrapperDiv, image.nextSibling);
		wrapperDiv.appendChild(image.parentNode.removeChild(image));

		return wrapperDiv;
	};

	useEffect(() => {
		// Gather downloadable asset IDs from meta.
		const imageIds =
			post && 'meta' in post && 'downloadable_assets' in post.meta
				? post.meta.downloadable_assets.map(({ id }) => id)
				: [];

		// Use CSS class to select the images.
		const selector = imageIds.map((id) => `.wp-image-${id}`).join();
		if (!selector) {
			return;
		}

		setDownloadableImages(
			[...document.querySelectorAll(selector)].map((image) => {
				const wrapperDiv = wrapImageInDiv(image);

				const buttonContainerEl = document.createElement('div');
				buttonContainerEl.setAttribute('class', 'download-button');

				// Insert the button after the image.
				wrapperDiv.insertBefore(buttonContainerEl, image.nextSibling);

				return {
					buttonContainerEl,
					isGallery:
						wrapperDiv.parentNode.parentNode.classList.contains('blocks-gallery-item'),
					wrapperDiv,
				};
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [post?.meta?.downloadable_assets]);

	// Buttons need to be rendered via a portal instead of ReactDOM.render
	// because they are rendered into external DOM elements created in
	// useEffect above. Being in a portal allows them to access the Redux
	// store. ReactDOM.render would put them outside the component tree that
	// has access to the Redux Provider at the app root.
	return (
		<>
			{children}
			{downloadableImages.map(({ buttonContainerEl, isGallery, wrapperDiv }) =>
				createPortal(
					<DownloadButton isGallery={isGallery} wrapperDiv={wrapperDiv} />,
					buttonContainerEl,
				),
			)}
		</>
	);
};

import React, { useEffect, useRef } from 'react';
import Image from 'next/image';
import { gsap, Power4 } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
import classnames from 'classnames';
import { RawHTML } from 'components/Html/Raw/html';
import { safeALT } from 'utils/safe-alt';

export const BlockImage = (props) => {
	const {
		align,
		alt,
		anchor,
		animated = false,
		aspectRatio,
		caption,
		className: classNameFromEditor,
		height: resizedHeight,
		href,
		id,
		linkClass,
		linkTarget,
		mediaHeight,
		mediaWidth,
		rel,
		scale,
		sizeSlug,
		title,
		url,
		width: resizedWidth,
	} = props;

	const newRel = rel || undefined;

	const classes = classnames('wp-block-image', classNameFromEditor, {
		[`align${align}`]: align,
		[`size-${sizeSlug}`]: sizeSlug,
		'is-resized': resizedWidth || resizedHeight,
		animated,
	});

	const imageRef = useRef(null);

	gsap.registerPlugin(ScrollTrigger);

	useEffect(() => {
		if (animated) {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: imageRef.current,
					start: 'top bottom-=100',
					end: 'bottom top',
				},
			});

			tl.fromTo(
				imageRef.current,
				{ opacity: 0, y: 100 },
				{ opacity: 1, y: 0, duration: 1, ease: Power4.easeOut },
			);
		}
	}, [animated]);

	let sizes;

	if (align === 'full') {
		sizes = '100vw';
	} else if (align === 'wide') {
		sizes = '(max-width: 1028px) 100vw, 1028px'; // --w-content-wider
	} else if (resizedWidth) {
		sizes = `(max-width: ${resizedWidth}) 100vw, ${resizedWidth}`;
	} else {
		sizes = '(max-width: 700px) 100vw, 700px'; // --w-content-normal
	}

	// If align is set to full or wide, set the layout to responsive
	let style = {
		...(resizedWidth && { width: resizedWidth }),
		...(resizedHeight && { height: resizedHeight }),
		...(aspectRatio && { aspectRatio: aspectRatio }),
		...(scale && { objectFit: scale }),
	};

	const image = (
		<Image
			alt={safeALT(alt)}
			className={id ? `wp-image-${id}` : null}
			height={mediaHeight}
			loading="lazy"
			sizes={sizes}
			src={url}
			style={style}
			title={title}
			width={mediaWidth}
		/>
	);

	const figure = (
		<>
			{href ? (
				<a className={linkClass} href={href} target={linkTarget} rel={newRel}>
					{image}
				</a>
			) : (
				image
			)}
			{caption && (
				<RawHTML
					className="wp-block-image__caption"
					value={caption}
					tagName="figcaption"
				/>
			)}
		</>
	);

	// if isResized add width as inline style
	let figureStyle = {
		...(resizedWidth && { width: resizedWidth, maxWidth: '100%' }),
	};

	return (
		<figure className={classes} id={anchor} ref={imageRef} style={figureStyle}>
			{figure}
		</figure>
	);
};

import React from 'react';
import CaretDown from 'assets/icons/caret-down.svg';
import DOMPurify from 'isomorphic-dompurify';

/**
 * Select Dropdown.
 *
 * @param {object} props
 */
export const Select = (props) => {
	const { id, label, name, onChange, options, showLabel, value, nullValue } = props;

	return (
		<div className="sb-select-control" key={id}>
			<label
				htmlFor={id}
				className={`sb-select-control__label ${!showLabel ? ' screen-reader-text' : ''}`}
			>
				{label}
			</label>
			<div className="sb-select-control__input-wrap">
				<select
					className="sb-select-control__input"
					id={id}
					key={id}
					name={name}
					onChange={(e) => onChange(e)}
					value={value}
				>
					{nullValue && <option value="null">{nullValue}</option>}
					{options.map(({ value, label }) => {
						return (
							<option
								value={value}
								key={value}
								dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }}
							/>
						);
					})}
				</select>
				<span className="sb-select-control__caret-icon">
					<CaretDown fill="currentColor" />
				</span>
			</div>
		</div>
	);
};

import classnames from 'classnames';
import { BlockPostTemplate } from '../block-post-template';

export const BlockQuery = (props) => {
	const {
		className: classNameFromEditor,
		innerBlocks: [
			{
				attributes: { layout, isEntireCardClickable },
				innerBlocks: blocks,
			},
		],
		posts,
	} = props;

	const className = classnames('wp-block-query', classNameFromEditor);

	return (
		<div className={className}>
			<BlockPostTemplate
				posts={posts}
				blocks={blocks}
				layout={layout}
				isEntireCardClickable={isEntireCardClickable}
			/>
		</div>
	);
};

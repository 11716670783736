/**
 * Starbucks Block Parser.
 * Add blocks to the switch statement to convert using this parser.
 * Any block not within the switch statement will be rendered using the legacy parser.
 */

import { useMemo } from 'react';
import usePackages from 'hooks/usePackages';
import { BLOCK_NAMESPACE, replaceBlocksWithComponents } from 'utils/blocks';
import { BlockFreeform } from 'components/Blocks/core/block-freeform';
import { BlockAudio } from 'components/Blocks/core/block-audio';
import { BlockButton } from 'components/Blocks/core/block-button';
import { BlockButtons } from 'components/Blocks/core/block-buttons';
import { BlockColumns } from 'components/Blocks/core/block-columns';
import { BlockDetails } from 'components/Blocks/core/block-details';
import { BlockHeading } from 'components/Blocks/core/block-heading';
import { BlockImage } from 'components/Blocks/core/block-image';
import { BlockList } from 'components/Blocks/core/block-list';
import { BlockParagraph } from 'components/Blocks/core/block-paragraph';
import { BlockEmbed } from 'components/Blocks/core/block-embed';
import { BlockPullquote } from 'components/Blocks/core/block-pullquote';
import { BlockFile } from 'components/Blocks/core/block-file';
import { BlockSeparator } from 'components/Blocks/core/block-separator';
import { BlockHTML } from 'components/Blocks/core/block-html';
import { BlockGallery } from 'components/Blocks/core/block-gallery';
import { BlockSpacer } from 'components/Blocks/core/block-spacer';
import { BlockMediaText } from 'components/Blocks/core/block-media-text';
import { BlockGroup } from 'components/Blocks/core/block-group';
import { BlockQuery } from 'components/Blocks/core/block-query';
import { BlockPostTemplate } from 'components/Blocks/core/block-post-template';
import { PostDate } from 'components/Blocks/core/block-post-template/post-date';
import { PostTitle } from 'components/Blocks/core/block-post-template/post-title';
import { PostFeaturedImage } from 'components/Blocks/core/block-post-template/post-featured-image';
import { HorizontalScrollWrapper } from 'components/Blocks/HorizontalScrollWrapper';
import { AnimatedOffsetBackground } from 'components/Blocks/AnimatedOffsetBackground';
import { AnimatedSVGContainer } from 'components/Blocks/AnimatedSVGContainer';
import { IconList } from 'components/Blocks/IconList';
import { ArticleHero } from 'components/Blocks/ArticleHero';
import { ArticleGallery } from 'components/Blocks/ArticleGallery';
import { PhotoCarousel } from 'components/Blocks/PhotoCarousel';
import { ParallaxBackgroundContainer } from 'components/Blocks/ParallaxBackgroundContainer';
import { Collage } from 'components/Blocks/Collage';
import { StoryGrid } from 'components/Blocks/StoryGrid';
import { Banner } from 'components/Blocks/Banner';
import { PullQuoteImage } from 'components/Blocks/PullQuoteImage';
import { ProductRecommend } from 'components/Blocks/ProductRecommend';
import { NewsletterPressCenter } from 'components/Blocks/NewsletterPressCenter';
import { PressCenter } from 'components/Blocks/press-photos/press-photos';
import { Recipe } from 'components/Blocks/Recipe';
import { SocialInsert } from 'components/Blocks/SocialInsert';
import { StoryMap } from 'components/Blocks/StoryMap';
import { ScrollingContentWrapper } from 'components/Blocks/ScrollingContentWrapper';
import { ImageElement } from 'components/Blocks/Image';
import { LinkedIn } from 'components/Blocks/LinkedIn';
import StorytellingArchive from 'components/Blocks/StorytellingArchive';
import { StorytellingFeaturedContent } from 'components/Blocks/StorytellingFeaturedContent';
import { Slider } from 'components/Blocks/Slider';
import { LeadershipPosition } from 'components/Blocks/LeadershipPostion';
import { OverlayCard } from 'components/Blocks/OverlayCard';
import { StickyAudio } from 'components/Blocks/StickyAudio';
import { ExpandableContent } from 'components/Blocks/ExpandableContent';
import { TranslatedContent } from 'components/Blocks/TranslatedContent';
import { TranslatedContentItem } from 'components/Blocks/TranslatedContentItem';
import { StoreSearch } from 'components/Blocks/StoreSearch';

import { blocks as blockComponents } from 'components/Blocks';
import RelatedContentInterrupter, {
	hash as interrupterHash,
} from 'components/Blocks/RelatedContentInterrupter';

export const ArticleContent = ({
	blocks: passedBlocks = null,
	post,
	className = 'article__content',
	wrapperProps = {},
	posts,
	images,
	pagination,
	showTerms,
	style,
}) => {
	const blocks = useMemo(() => {
		if (passedBlocks) {
			return passedBlocks;
		}

		if (post.content.blocks && post.content.blocks.length > 0) {
			return post.content.blocks;
		}

		if (post.meta.editor_blocks && post.meta.editor_blocks.length > 0) {
			return post.meta.editor_blocks;
		}

		return null;
	}, [post.id]);

	const blockCount = blocks ? blocks.length : 0;

	if (blockCount && blockCount > 1) {
		// Insert related posts interrupter if it doesnt already exist.
		const relatedInterrupterName = 'starbucks-blocks/related-posts-interrupter';
		const relatedInterrupter = {
			attributes: {},
			clientId: interrupterHash,
			innerBlocks: [],
			name: relatedInterrupterName,
		};

		const exists = blocks.find((block) => block.name === relatedInterrupterName);

		if (!exists) {
			blocks.splice(Math.floor(blockCount / 2), 0, relatedInterrupter);
		}
	}

	const ref = usePackages(post);

	return (
		<div className={className} ref={ref} style={style}>
			{blocks ? (
				blocks
					.map(({ attributes, clientId, innerBlocks, name, ...block }) => {
						switch (name) {
							/**
							 * Core Blocks
							 */

							case 'core/audio': {
								return <BlockAudio key={clientId} {...attributes} />;
							}

							case 'core/button': {
								return <BlockButton key={clientId} {...attributes} />;
							}

							case 'core/buttons': {
								return (
									<BlockButtons
										key={clientId}
										innerBlocks={innerBlocks}
										{...attributes}
									/>
								);
							}

							case 'core/columns': {
								return (
									<BlockColumns
										key={clientId}
										innerBlocks={innerBlocks}
										{...attributes}
										post={post}
									/>
								);
							}

							case `core/details`: {
								return (
									<BlockDetails
										key={clientId}
										post={post}
										innerBlocks={innerBlocks}
										{...attributes}
									/>
								);
							}

							case 'core/group': {
								return (
									<BlockGroup
										key={clientId}
										innerBlocks={innerBlocks}
										{...attributes}
									/>
								);
							}

							case 'core/embed':
							case 'core-embed/facebook':
							case 'core-embed/instagram':
							case 'core-embed/soundcloud':
							case 'core-embed/spotify':
							case 'core-embed/twitter':
							case 'core-embed/vimeo':
							case 'core-embed/youtube': {
								// Make sure Facebook and Instagram are setup correctly (thanks Jetpack)
								if (
									attributes.providerNameSlug &&
									attributes.providerNameSlug === 'embed-handler'
								) {
									if (name === 'core-embed/facebook') {
										attributes.providerNameSlug = 'facebook';
									}

									if (name === 'core-embed/instagram') {
										attributes.providerNameSlug = 'instagram';
									}
								}

								return (
									<BlockEmbed
										key={clientId}
										embedHtml={block.embed}
										{...attributes}
									/>
								);
							}

							case 'core/file': {
								return <BlockFile key={clientId} {...attributes} />;
							}

							/*
				case 'core/freeform': {
				return <BlockFreeform key={clientId} {...attributes} />;
				}
				*/

							case 'core/gallery': {
								return (
									<BlockGallery
										key={clientId}
										innerBlocks={innerBlocks}
										{...attributes}
									/>
								);
							}

							case 'core/heading': {
								return <BlockHeading key={clientId} {...attributes} />;
							}

							case 'core/html': {
								return <BlockHTML key={clientId} {...attributes} />;
							}

							case 'core/image': {
								return <BlockImage key={clientId} {...attributes} />;
							}

							case 'core/list': {
								return (
									<BlockList
										key={clientId}
										{...attributes}
										innerBlocks={innerBlocks}
									/>
								);
							}

							case 'core/media-text': {
								return (
									<BlockMediaText
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										{...attributes}
									/>
								);
							}

							case 'core/paragraph': {
								return <BlockParagraph key={clientId} {...attributes} />;
							}

							case 'core/pullquote': {
								return <BlockPullquote key={clientId} {...attributes} />;
							}

							case 'core/separator': {
								return <BlockSeparator key={clientId} {...attributes} />;
							}

							case 'core/spacer': {
								return <BlockSpacer key={clientId} {...attributes} />;
							}

							case 'core/query': {
								return (
									<BlockQuery
										key={clientId}
										{...attributes}
										innerBlocks={innerBlocks}
									/>
								);
							}
							case 'core/post-template': {
								return (
									<BlockPostTemplate
										key={clientId}
										{...attributes}
										innerBlocks={innerBlocks}
									/>
								);
							}

							case 'core/post-title': {
								return <PostTitle key={clientId} {...attributes} post={post} />;
							}

							case 'core/post-date': {
								return <PostDate key={clientId} {...attributes} post={post} />;
							}

							case 'core/post-featured-image': {
								return (
									<PostFeaturedImage key={clientId} {...attributes} post={post} />
								);
							}

							case 'starbucks-blocks/leadership-position': {
								return <LeadershipPosition key={clientId} {...attributes} post={post} />;
							}

							/**
							 * Custom Blocks
							 */
							case `${BLOCK_NAMESPACE}/related-posts-interrupter`: {
								return <RelatedContentInterrupter key={clientId} />;
							}

							case `${BLOCK_NAMESPACE}/horizontal-scroll-wrapper`: {
								return (
									<HorizontalScrollWrapper
										key={clientId}
										innerBlocks={innerBlocks}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/animated-offset-background`: {
								return (
									<AnimatedOffsetBackground
										key={clientId}
										innerBlocks={innerBlocks}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/animated-icons-list`: {
								return (
									<IconList
										key={clientId}
										innerBlocks={innerBlocks}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/article-hero`: {
								return (
									<ArticleHero
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/article-gallery`: {
								return (
									<ArticleGallery
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/scrolling-content-wrapper`: {
								return (
									<ScrollingContentWrapper
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/photo-carousel`: {
								return (
									<PhotoCarousel
										key={clientId}
										id={clientId}
										innerBlocks={innerBlocks}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/linkedin`: {
								return (
									<LinkedIn
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										block={block}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/story-grid`: {
								return (
									<StoryGrid
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										block={block}
										{...attributes}
										images={images}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/banner`: {
								return (
									<Banner
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										block={block}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/collage`: {
								return (
									<Collage
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										block={block}
										attributes={attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/pull-quote-image`: {
								return <PullQuoteImage key={clientId} {...attributes} />;
							}

							case `${BLOCK_NAMESPACE}/product-recommend`: {
								return <ProductRecommend key={clientId} {...attributes} />;
							}

							case `${BLOCK_NAMESPACE}/newsletter-press-center`: {
								return (
									<NewsletterPressCenter
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										block={block}
										attributes={attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/press-photos`: {
								return (
									<PressCenter
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										block={block}
										attributes={attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/recipe`: {
								return (
									<Recipe
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										block={block}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/story-map`: {
								return (
									<StoryMap
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										block={block}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/parallax-background-container`: {
								return (
									<ParallaxBackgroundContainer
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										block={block}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/social-insert`: {
								return <SocialInsert key={clientId} attributes={attributes} />;
							}

							case `${BLOCK_NAMESPACE}/image`: {
								return (
									<ImageElement
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										block={block}
										{...attributes}
										alt=""
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/animated-svg-container`: {
								return (
									<AnimatedSVGContainer
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/storytelling-archive`: {
								return <StorytellingArchive key={clientId} {...attributes} />;
							}

							case `${BLOCK_NAMESPACE}/slider-with-navigation`: {
								return (
									<Slider
										key={clientId}
										innerBlocks={innerBlocks}
										post={post}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/storytelling-featured-content`: {
								return (
									<StorytellingFeaturedContent
										key={clientId}
										post={post}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/overlay-card`: {
								return <OverlayCard key={clientId} post={post} {...attributes} />;
							}

							case `${BLOCK_NAMESPACE}/overlay-card`: {
								return <OverlayCard key={clientId} post={post} {...attributes} />;
							}

							case `${BLOCK_NAMESPACE}/sticky-audio`: {
								return (
									<StickyAudio
										key={clientId}
										post={post}
										innerBlocks={innerBlocks}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/expandable-content`: {
								return (
									<ExpandableContent
										key={clientId}
										post={post}
										innerBlocks={innerBlocks}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/translated-content`: {
								return (
									<TranslatedContent
										key={clientId}
										post={post}
										innerBlocks={innerBlocks}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/translated-content-item`: {
								return (
									<TranslatedContentItem
										key={clientId}
										post={post}
										innerBlocks={innerBlocks}
										{...attributes}
									/>
								);
							}

							case `${BLOCK_NAMESPACE}/store-search`: {
								return (
									<StoreSearch
										key={clientId}
										post={post}
										innerBlocks={innerBlocks}
										{...attributes}
									/>
								)
							}

							default: {
								const Component = blockComponents[name];

								let componentPosts = posts;

								if (
									!posts &&
									attributes &&
									attributes.posts &&
									typeof attributes.posts === 'string'
								) {
									componentPosts = JSON.parse(attributes.posts);
								}

								if (Component) {
									return (
										<Component
											key={clientId}
											attributes={attributes}
											posts={componentPosts}
											post={post}
											images={images}
											pagination={pagination}
											showTerms={showTerms}
										/>
									);
								}
								if (block?.rendered && block.rendered.length > 0) {
									wrapperProps.className = 'sbx-legacy-block';

									// No JSX component? Pass through the legacy parser.
									return replaceBlocksWithComponents(
										block.rendered,
										wrapperProps || {},
										null,
										post,
										componentPosts,
										images,
										pagination,
										showTerms,
									);
								}

								console.log('No component found for block', name);

								return null;
							}
						}
					})
					.filter((renderedBlock) => renderedBlock)
			) : (
				<BlockFreeform content={post.content.rendered} />
			)}
		</div>
	);
};

import Image from 'next/image';
import classnames from 'classnames';

export const PostFeaturedImage = props => {
	const {
		width,
		height,
		className: classNameFromEditor,
		post: {
			thumbnail: { alt, height: mediaHeight, src, width: mediaWidth },
		},
	} = props;

	const className = classnames(
		'wp-block-post-featured-image',
		classNameFromEditor
	);

	let sizes = "(max-width: 1440px) 100vw, 1440px";

	if (width) {
		sizes = `${width}`;
	}

	return (
		<div className={className}>
			<Image
				alt={alt}
				height={mediaHeight}
				src={src}
				sizes={sizes}
				width={mediaWidth}
			/>
		</div>
	);
};

import { __ } from '@wordpress/i18n';
import classNames from 'classnames';
import { ArticleContent } from 'components/Pages/Shared/ArticleContent';

export const TranslatedContentItem = (props) => {
	const {
		className: classNameFromEditor,
		innerBlocks,
		isActive,
		label = __('Read In Default Language', 'starbucks'),
		locale,
		writingDirection,
	} = props;

	const className = classNames('sbx-translated-content__item', classNameFromEditor);

	return (
		<div
			className={className}
			lang={locale}
			dir={writingDirection}
			label={label}
			aria-hidden={!isActive}
		>
			<ArticleContent
				className="article__content"
				blocks={innerBlocks}
				post={{ content: {} }}
			/>
		</div>
	);
};

import { __ } from '@wordpress/i18n';

export default function LegacyDisclaimer(props) {
	return (
		<div {...props} className="legacy-content-disclaimer">
			<p className="legacy-content-disclaimer__text">
				<em>
					{__(
						'This post is from our archives. Please excuse any legacy formatting issues.',
						'starbucks',
					)}
				</em>
			</p>
		</div>
	);
}

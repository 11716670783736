import React, { Component, createContext } from 'react';
import Sticky from 'react-sticky-el';
import { sprintf, __ } from '@wordpress/i18n';
import { decodeEntities } from 'utils/decode-wrapper';
import { getReadingTime, longDateFormat, longDateTimeFormat } from 'utils/date';
import { elInView, hashScrollEvent } from 'utils/scroll';
import Article from 'components/Pages/Shared/Article';
import PageTitle from 'components/Pages/Shared/PageTitle';
import { ArticleContent } from 'components/Pages/Shared/ArticleContent';
import AssetModal from 'components/Modal/Asset';
import SocialShareList from 'components/Social/Share/List';
import ThreeImageElement from 'components/ThreeImageElement';
import PostHero from './Hero';
import { StickySocialShare } from './StickySocialShare';
import Flyout from './Flyout';
import LegacyDisclaimer from './LegacyDisclaimer';
import { StickySubNav } from './StickySubNav';
import { ShareableContent } from './ShareableContent';
import { DownloadableImages } from './DownloadableImages';

export const PostContentContext = createContext();

/**
 * @todo document this function
 */
export class PostContent extends Component {
  articleContentRef;
  articleFooterRef;
  content;
  carousels;
  headerToolsRef;
  socialStickysRef;

  constructor(props) {
    super(props);

    this.headerToolsRef = null;

    this.state = {
      isRelatedPostsInterrupterRendered: false,
      currentPost: props.post,
      renderRelatedPosts: relatedPostsProps => {
        return props.renderRelatedPosts
          ? props.renderRelatedPosts(props.post, relatedPostsProps)
          : null;
      },
      setContext: value => this.setState(value),
      inArticleHeaderToolView: false,
      inArticleFooterView: false,
    };

    this.articleContentRef = React.createRef();
    this.articleFooterRef = React.createRef();
    this.headerToolsRef = React.createRef();
    this.socialStickysRef = React.createRef();
  }

  componentDidMount() {
    this.carousels = [];
    this.checkForCarousels(this.content);

    this.checkForLightboxCarousels(this.content);

    this.checkForWistiaEmbed(this.content);

    this.setState({
      inArticleHeaderToolView: this.getArticleHeaderToolInView(),
      inArticleFooterView: this.getArticleFooterInView(),
    });

    window.addEventListener('scroll', this.handleArticleHeaderToolScroll);
    window.addEventListener('scroll', this.handleArticleFooterScroll);
    hashScrollEvent();
  }

  componentDidUpdate() {
    this.checkForCarousels(this.content);
  }

  componentWillUnmount() {
    this.destroyCarousels();

    window.removeEventListener('scroll', this.handleArticleHeaderToolScroll);
    window.removeEventListener('scroll', this.handleArticleFooterScroll);
  }

  checkForCarousels = (parentNode) => {
    if (!parentNode) {
      return;
    }

    const carousels = parentNode.querySelectorAll(
      '.wp-block-gallery.is-style-carousel'
    );

    if (carousels.length) {
      this.initCarousels(carousels);
    }
  };

  checkForLightboxCarousels = (parentNode) => {
    if (!parentNode) {
      return;
    }

    const carousels = parentNode.querySelectorAll(
      '.wp-block-gallery.is-style-lightbox'
    );

    if (carousels.length) {
      this.initLightboxCarousels(carousels);
    }
  };

  getArticleHeaderToolInView = () => {
    const articleHeaderTools = this.headerToolsRef;
    const socialStick = this.socialStickysRef;

    return elInView(articleHeaderTools, true, 0.09, socialStick);
  };

  getArticleFooterInView = () => {
    const articleFooter = this.articleFooterRef;
    const articleContent = this.articleContentRef;

    return elInView(articleFooter, true, 1.5, articleContent);
  };

  handleArticleHeaderToolScroll = () => {
    const articleHeaderTools = this.headerToolsRef;
    const socialStick = this.socialStickysRef;

    if (this.props.isHomepage) {
      return;
    }

    // Bail if sticky social was alrady viewed
    if (socialStick && socialStick?.classList?.contains('-viewed')) {
      return;
    }

    if (articleHeaderTools) {
      window.requestAnimationFrame(() => {
        this.setState({
          inArticleHeaderToolView: this.getArticleHeaderToolInView(),
        });
      });
    }
  };

  handleArticleFooterScroll = () => {
    const articleContent = this.articleContentRef;
    const articleFooter = this.articleFooterRef;

    // Bail if sticky social was alrady viewed
    if (articleContent && articleContent.classList.contains('-viewed')) {
      return;
    }

    if (articleFooter) {
      window.requestAnimationFrame(() => {
        this.setState({
          inArticleFooterView: this.getArticleFooterInView(),
        });
      });
    }
  };

  initLightboxCarousels = (carousels) => {
    if (!carousels || !carousels.length) {
      return;
    }

    const carouselsArray = [].slice.call(carousels);

    carouselsArray.forEach(carousel => {
      // Prevent re-initializing an already initialized
      // carousel

      const thumbs = carousel.querySelectorAll('.blocks-gallery-item');

      for (let i = 0; i < thumbs.length; i++) {
        // eslint-disable-next-line no-unused-vars
        thumbs[i].addEventListener('click', async e => {
          if (!window.matchMedia('(min-width: 768px)').matches) {
            return;
          }

          const Flickity = (await import('flickity')).default;

          await import('flickity-fullscreen');
          await import('flickity-imagesloaded');

          const thisCarousel = new Flickity(carousel, {
            adaptiveHeight: false,
            imagesLoaded: true,
            initialIndex: i,
            pageDots: false,
            closeEl: true,
            tapToClose: true,
            fullscreen: true,
            on: {
              ready: () => {
                carousel.classList.add('is-visible');
              },
            },
          });

          thisCarousel.viewFullscreen();

          thisCarousel.on('fullscreenChange', function (isFullscreen) {
            if (
              false === isFullscreen &&
              !thisCarousel.element.classList.contains('is-style-carousel')
            ) {
              thisCarousel.destroy();
            }
          });
        });
      }
    });
  };

  initCarousels = (carousels) => {
    if (!carousels || !carousels.length) {
      return;
    }

    const carouselsArray = [].slice.call(carousels);

    carouselsArray.forEach(async carousel => {
      // Prevent re-initializing an already initialized
      // carousel
      if (carousel.classList.contains('is-visible')) {
        return;
      }

      const Flickity = (await import('flickity')).default;

      await import('flickity-fullscreen');
      await import('flickity-imagesloaded');

      const thisCarousel = new Flickity(carousel, {
        adaptiveHeight: false,
        imagesLoaded: true,
        pageDots: false,
        fullscreen: true,
        on: {
          ready: () => {
            carousel.classList.add('is-visible');
          },
        },
      });

      const thumbs = carousel.querySelectorAll('.blocks-gallery-item');

      for (let i = 0; i < thumbs.length; i++) {
        // eslint-disable-next-line no-unused-vars
        thumbs[i].addEventListener('click', e => {
          if (window.matchMedia('(min-width: 768px)').matches) {
            thisCarousel.viewFullscreen();
          }
        });
      }

      this.carousels.push(thisCarousel);
    });
  };

  destroyCarousels = () => {
    while (this.carousels.length) {
      this.carousels.pop().destroy();
    }
  };

  checkForWistiaEmbed = (parentNode) => {
    if (!parentNode) {
      return;
    }

    const embeds = parentNode.querySelectorAll(
      '.article__content .wp-block-embed.is-type-video.is-provider-wistia-inc'
    );

    if (embeds.length) {
      this.setupWistiaEmbedContainer(embeds);
    }
  };

  setupWistiaEmbedContainer = (embeds) => {
    if (!embeds || !embeds.length) {
      return;
    }

    const embedsArray = [].slice.call(embeds);

    embedsArray.forEach(embed => {
      const embedWrapper = embed.querySelector('.wp-block-embed__wrapper');
      const embedIframe = embed.querySelector('iframe');

      if (!embedWrapper || !embedIframe) {
        return;
      }

      // Aspect Ratio is height divided by width multipled by 100 for a whole number percentage
      // i.e. 281 / 500 * 100 = 56.2
      const paddingRatio = (embedIframe.height / embedIframe.width) * 100;
      embedWrapper.setAttribute('style', `padding-top: ${paddingRatio}%;`);
    });
  };

  /**
   * @todo document this function
   */
  shouldDisplayMeta() {
    const { post, shouldDisplayMetaDefault } = this.props;

    if ('story_category_view' === post.type) {
      return false;
    }

    if (post && post.meta && post.meta.hide_article_meta) {
      return false;
    }

    return Boolean(shouldDisplayMetaDefault);
  }

  /**
   * Whether to display the header tools component.
   */
  shouldDisplayHeaderTools() {
    const { post, terms } = this.props;

    if ('story_category_view' === post.type) {
      return false;
    }

    if ('press' === post.type) {
      return true;
    }

    if (!post.story_category || 0 === post.story_category.length) {
      return true;
    }

    for (const termId of Array.isArray(post.story_category)
      ? post.story_category
      : [post.story_category]) {
      if (terms && termId in terms && 'views' !== terms[termId].slug) {
        return true;
      }
    }

    return false;
  }

  /**
   * @todo document this function
   */
  postMeta = () => {
    const { post } = this.props;
    const { author } = post;
    const datePosted = post.date;

    const postDate = this.props.isBlogPost
      ? longDateTimeFormat(datePosted)
      : longDateFormat(datePosted);
    const readingTime = post?.content?.rendered
      ? getReadingTime(post?.content?.rendered)
      : '';

    return (
      <div className="article-meta">
        {author && author.length && (
          <>
            <p className="article-meta__author">{sprintf(__('By %s', 'starbucks'), author)}</p>
            <span>&#8226;</span>
          </>
        )}
        <p className="article-meta__date-posted">{postDate}</p>
        <span>&#8226;</span>
        <p className="article-meta__date-posted article-meta__read-time">
          {sprintf(__('%d min read', 'starbucks'), readingTime)}
        </p>
      </div>
    );
  };

  render() {
    const {
      renderUpNext,
      renderRelatedPosts,
      post,
      images,
      disableArticleHeader,
      isBlogPost,
      isHomepage,
      siblingTerms,
    } = this.props;

    const { meta } = post;

    // Meta may not exist on the post.
    const downloadableAssets =
      !!meta && 'downloadable_assets' in meta ? meta.downloadable_assets : null;

    const imageID = post.featured_media ? post.featured_media : 0;
    const smallImageID =
      post.meta && post.meta.kdmfi_small_featured
        ? post.meta.kdmfi_small_featured
        : 0;
    const wistiaUrl = post.wistia_video;
    const videoUrl =
      post.meta && post.meta.featured_video_url
        ? post.meta.featured_video_url
        : '';

    const isTermView = 'story_category_view' === post.type;
    const showTermDescription =
      post.meta?.termDescription && post.meta.termDescription !== '';
    //needs a switch from BE
    const hasStickyNav = isTermView && !this.props.hideStickyNav;

    if (isTermView) {
      post.meta.header_background_color = 'green';
      post.meta.featured_image_layout = 'horizontal-above';
    }

    const headerBackgroundColorClass =
      post.meta && post.meta.header_background_color
        ? `header-background--${post.meta.header_background_color}`
        : '';

    const featuredImageLayoutClass =
      post.meta && post.meta.featured_image_layout
        ? `header-layout--${post.meta.featured_image_layout}`
        : '';

    let featuredImageClass = images[imageID] ? 'header-with--image' : '';

    const imageBackgroundColorClass =
      post.meta && post.meta.header_background_color
        ? `single-top article-hero--${post.meta.header_background_color}`
        : 'single-top';

    const featuredVideoClass = wistiaUrl ? 'header-with--video' : '';

    const imageData = images[imageID];
    const smallImagedata = images[smallImageID];
    const displayHeader = isTermView ? true : !disableArticleHeader;
    const displayHeaderTools = this.shouldDisplayHeaderTools();

    const hasThreeImageLayout =
      meta && '3-image' === meta.featured_image_layout;
    const verticalLayout = meta && 'vertical' === meta.featured_image_layout;

    const displayFeaturedAboveTitle =
      meta && 'horizontal-above' === meta.featured_image_layout;

    if (displayFeaturedAboveTitle) {
      featuredImageClass = '';
    }

    const portraitImage =
      meta && meta.portrait_featured_image
        ? images[meta.portrait_featured_image]
        : imageData;
    const leftImage =
      meta && meta.left_featured_image
        ? images[meta.left_featured_image]
        : false;
    const centerImage =
      meta && meta.center_featured_image
        ? images[meta.center_featured_image]
        : imageData;
    const rightImage =
      meta && meta.right_featured_image
        ? images[meta.right_featured_image]
        : false;

    const hasAssets = !!downloadableAssets && 0 < downloadableAssets.length;

    const twitterTitleContent = post?.seo_twitter?.twitter_title.content
      ? post.seo_twitter.twitter_title.content
      : '';

    const { isRelatedPostsInterrupterRendered } = this.state;

    const headerOrientation =
      meta && meta.featured_image_layout ? meta.featured_image_layout : false;

    const isLegacyPost = post.meta?.uncleaned_post === '1';

    return (
      <PostContentContext.Provider value={this.state}>
        <Article>
          <div
            className={`content content--article ${headerBackgroundColorClass} ${featuredImageLayoutClass} ${featuredImageClass} ${featuredVideoClass} ${isHomepage ? 'is-homepage' : ''
              }`}
            ref={el => (this.articleContentRef = el)}
          >
            {(wistiaUrl || videoUrl) &&
              !isHomepage &&
              displayFeaturedAboveTitle && (
                <PostHero
                  video={videoUrl}
                  postTitle={decodeEntities(post.title.rendered)}
                  location="single-top"
                  wistiaUrl={wistiaUrl}
                  orientation={headerOrientation}
                >
                  {this.props.heroInner}
                </PostHero>
              )}

            {imageData && !hasThreeImageLayout && displayFeaturedAboveTitle && (
              <div className="article-hero-top">
                <PostHero
                  image={imageData}
                  smallImage={smallImagedata}
                  postTitle={decodeEntities(post.title.rendered)}
                  location={imageBackgroundColorClass}
                  video={videoUrl}
                  wistiaUrl={wistiaUrl}
                  orientation={headerOrientation}
                >
                  {this.props.heroInner}
                </PostHero>
              </div>
            )}

            {displayHeader && !isHomepage && (
              <header
                className={`article-header ${hasStickyNav ? 'has-sticky-nav' : ''
                  }`}
              >
                <PageTitle>{post?.title?.rendered}</PageTitle>

                {showTermDescription && (
                  <div
                    className="article-header__description"
                    dangerouslySetInnerHTML={{
                      // __html: post?.meta?.termDescription,
                      __html: post?.seo_meta?.description,
                    }}
                  />
                )}

                {hasStickyNav && <StickySubNav navItems={siblingTerms} />}

                {isLegacyPost && <LegacyDisclaimer />}

                {this.shouldDisplayMeta() && this.postMeta()}

                {displayHeaderTools && (
                  <div
                    id="article-header-tools"
                    className="article-header__tools"
                    ref={el => (this.headerToolsRef = el)}
                  >
                    {hasAssets && (
                      <>
                        <AssetModal
                          className={'article-header__download'}
                          downloadableAssets={downloadableAssets}
                        />
                        <span className="article-header__divider">&#8226;</span>
                      </>
                    )}

                    <div className="article-header__social">
                      <span className="article-header__social-label">
                        {__('Share', 'starbucks')}
                      </span>
                      <SocialShareList
                        postTitle={post?.title?.rendered}
                        postUrl={post.link}
                        image={images[imageID]}
                        twitterTitle={twitterTitleContent}
                        label="Article Header"
                      />
                    </div>
                  </div>
                )}
              </header>
            )}

            {(wistiaUrl || videoUrl) &&
              !isHomepage &&
              !displayFeaturedAboveTitle &&
              !verticalLayout && (
                <PostHero
                  video={videoUrl}
                  postTitle={decodeEntities(post.title.rendered)}
                  location="single-bottom"
                  wistiaUrl={wistiaUrl}
                  orientation={headerOrientation}
                >
                  {this.props.heroInner}
                </PostHero>
              )}

            {(imageData || portraitImage) &&
              !hasThreeImageLayout &&
              !displayFeaturedAboveTitle &&
              verticalLayout && (
                <PostHero
                  image={portraitImage ? portraitImage : imageData}
                  smallImage={smallImagedata}
                  postTitle={decodeEntities(post?.title?.rendered)}
                  location="single-bottom"
                  video={videoUrl}
                  wistiaUrl={wistiaUrl}
                  orientation={headerOrientation}
                >
                  {this.props.heroInner}
                </PostHero>
              )}

            {imageData &&
              !hasThreeImageLayout &&
              !displayFeaturedAboveTitle &&
              !verticalLayout && (
                <PostHero
                  image={imageData}
                  smallImage={smallImagedata}
                  postTitle={decodeEntities(post?.title?.rendered)}
                  location="single-bottom"
                  video={videoUrl}
                  wistiaUrl={wistiaUrl}
                  orientation={headerOrientation}
                >
                  {this.props.heroInner}
                </PostHero>
              )}

            {hasThreeImageLayout && (
              <ThreeImageElement
                leftImage={leftImage}
                centerImage={centerImage}
                rightImage={rightImage}
              />
            )}

            {(imageData || wistiaUrl || videoUrl) && !isTermView && (
              <hr className="article-header-separator" />
            )}

            <div ref={el => (this.content = el)}>
              <ShareableContent url={post.link} image={images[imageID]}>
                <DownloadableImages post={post}>
                  <ArticleContent
                    wrapperProps={{ className: 'article__content' }}
                    post={post}
                    posts={this.props.posts}
                    images={this.props.images}
                    pagination={this.props.pagination}
                  />
                </DownloadableImages>
              </ShareableContent>
            </div>

            {!isHomepage && (
              <div
                className="article-sticky-social"
                ref={el => (this.socialStickysRef = el)}
              >
                <Sticky
                  className='story-stick'
                  mode="bottom"
                  stickyClassName="story-stick--sticky"
                  positionRecheckInterval={25}
                >
                  <div className='story-stick--sticky__wrap'>
                    <StickySocialShare
                      title={decodeEntities(post?.title?.rendered)}
                      url={post.link}
                      image={images[imageID]}
                      twitterTitle={twitterTitleContent}
                    />

                    {!isBlogPost && post?.related_story?.link && <Flyout content={post.related_story} />}
                  </div>
                </Sticky>
              </div>
            )}

            {post.related_story && 'link' in post.related_story && (
              <div
                className="article-footer"
                ref={el => (this.articleFooterRef = el)}
              >
                {/* This empty div is used to trigger flyout when in view (bottom of article) */}
              </div>
            )}

            {renderUpNext && renderUpNext(post)}
          </div>

          {renderRelatedPosts &&
            !isBlogPost &&
            !isRelatedPostsInterrupterRendered &&
            renderRelatedPosts(post)}
        </Article>
      </PostContentContext.Provider>
    );
  }
}

export default PostContent;

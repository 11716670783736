import React from 'react';
import Image from 'next/image';

// Note: This component is not used in the current implementation. It serves as
// a placeholder block for a simple image, but ArticleGallery and ArticleHero
// blocks that have it as part of their template, interject and render plain
// Image components directly in their render method.
export const ImageElement = (props) => {
	const { media, focalPoint } = props;
	const { url = null, alt = null, width = null, height = null } = media ?? {};

	const focalPointStyle = {
		objectFit: 'cover',
		objectPosition: `${focalPoint?.x * 100}% ${focalPoint?.y * 100}%`,
		height: 200,
		width: 200,
	};

	return (
		<div className="article-hero-block__image">
		{url &&
			<Image
				src={url}
				alt={alt}
				sizes='100vw'
				width={width}
				height={height}
			/>}
		</div>
	);
};

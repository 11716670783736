import { ArticleContent } from 'components/Pages/Shared/ArticleContent';
import classnames from 'classnames';

export const BlockPostTemplate = (props) => {
	const {
		blocks,
		className: classNameFromEditor,
		isEntireCardClickable,
		layout: { type: layoutType = 'default', columnCount = 2 } = {},
		posts,
	} = props;

	const className = classnames('wp-block-post-template', classNameFromEditor, {
		'is-entire-card-clickable': isEntireCardClickable,
		[`is-layout-${layoutType}`]: layoutType,
		[`has-${columnCount}-columns`]: columnCount,
	});

	return (
		<div className={className}>
			{posts && posts.map((post) => {
				return (
					<div className="wp-block-post" key={post.id}>
						<ArticleContent post={post} blocks={blocks} />
					</div>
				);
			})}
		</div>
	);
};

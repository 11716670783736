import { get } from 'dot-prop-immutable';
import { saveAs } from 'file-saver';

export const SQUARE = 'square';
export const PORTRAIT = 'portrait';
export const LANDSCAPE = 'landscape';

export function getOrientation(width, height) {
	const w = parseInt(width, 10);
	const h = parseInt(height, 10);

	if (w === h) {
		return SQUARE;
	}

	if (w > h) {
		return LANDSCAPE;
	}

	return PORTRAIT;
}

function getImageSizes(image, orientation = '') {
	return Object.values(get(image, 'media_details.sizes', {})).filter(
		(size) => getOrientation(size.width, size.height) === orientation,
	);
}

export function getSrcset(image = {}, orientation) {
	const computedOrientation =
		image.media_details?.width && image.media_details?.height
			? getOrientation(image.media_details.width, image.media_details.height)
			: 'landscape';

	return getImageSizes(image, orientation || computedOrientation)
		.reverse()
		.map((size) => `${size.source_url} ${size.width}w`)
		.join(', ');
}

export function imageSrcProps(image, orientation = '') {
	// if no specific orientation is requested, infer from the source dimensions
	orientation =
		orientation || getOrientation(image.media_details.width, image.media_details.height);

	return {
		src: getSmallestSrc(image, orientation),
		srcSet: getSrcset(image, orientation),
		sizes: image?.media_details?.sizes,
	};
}

export function getSmallestSrc(image, orientation = '') {
	return getSmallestSize(image, orientation).source_url || '';
}

export function getSmallestSize(image, orientation = '') {
	if (get(image, 'mime_type') === 'image/svg+xml') {
		return image; // image.source_url will work
	}

	return (
		getImageSizes(image, orientation)
			.sort((a, b) => (a.width < b.width ? -1 : 1))
			.shift() || {}
	);
}

export function getInlineSvg() {
	let queryResult = document.querySelectorAll('.image-to-svg:not(.image-to-svg--replaced)');
	queryResult = Array.from(queryResult);
	queryResult.forEach(function (img) {
		const imgID = img.id;
		const imgClass = img.className;
		const imgURL = img.src;
		const imgAlt = img.alt;

		fetch(imgURL)
			.then(function (response) {
				return response.text();
			})
			.then(function (text) {
				const parser = new DOMParser();
				const xmlDoc = parser.parseFromString(text, 'text/xml');

				// Get the SVG tag, ignore the rest
				const [svg] = xmlDoc.getElementsByTagName('svg');

				// In some cases, we don't have an SVG - possibly just a normal image where we usually expect an svg to be uploaded
				if (!svg) {
					return;
				}

				// Add replaced image's ID to the new SVG
				if (typeof imgID !== 'undefined') {
					svg.setAttribute('id', imgID);
				}
				// Add replaced image's classes to the new SVG
				if (typeof imgClass !== 'undefined') {
					svg.setAttribute('class', `${imgClass} image-to-svg--replaced`);
				}

				// Remove any invalid XML tags as per http://validator.w3.org
				svg.removeAttribute('xmlns:a');

				// Check if the viewport is set, if the viewport is not set the SVG wont't scale.
				if (
					!svg.getAttribute('viewBox') &&
					svg.getAttribute('height') &&
					svg.getAttribute('width')
				) {
					svg.setAttribute(
						'viewBox',
						`0 0 ${svg.getAttribute('height')} ${svg.getAttribute('width')}`,
					);
				}

				const title = svg.querySelector('title') ? svg.querySelector('title') : '';
				const desc = svg.querySelector('desc') ? svg.querySelector('desc') : '';
				const fillArray = svg.querySelectorAll('[fill]');

				Array.from(fillArray).forEach((el) => {
					el.setAttribute('fill', 'currentColor');
				});

				if (imgAlt) {
					if (title) {
						title.textContent = imgAlt;
					} else {
						const titleEl = document.createElement('title');
						titleEl.textContent = imgAlt;

						svg.insertBefore(titleEl, svg.childNodes[0] || null);
					}
				} else if (title) {
					svg.removeChild(title);
				}

				if (desc) {
					svg.removeChild(desc);
				}

				// Replace image with new SVG
				if (img && img.parentNode) {
					img.parentNode.replaceChild(svg, img);
				}
			});
	});
}

export async function downloadLinkHrefOnClick(e) {
	e.preventDefault();

	const { currentTarget } = e;
	const linkHref = currentTarget.getAttribute('href');

	if (linkHref) {
		let linkFilename = currentTarget.getAttribute('data-filename');

		if (!linkFilename) {
			linkFilename = linkHref.replace(/.*\//g, '');
		}

		const res = await fetch(linkHref);
		const blob = await res.blob();
		saveAs(blob, linkFilename);
	}
}

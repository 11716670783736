/* eslint-disable no-unused-vars */
import React, { useEffect, useState, createRef } from 'react';
import md5 from 'md5';
import Image from 'next/image';
import classnames from 'classnames';
import DOMPurify from 'isomorphic-dompurify';

export const Slider = (props) => {
	const {
		align,
		backgroundColor,
		innerBlocks,
		className: classNameFromEditor,
		textColor,
	} = props;

	const [carousel, setCarousel] = useState(null);
	const [activeSlide, setActiveSlide] = useState(1);
	const ref = React.createRef();

	useEffect(() => {
		const initCarousel = async () => {
			const Flickity = (await import('flickity')).default;

			const carouselInstance = new Flickity(ref.current, {
				adaptiveHeight: true,
				draggable: true,
				fullscreen: false,
				pageDots: false,
				prevNextButtons: false,
				wrapAround: true,
				autoPlay: 5000,
				pauseAutoPlayOnHover: false,
				on: { change: (index) => setActiveSlide(index + 1) },
			});

			setCarousel(carouselInstance);
		};

		initCarousel();
	}, [ref]);

	const handleIndicatorClick = (e, index) => {
		e.preventDefault();
		carousel.select(index);
	};

	const slideLabels = innerBlocks.map((block, index) => {
		const { slideLabel = '', backgroundVariation } = block.attributes;

		return slideLabel;
	});

	const SlideIndicator = ({ label, index }) => {
		const className = classnames('sbx-cs-slider__indicator', {
			'is-active': activeSlide === index + 1,
		});

		return (
			<li className={className}>
				<button href="#" onClick={(e) => handleIndicatorClick(e, index)}>
					{label}
				</button>
			</li>
		);
	};

	const className = classnames('sbx-cs-slider', {
		[classNameFromEditor]: classNameFromEditor,
		'has-background-color': backgroundColor,
		'has-text-color': textColor,
		[`align${align}`]: align,
		[`has-${backgroundColor}-background-color`]: backgroundColor,
		[`has-${textColor}-color`]: textColor,
	});

	return (
		<div className={className}>
			{slideLabels.length > 1 && (
				<ul className="sbx-cs-slider__indicators">
					{slideLabels.map((label, index) => (
						<SlideIndicator label={label} index={index} key={label} />
					))}
				</ul>
			)}
			<div className="sbx-cs-slider__inner" ref={ref}>
				{innerBlocks.map((block, index) => {
					const {
						copy,
						ctaLabel,
						ctaLink: { url = null, opensInNewTab = null } = {},
						eyebrow,
						media: { url: src = null, alt = '' } = {},
						title,
					} = block.attributes;

					return (
						<div className="sbx-cs-slider__item sbx-cs-slide" key={index}>
							<div className="sbx-cs-slide__content">
								<p className="sbx-cs-slide__eyebrow">{eyebrow}</p>
								<h2
									className="sbx-cs-slide__title"
									dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
								/>
								<div className="sbx-cs-slide__copy">
									<p
										dangerouslySetInnerHTML={{
											__html: DOMPurify.sanitize(copy),
										}}
									/>
								</div>
								{ctaLabel && url && (
									<div className="sbx-cs-slide__cta">
										<a
											href={url}
											target={opensInNewTab ? '_blank' : '_self'}
											rel="noreferrer"
										>
											{ctaLabel}
										</a>
									</div>
								)}
							</div>
							<div className="sbx-cs-slide__media">
								<div className="sbx-cs-slide__media-wrap">
									{src && alt && (
										<Image
											alt={alt}
											layout="fill"
											objectFit="cover"
											objectPosition="center"
											src={src}
										/>
									)}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export const map = {
	'starbucks-blocks/slider-with-navigation': Slider,
	[md5('starbucks-blocks/slider-with-navigation')]: Slider,
};

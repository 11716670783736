import * as React from 'react';
import reactStringReplace from 'react-string-replace';
import EmbedContainer from 'components/EmbedContainer';
import { getComponentForBlock } from 'components/Blocks';
import RelatedContentInterrupter, {
	map as RelatedContentInterrupterMappings,
} from 'components/Blocks/RelatedContentInterrupter';
import parse, { domToReact } from 'html-react-parser';
import { Link } from 'components/Html';
import { withRouter } from 'next/router';
import { renderTableauEmbed } from 'utils/tableau-embed-utils';
import { isAnchorLink, isInternalLink } from './url';

export const BLOCK_NAMESPACE = 'starbucks-blocks';

const parserOptions = (locale, domainsLocales) => ({
	replace: ({ name, attribs, children }) => {
		if (name === 'a' && attribs?.href && !isAnchorLink(attribs.href)) {
			const { class: className, href, ...rest } = attribs;

			// Check if the link is an internal link
			const internal = isInternalLink(href, domainsLocales);

			// If internal link, transform to relative URL
			// rest.href = internal ? transformLink(href, null, locale, true) : href;

			// temporary workaround for doubling subdirectory bug - only use absolute URLs for now
			rest.href = href;

			// If target attribute is set in the editor, use that.
			// If no target attribute, set based on internal vs external link
			if (attribs.target) {
				rest.target = attribs.target;
			} else if (!internal) {
				rest.target = '_blank';
				rest.rel = 'noopener noreferrer';
			}

			// If a rel attribute is set in the editor, use that.
			if (attribs.rel) {
				rest.rel = attribs.rel;
			}

			// Update itemprop to a valid prop name
			if (rest.itemprop) {
				rest.itemProp = rest.itemprop;
				delete rest.itemprop;
			}

			return (
				<Link className={className} locale={false} {...rest}>
					{domToReact(children, parserOptions(locale, domainsLocales))}
				</Link>
			);
		}
	},
});

/**
 * We need to override the EmbedContainer's render method
 * to set the inner HTML to prevent adding additional <div>s
 */
class RawEmbedContainerNoRouter extends EmbedContainer {
	/** */
	render() {
		const { className, markup } = this.props;
		markup.split(' ').join(' ');
		const newMarkup = markup.replace(
			'<!--fallback-image-->',
			`<img src="/images/SBux-Stories-Default-Image.jpg" class="sb-card-grid-item__image sb-image-fit__image" alt=""/>`,
		);
		const domainLocales = this.props.router.domainLocales
			.filter(({ id }) => id !== 'primary')
			.map(({ domain }) => domain);
		const { locale } = this.props.router;

		return (
			<div
				className={className}
				ref={(node) => {
					this.container = node;
				}}
			>
				{parse(newMarkup, parserOptions(locale, domainLocales))}
			</div>
		);
	}
}

const RawEmbedContainer = withRouter(RawEmbedContainerNoRouter);
/**
 * Parse all block component hashes from the given content into a list of unique hashes.
 */
export function parseBlockHashes(content = '') {
	return Array.from(content.matchAll(/<!-- ([a-z0-9]{32}) /g))
		.map((match) => match[1])
		.reduce((unique, hash) => {
			return unique.includes(hash) ? unique : unique.concat(hash);
		}, []);
}

/**
 * @todo document this function
 * @param {*} hash
 */
function regexForHash(hash) {
	return new RegExp(`<!-- ${hash} (.*) /${hash} -->`, 'g');
}

/**
 * @todo document this function
 * @param {*} string
 */
function convertBuiltinBlocks_StringToArray(string) {
	if (typeof string === 'string') {
		return string.split(/\n\n\n/g);
	}
	return string;
}

/**
 * Convert block strings to array elements and inject
 * Related Posts Interrupter component if necessary.
 *
 * @param {string} content        The original contents.
 * @param {*}      reducedContent The parsed and reduced contents.
 */
function dynamicallyInsertInterrupter({ content, reducedContent }) {
	const contentContainsInterrupter = regexForHash(
		RelatedContentInterrupterMappings.hashString,
	).test(content);

	// if there's already an interuppter, that's great!
	// just return the content
	if (contentContainsInterrupter) {
		return reducedContent;
	}

	// if there is no related posts interrupter block
	// add one automatically.
	let interruptedContent;

	if (Array.isArray(reducedContent)) {
		// loop through reducedContent and convert strings to arrays
		interruptedContent = reducedContent.reduce((accumulator, index) => {
			const defaultBlocks = convertBuiltinBlocks_StringToArray(reducedContent[index]);

			if (typeof defaultBlocks === 'string') {
				accumulator = [...accumulator, defaultBlocks];
			}

			if (typeof defaultBlocks === 'object' && defaultBlocks.length) {
				accumulator = [...accumulator, ...defaultBlocks];
			}

			return accumulator;
		}, []);
	} else if (typeof reducedContent === 'string') {
		interruptedContent = convertBuiltinBlocks_StringToArray(reducedContent);
	}

	if (!interruptedContent) {
		return reducedContent;
	}

	// if there are five or less blocks, don't add the interrupter
	if (Array.isArray(interruptedContent) && interruptedContent.length <= 5) {
		return convertBuiltinBlocks_StringToArray(reducedContent);
	}

	// add the interrupter in the middle
	interruptedContent.splice(
		Math.floor(interruptedContent.length / 2),
		0,
		<RelatedContentInterrupter />,
	);

	return interruptedContent;
}

/**
 * @todo document this function
 * @param {*} content
 * @param {*} wrapperProps
 * @param {*} normalizers
 */
export function replaceBlocksWithComponents(
	content,
	wrapperProps = {},
	normalizers,
	post,
	posts,
	images,
	pagination,
	showTerms,
) {
	let key = 1;

	/**
	 * Primary React Wrapper for HTML String.
	 * Convert HTML string to renderable React component.
	 *
	 * @param {*} __html HTML string or Component
	 */
	const wrap = (__html) => {
		if (__html === '') {
			return '';
		}
		const props = {
			key: key++,
		};
		let Wrapper = 'div';

		if (typeof __html === 'string') {
			Wrapper = RawEmbedContainer;
			props.markup = __html;
			props.children = ''; // prop types required
		} else {
			props.children = __html; // it's a component!
		}

		return <Wrapper {...props} {...wrapperProps} />;
	};

	const blockHashes = parseBlockHashes(content);

	let reducedContent = blockHashes.reduce((content, hash) => {
		return reactStringReplace(content, regexForHash(hash), (match, index) => {
			const props = JSON.parse(match);
			const Component = getComponentForHash(hash, { props, index, match });

			return Component ? (
				<Component
					key={`${hash}-${index}`}
					post={post}
					posts={posts}
					images={images}
					pagination={pagination}
					showTerms={showTerms}
					{...props}
				>
					{props.content}
				</Component>
			) : null;
		});
	}, content);

	reducedContent = renderTableauEmbed(content, reducedContent);

	// Dynamically insert related posts interrupter if needed
	// At this point the blocks are parsed into an array of HTML strings.
	// Each block should be its own index in the array (except inner blocks)
	const interruptedContent = dynamicallyInsertInterrupter({
		content,
		reducedContent,
	});

	// The InnerBlocks within the blocks in this array will not be split into separate divs.
	// Instead, they will retain their markup within the parent. (i.e. gallery images, wp-block-column, etc..)
	const blockClasses = ['wp-block-columns', 'wp-block-gallery', 'wp-block-list', 'icon-list'];

	const _interruptedContent = [...(interruptedContent ?? [])];
	if (_interruptedContent.length > 0) {
		blockClasses.forEach((blockClass) => {
			let colsPointer = null;
			for (let i = 0, m = interruptedContent.length; i < m; i++) {
				if (typeof interruptedContent[i] !== 'string') {
					colsPointer = i;
					continue;
				}

				// If the string contains one of the block classes, skip it.
				if (
					interruptedContent[i].trim().includes(blockClass) &&
					(interruptedContent[i].trim().includes('div') ||
						interruptedContent[i].trim().includes('li'))
				) {
					colsPointer = i;
					continue;
				}

				if (interruptedContent[i].trim().startsWith('<')) {
					if (typeof _interruptedContent[colsPointer] !== 'string') {
						continue;
					}
					_interruptedContent[colsPointer] += interruptedContent[i].trim();
					delete _interruptedContent[i];
					continue;
				}
				_interruptedContent[i] = interruptedContent[i].trim();
			}
		});
	}

	return _interruptedContent.map(wrap);
}

/**
 * @todo document this function
 * @param {*} hash
 */
export function getComponentForHash(hash) {
	const component = getComponentForBlock(hash);

	if (component) {
		return component;
	}

	if (process.env.NODE_ENV === 'development') {
		return (props) => ( // eslint-disable-line
			<div>
				No component found for block <code>{hash}</code>
				<pre>{JSON.stringify(props, null, 4)}</pre>
			</div>
		);
	}

	return null;
}

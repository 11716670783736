import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { sprintf, __ } from '@wordpress/i18n';
import Download from 'assets/icons/download.svg';
import CloseIcon from 'assets/icons/plus.svg';
import { fetchUrlAsBlob } from 'lib/wp/utils/fetch-blob';
import DownloadableImagesModalOpenContext from 'context/DownloadableImagesModalOpenContext';
import DownloadButtonWithIcon from 'components/DownloadButtonWithIcon';
import Modal from '..';
import AssetItem from './Item';

export default function AssetModal({ downloadableAssets, className }) {
	const openModalContext = useContext(DownloadableImagesModalOpenContext);
	const toggleButton = useRef(null);

	/**
	 * Provides props ot pass to the AssetItem component.
	 *
	 * @param {object} asset Attachment post data.
	 * @param {string} backupAlt Alt text to use if asset's assigned alt is empty.
	 * @returns {object} Props to pass to AssetItem component.
	 */
	const getAssetProps = (
		{ alt, sizes = {}, type, url, filename },
		backupAlt = __('Downloadable image', 'starbucks'),
	) => {
		/**
		 * Returns the appropriate attachment thumb for the size.
		 *
		 * @param {string} size
		 */
		const selectSize = (size) => {
			switch (true) {
				case size === 'highRes' && 'full' in sizes:
					return sizes.full.url;

				case 'large' in sizes:
					return sizes.large.url;

				case 'medium' in sizes:
					return sizes.medium.url;

				case 'thumbnail' in sizes:
					return sizes.thumbnail;

				default:
					return '';
			}
		};

		return {
			type,
			url,
			filename,
			imageAltText: alt || backupAlt,
			imageHighRes: selectSize('highRes'),
			imageLowRes: selectSize('lowRes'),
			imageThumbnail: 'thumbnail' in sizes ? sizes.thumbnail.url : '',
		};
	};

	const toggleModal = () => {
		if (openModalContext.isDownloadAssetsModalOpen) {
			openModalContext.setIsDownloadAssetsModalOpen(false);
			if (openModalContext.focusElement && 'focus' in openModalContext.focusElement) {
				openModalContext.focusElement.focus();
			}
		} else {
			openModalContext.setIsDownloadAssetsModalOpen(true);
			openModalContext.setFocusElement(toggleButton.current);

			const modalHeading = document.querySelector('.asset-download__close');
			if (modalHeading === true) {
				modalHeading.focus();
			}
		}
	};

	const modalScreenClickIntent = (e) => {
		if (e.target.classList.contains('asset-download__modal_screen') === true) {
			openModalContext.setIsDownloadAssetsModalOpen(false);
			if (openModalContext.focusElement && 'focus' in openModalContext.focusElement) {
				openModalContext.focusElement.focus();
			}
		}
	};

	const handleEscapeKey = useCallback(
		(e) => {
			if (openModalContext.isDownloadAssetsModalOpen && e?.keyCode === 27) {
				openModalContext.setIsDownloadAssetsModalOpen(false);
			}
		},
		[openModalContext],
	);

	const zipAssets = async (images) => {
		const JSZip = (await import('jszip')).default;
		const { saveAs } = (await import('file-saver')).default;
		const zip = new JSZip();
		const imageFolder = zip.folder('Assets');

		// Add images to folder
		images.forEach((image) => {
			const imageLink = image.href;
			const imageFilename = imageLink.replace(/.*\//g, '');

			imageFolder.file(imageFilename, fetchUrlAsBlob(imageLink), {
				binary: true,
			});
		});

		// Generate the zip file asynchronously
		zip.generateAsync({ type: 'blob' }).then(function (content) {
			saveAs(content, 'Starbucks-Assets.zip');
		});
	};

	const zipLowResImages = () => {
		const lowResImages = document.querySelectorAll('.asset-download__item-link--low');

		zipAssets(lowResImages);
	};

	const zipHighResImages = () => {
		const highResImages = document.querySelectorAll('.asset-download__item-link--high');

		zipAssets(highResImages);
	};

	useEffect(() => {
		document.addEventListener('keydown', handleEscapeKey);
	}, [handleEscapeKey]);

	return (
		<>
			<DownloadButtonWithIcon
				aria-controls="asset-download"
				aria-expanded={openModalContext.isDownloadAssetsModalOpen.toString()}
				className={className}
				label={__('Download Assets', 'starbucks')}
				onClick={() => toggleModal()}
				onKeyDown={() => handleEscapeKey()}
				ref={toggleButton}
			/>

			{openModalContext.isDownloadAssetsModalOpen && (
				<Modal targetEl={toggleButton}>
					<div className="asset-download" onClick={(e) => modalScreenClickIntent(e)}>
						<div className="asset-download__modal">
							<div
								id="asset-download"
								className="asset-download__modal_body"
								aria-hidden="true"
							>
								<button
									className="asset-download__close"
									onClick={() => toggleModal()}
								>
									{__('Close', 'starbucks')}
									<CloseIcon aria-hidden="true" />
								</button>

								<div className="asset-download__all">
									<div className="asset-download__all-label">
										<Download className="asset-download__all--icon" />
										{__('Download Assets', 'starbucks')}
										<span className="asset-download__count">
											({downloadableAssets.length})
										</span>
									</div>

									<div className="asset-download__item-links">
										<button
											onClick={() => zipLowResImages()}
											className="asset-download__item-link"
										>
											{__('Low', 'starbucks')}
										</button>

										<span className="asset-download__item-divider">|</span>

										<button
											onClick={() => zipHighResImages()}
											className="asset-download__item-link"
										>
											{__('High', 'starbucks')}
										</button>
									</div>
								</div>

								<div className="asset-download__list">
									{downloadableAssets.map((asset) => (
										<AssetItem
											key={`downloadable-asset-${asset.id}`}
											{...getAssetProps(asset)}
										/>
									))}
								</div>

								<div className="asset-download__terms">
									<p
										dangerouslySetInnerHTML={{
											__html: sprintf(
												/* translators: %s: the URL for terms and conditions */
												__(
													'By using this Site, I agree to its <a href="%s">Terms & Conditions</a>. All content downloaded from the Site (photography, audio, and video, etc.) may be used for editorial purposes only. Any other use of Site content including, without limitation personal or commercial use, is strictly prohibited.',
													'starbucks',
												),
												__(
													'https://www.starbucks.com/terms/starbucks-terms-of-use/',
													'starbucks',
												),
											),
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
}

/* eslint-disable no-unused-vars */
import React from 'react';
import md5 from 'md5';
import { RawHTML } from 'components/Html/Raw/html';
import { addQueryArgs } from '@wordpress/url';

export const StoryMap = (props) => {
	const { apiKey, title, subtitle, bgMediaURL, mapURL } = props;

	const embedURL = addQueryArgs(mapURL, {
		key: apiKey,
	});

	return (
		<div className="story-map">
			<div className="story-map__background">
				<div className="story-map__reveal">
					<RawHTML
						tagName="iframe"
						src={embedURL}
						className="story-map__embed"
						frameBorder="0"
						allowfullscreen
					/>
				</div>

				<div className="story-map__headings">
					<RawHTML tagName="h3" className="story-map__title" value={title} />
					<RawHTML tagName="h4" className="story-map__subtitle" value={subtitle} />
				</div>

				<figure className="story-map__image">
					<img src={bgMediaURL} alt="" />
				</figure>
			</div>
		</div>
	);
};

export const map = {
	'starbucks-blocks/story-map': StoryMap,
	[md5('starbucks-blocks/story-map')]: StoryMap,
};

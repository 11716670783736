import classnames from 'classnames';
import { BlockButton } from 'components/Blocks/core/block-button';

export const BlockButtons = (props) => {
	const {
		className: classNameFromEditor,
		layout: { type, justifyContent, verticalAlignment } = {},
		innerBlocks,
		anchor,
	} = props;

	const className = classnames(classNameFromEditor, 'wp-block-buttons', {
		[`is-layout-${type}`]: type,
		[`is-content-justification-${justifyContent}`]: justifyContent,
		[`is-content-vertical-alignment-${verticalAlignment}`]: verticalAlignment,
	});

	return (
		<div className={className} id={anchor}>
			{innerBlocks.map((buttonBlock) => (
				<BlockButton key={buttonBlock.clientId} {...buttonBlock.attributes} />
			))}
		</div>
	);
};

import classnames from 'classnames';
import { RawHTML } from 'components/Html/Raw/html';
import { downloadLinkHrefOnClick } from 'utils/image';

export const BlockFile = ({
	align,
	className: classNameFromEditor,
	href,
	fileName,
	textLinkHref,
	textLinkTarget,
	showDownloadButton,
	downloadButtonText,
	anchor,
}) => {
	const className = classnames(classNameFromEditor, {
		[`align${align}`]: align,
	});

	return href ? (
		<div className={`wp-block-file ${className}`} id={anchor}>
			{!showDownloadButton && (
				<a
					href={textLinkHref}
					className="wp-block-file__button"
					target={textLinkTarget}
					onClick={downloadLinkHrefOnClick}
				>
					<RawHTML value={fileName} tagName="span" />
				</a>
			)}
			{showDownloadButton && (
				<>
					<a href={textLinkHref} className="wp-block-file__text" target={textLinkTarget}>
						<RawHTML value={fileName} tagName="span" />
					</a>
					<a
						href={href}
						className="wp-block-file__button"
						target={textLinkTarget}
						onClick={downloadLinkHrefOnClick}
					>
						<RawHTML value={downloadButtonText} tagName="span" />
					</a>
				</>
			)}
		</div>
	) : null;
};

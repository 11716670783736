import React from 'react';
import ReactDOM from 'react-dom';
import Image from 'next/image';
import { __ } from '@wordpress/i18n';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import DownloadLinkWithIcon from 'components/DownloadLinkWithIcon';
import Facebook from 'assets/icons/facebook.svg';
import Twitter from 'assets/icons/twitter.svg';
import Linkedin from 'assets/icons/linkedin.svg';
import PlusIcon from 'assets/icons/plus.svg';

class PressPhotosImage extends React.Component {
	state = {
		toolsOpen: false,
	};

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside, true);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside, true);
	}

	handleClickOutside = (event) => {
		// eslint-disable-next-line react/no-find-dom-node
		const domNode = ReactDOM.findDOMNode(this);

		if (!domNode || !domNode.contains(event.target)) {
			this.setState({
				toolsOpen: false,
			});
		}
	};

	// eslint-disable-next-line no-unused-vars
	handleToolToggle(e) {
		this.setState({
			toolsOpen: !this.state.toolsOpen,
		});
	}

	render() {
		const { media } = this.props;

		const twitterAccount = process.env.REACT_APP_TWITTER_ACCOUNT
			? process.env.REACT_APP_TWITTER_ACCOUNT
			: 'StarbucksNews';

		return (
			<div className="press-photos-image">
				<a
					href={media.url}
					className="press-photos-image__wrap"
					rel="noopener noreferrer"
					target="_blank"
				>
					<Image
						src={media.url}
						layout="fill"
						parentClassName=""
						objectFit="cover"
						objectPosition="center"
						alt={media.alt}
					/>
				</a>
				<button
					onClick={(e) => this.handleToolToggle(e)}
					className={
						this.state.toolsOpen
							? 'press-photos-image__trigger press-photos-image__trigger--open'
							: 'press-photos-image__trigger'
					}
				>
					<PlusIcon />
				</button>
				<ul
					className="press-photos-image__tools"
					aria-hidden={this.state.toolsOpen ? 'false' : 'true'}
				>
					<li className="press-photos-image__tool">
						<DownloadLinkWithIcon
							href={media.url}
							label={__('Download', 'starbucks')}
						/>
					</li>
					<li className="press-photos-image__tool">
						<TwitterShareButton
							className="press-photos-image__tool-link press-photos-image__tool-link--twitter"
							url={media.link}
							resetButtonStyle={false}
							via={twitterAccount}
							title={this.props.twitterTitle}
							data-social-category="Share"
							data-social-action="Twitter"
							data-social-label={this.props.label}
						>
							<Twitter className="sb-icon sb-social-share__icon" />
							<span className="screen-reader-text">
								{__('Share on Twitter', 'starbucks')}
							</span>
						</TwitterShareButton>
					</li>
					<li className="press-photos-image__tool">
						<FacebookShareButton
							className="press-photos-image__tool-link press-photos-image__tool-link--facebook"
							resetButtonStyle={false}
							url={media.link}
							data-social-category="Share"
							data-social-action="Facebook"
							data-social-label={this.props.label}
						>
							<Facebook className="sb-icon sb-social-share__icon" />
							<span className="screen-reader-text">
								{__('Share on Facebook', 'starbucks')}
							</span>
						</FacebookShareButton>
					</li>
					{!process.env.REACT_APP_HIDE_LINKEDIN && (
						<li className="press-photos-image__tool">
							<LinkedinShareButton
								className="press-photos-image__tool-link press-photos-image__tool-link--linkedin"
								url={media.link}
								resetButtonStyle={false}
								data-social-category="Share"
								data-social-action="LinkedIn"
								data-social-label={this.props.label}
							>
								<Linkedin className="sb-icon sb-social-share__icon" />
								<span className="screen-reader-text">
									{__('Share on LinkedIn', 'starbucks')}
								</span>
							</LinkedinShareButton>
						</li>
					)}
				</ul>
			</div>
		);
	}
}

export default PressPhotosImage;

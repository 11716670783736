import * as React from 'react';
import PropTypes from 'prop-types';

export default function PageTitle({ children }) {
	return (
		<h1
			className={`article-header__text ${children?.length > 90 ? 'is-long-title' : ''}`}
			dangerouslySetInnerHTML={{
				__html: children,
			}}
		/>
	);
}

PageTitle.propTypes = {
	children: PropTypes.node.isRequired,
};

import * as React from 'react';
import PropTypes from 'prop-types';

export default function Article({ children, ...rest }) {
	return (
		<article className={`article ${rest.className || ''}`}>
			<div id="content-wrap" className="article__content-wrap">
				{children}
			</div>
		</article>
	);
}

Article.propTypes = {
	children: PropTypes.node.isRequired,
};

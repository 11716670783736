/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import md5 from 'md5';
import { PhotoCarouselItem } from './PhotoCarouselItem';

export const PhotoCarousel = ({ id, innerBlocks }) => {
	const [carousel, setCarousel] = useState(null);

	const carouselWrapRef = React.createRef();

	useEffect(() => {
		const initCarousel = async () => {
			const Flickity = (await import('flickity')).default;

			const carouselInstance = new Flickity(carouselWrapRef.current, {
				adaptiveHeight: true,
				draggable: false,
				fullscreen: false,
				pageDots: false,
				prevNextButtons: false,
				wrapAround: true,
			});

			setCarousel(carouselInstance);
		};

		initCarousel();
	}, [carouselWrapRef]);

	return (
		<div className="sb-photo-carousel">
			<div className="sb-photo-carousel__wrap" ref={carouselWrapRef}>
				{innerBlocks.map((block, index) => {
					const { image, title, content, type } = block.attributes;
					const { innerBlocks } = block;
					return (
						<PhotoCarouselItem
							key={`${id}_${index}`}
							image={image}
							title={title}
							content={content}
							carousel={carousel}
							innerBlocks={innerBlocks}
							type={type}
						/>
					);
				})}
			</div>
		</div>
	);
};

export const map = {
	'starbucks-blocks/photo-carousel': PhotoCarousel,
	[md5('starbucks-blocks/photo-carousel')]: PhotoCarousel,
};

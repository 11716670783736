/**
 * Initialize carousels
 *
 * @param {HTMLElement} node React Ref node
 * @param {object} Flickity Flickity instance
 */
export const initCarousels = (node, Flickity) => {
	const carousels = node.querySelectorAll('.wp-block-gallery.is-style-carousel');

	if (carousels.length) {
		const carouselsArray = Array.from(carousels);

		carouselsArray.forEach((carousel) => {
			const carouselElement = carousel;

			// Prevent re-initializing an already initialized carousel
			if (carouselElement.classList.contains('is-visible')) {
				return;
			}

			const thisCarousel = new Flickity(carouselElement, {
				adaptiveHeight: true,
				imagesLoaded: true,
				pageDots: false,
				fullscreen: true,
				on: {
					ready: () => {
						carouselElement.classList.add('is-visible');
					},
				},
			});

			const thumbs = carouselElement.querySelectorAll('.blocks-gallery-item');

			for (let i = 0; i < thumbs.length; i++) {
				thumbs[i].addEventListener('click', () => {
					if (window.matchMedia('(min-width: 768px)').matches) {
						thisCarousel.viewFullscreen();
					}
				});
			}
		});
	}
};

/**
 * Initialize carousels with lightbox components.
 *
 * @param {HTMLElement} node React Ref node
 * @param {object} Flickity Flickity instance
 */
export const initLightboxCarousels = (node, Flickity) => {
	const carousels = node.querySelectorAll('.wp-block-gallery.is-style-lightbox');

	if (!carousels.length) {
		return;
	}

	const carouselsArray = Array.from(carousels);

	carouselsArray.forEach((carousel) => {
		// Prevent re-initializing an already initialized carousel

		const thumbs = carousel.querySelectorAll('.blocks-gallery-item');

		for (let i = 0; i < thumbs.length; i++) {
			thumbs[i].addEventListener('click', () => {
				if (!window.matchMedia('(min-width: 768px)').matches) {
					return;
				}

				const thisCarousel = new Flickity(carousel, {
					adaptiveHeight: false,
					imagesLoaded: true,
					initialIndex: i,
					pageDots: false,
					closeEl: true,
					tapToClose: true,
					fullscreen: true,
					on: {
						ready: () => {
							carousel.classList.add('is-visible');
						},
					},
				});

				thisCarousel.viewFullscreen();

				thisCarousel.on('fullscreenChange', function (isFullscreen) {
					if (
						isFullscreen === false &&
						!thisCarousel.element.classList.contains('is-style-carousel')
					) {
						thisCarousel.destroy();
					}
				});
			});
		}
	});
};

/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import md5 from 'md5';
import { gsap, Power4 } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
import { ArticleContent } from 'components/Pages/Shared/ArticleContent';
import cn from 'classnames';

export const AnimatedOffsetBackground = (props) => {
	const { innerBlocks, backgroundColor, offset, className: classNameFromEditor, align } = props;

	const bgRef = React.createRef();

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);

		gsap.set(bgRef.current, {
			backgroundColor: bgRef.current.dataset.backgroundColor,
		});

		gsap.to(bgRef.current, {
			scrollTrigger: bgRef.current,
			scaleX: bgRef.current.dataset.offset / 100,
			backgroundColor: bgRef.current.dataset.backgroundColor,
			ease: Power4.easeOut,
			duration: 1,
		});
	}, [bgRef]);

	const className = cn('animated-offset-bg-container', classNameFromEditor, {
		[`align${align}`]: align === 'wide' || align === 'full',
	});

	return (
		<div className={className}>
			<div
				className="animated-offset-bg-container__bg"
				ref={bgRef}
				data-offset={offset}
				data-background-color={backgroundColor}
				style={{ backgroundColor }}
			/>
			<ArticleContent
				className="animated-offset-bg-container__wrap"
				blocks={innerBlocks}
				post={{ content: {} }}
			/>
		</div>
	);
};

export const map = {
	'starbucks-blocks/animated-offset-background': AnimatedOffsetBackground,
	[md5('starbucks-blocks/animated-offset-background')]: AnimatedOffsetBackground,
};

/* eslint-disable no-unused-vars */
import * as React from 'react';
import md5 from 'md5';
import ThreeImageElement from 'components/ThreeImageElement';
import { Loading } from 'components/Animation/Loading';
import { useRouter } from 'next/router';
import { Link } from 'components/Html';

export const Collage = (props) => {
	const [images, setImages] = React.useState({});
	const router = useRouter();

	const { featured_media, left_featured_media, right_featured_media, title, type, link } =
		props.attributes;

	React.useEffect(() => {
		const loadImages = async () => {
			try {
				const result = await fetch(
					`/api/images?locale=${router.locale}&include=${[
						featured_media,
						left_featured_media,
						right_featured_media,
					].join(',')}`,
				).then((res) => res.json());

				const images = result.images.reduce((acc, curr) => {
					acc[curr.id] = curr;
					return acc;
				}, {});

				setImages(images);
			} catch (e) {
				console.error(e);
			}
		};
		loadImages();
	}, [featured_media, left_featured_media, right_featured_media, router.locale]);

	const leftImage = images[left_featured_media];
	const rightImage = images[right_featured_media];
	const centerImage = images[featured_media];

	if (!leftImage || !centerImage || !rightImage) {
		return <Loading />;
	}

	return (
		<div className="starbucks-collage">
			<Link href={link} locale={false}>
				<ThreeImageElement
					leftImage={leftImage}
					centerImage={centerImage}
					rightImage={rightImage}
				/>
				<span className="starbucks-collage__type">
					{type === 'story' ? 'Stories' : type}
				</span>
				<h3 className="starbucks-collage__title">{title}</h3>
			</Link>
		</div>
	);
};

export const map = {
	'starbucks-blocks/collage-block': Collage,
	[md5('starbucks-blocks/collage-block')]: Collage,
};

import classnames from 'classnames';
import { ArticleContent } from 'components/Pages/Shared/ArticleContent';

export const BlockColumn = ({
	className: classNameFromEditor,
	width,
	innerBlocks,
	anchor,
	post = { content: {} },
}) => {
	let style;

	if (width) {
		// Numbers are handled for backward compatibility as they can be still provided with templates.
		style = { flexBasis: Number.isFinite(width) ? `${width}%` : width };
	}

	const className = classnames('wp-block-column', classNameFromEditor);

	return (
		<div className={className} style={style} id={anchor}>
			<ArticleContent className="column-content" blocks={innerBlocks} post={post} />
		</div>
	);
};

export const BlockColumns = (props) => {
	const {
		className: classNameFromEditor,
		align,
		isStackedOnMobile,
		verticalAlignment,
		innerBlocks,
		post,
	} = props;

	const className = classnames('wp-block-columns', classNameFromEditor, {
		'is-not-stacked-on-mobile': !isStackedOnMobile,
		[`align${align}`]: align,
		[`are-vertically-aligned-${verticalAlignment}`]: verticalAlignment,
	});

	return (
		<div className={className}>
			{innerBlocks.map((columnBlock) => {
				if (columnBlock.name === 'starbucks-blocks/related-posts-interrupter') {
					return null;
				}
				return (
					<BlockColumn
						key={columnBlock.clientId}
						innerBlocks={columnBlock.innerBlocks}
						{...columnBlock.attributes}
						post={post}
					/>
				);
			})}
		</div>
	);
};

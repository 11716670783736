/**
 * Store Search Block
 */
import { useEffect, useState, useRef } from 'react';
import { __ } from '@wordpress/i18n';
import classnames from 'classnames';
import Dialog from '../Dialog';
import TextInput from '../Forms/TextInput';

export const StoreSearch = (props) => {
  const { align, className: classNameFromEditor } = props;

  const [hasSearched, setHasSearched] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [map, setMap] = useState(null);
  const [markerElements, setMarkerElements] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  
  const dialog = useRef(null);
  const mapContainer = useRef(null);
  
  const MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

  const pinSvg =`
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="#00754A" fill-rule="evenodd"/>
    </svg>`;

  const pinActiveSvg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="46">
      <path fill="#00754A" d="M20 46.037c1.732 0 3.412-6.257 5.015-6.67C33.633 37.14 40 29.313 40 20 40 8.954 31.046 0 20 0S0 8.954 0 20c0 9.318 6.372 17.147 14.996 19.37 1.6.41 3.276 6.667 5.004 6.667Z"/>
      <path fill="#FFF" d="M28.688 10.35c-.108-.46-.587-1.65-1.5-1.65H12.81c-.912 0-1.39 1.19-1.503 1.682l-.295 1.634a.641.641 0 0 0 .633.757h.824l1.158 16.44c.06.962.863 1.715 1.83 1.715h9.09c.967 0 1.77-.753 1.83-1.71l1.156-16.445h.826a.642.642 0 0 0 .634-.757l-.302-1.666h-.003Zm-13.216 9.273c0-2.548 2.086-4.622 4.65-4.622 2.563 0 4.648 2.075 4.648 4.623 0 2.546-2.085 4.62-4.648 4.62-2.564 0-4.65-2.074-4.65-4.62v-.001Z"/>
    </svg>`;

  /**
   * Calculates the center point of a set of geographical coordinates.
   *
   * @param {Array} data - An array of objects, each containing 'lat' and 'lng' properties representing geographical coordinates.
   * @returns {object} An object with 'lat' and 'lng' properties representing the center point of the input coordinates.
   */
  const calculateMapCenter = (data) => {
    let latSum = 0;
    let lngSum = 0;

    data.forEach((point) => {
      latSum += parseFloat(point.lat);
      lngSum += parseFloat(point.lng);
    });

    return { lat: latSum / data.length, lng: lngSum / data.length };
  }

  /**
   * useEffect hook to load the Google Maps script.
   *
   * This hook checks if the Google Maps script is already loaded. If not, it creates a new script element
   * and appends it to the body of the document. The script loads the Google Maps JavaScript API with the
   * specified API key and version.
   *
   * The Google Maps JavaScript API is loaded asynchronously, and the `google.maps.__ib__` function is
   * defined as a callback to be executed when the API is loaded.
   *
   * @param {string} MAPS_API_KEY - The API key to use for the Google Maps JavaScript API.
   * @param {string} "weekly" - The version of the Google Maps JavaScript API to load.
   *
   * @returns {void}
   */
  useEffect(() => {
    // Check if the Google Maps script is already loaded
    if (!window.google?.maps?.__ib__) {
      const script = document.createElement('script');
      script.text = `
        (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=\`https://maps.\${c}apis.com/maps/api/js?\`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
          key: "${MAPS_API_KEY}",
          v: "weekly",
        });
      `;
      document.body.appendChild(script);
    }
  }, []);

  /**
   * useEffect hook to perform a store search when `isSearching` is true.
   *
   * This hook is triggered when `isSearching` or `searchQuery` changes. If `isSearching` is true,
   * it fetches store data from the `/api/store-search` endpoint with `searchQuery` as a parameter.
   *
   * The fetched data is then used to update `searchResults` and `markers` states. `isSearching` is
   * set to false after the data is fetched and states are updated.
   *
   * @returns {void}
   */
  useEffect(() => {
    if (isSearching) {
      fetch(`/api/store-search?s=${searchQuery}`)
        .then(response => response.json())
        .then(data => {
          setSearchResults(data);
          setMarkers(data.map(store => ({
            lat: parseFloat(store.meta['sbux_store-data_lat']),
            lng: parseFloat(store.meta['sbux_store-data_lng']),
            id: store.post_title,
            isActive: false,
          })));
          setIsSearching(false);
        });
    }
  }, [isSearching, searchQuery]);

  /**
   * useEffect hook to initialize the Google Map.
   *
   * This hook is triggered when `searchResults` changes. If the Google Maps library is loaded
   * and `searchResults` is not empty, it calculates the center of the map based on the `markers`,
   * imports the Google Maps library, and initializes a new map with the calculated center, a zoom level of 10,
   * and a mapId of 'STORES_MAP'.
   *
   * The initialized map is then set to the `map` state.
   *
   * @returns {void}
   */
  useEffect(() => {
    const initMap = async () => {
      const center = calculateMapCenter(markers);
      const { Map } = await window.google.maps.importLibrary("maps");
      const map = new Map(mapContainer.current, {
        zoom: 10,
        center,
        mapId: 'STORES_MAP',
      });

      setMap(map);
    };

    if (window.google && searchResults.length > 0) {
      initMap();
    }
  }, [searchResults]);

  /**
   * useEffect hook to add markers to the Google Map.
   *
   * This hook is triggered when `map` or `markers` changes. If `map` is defined and `markers` is not empty,
   * it imports the `AdvancedMarkerElement` from the Google Maps library and creates a new marker for each item in `markers`.
   *
   * Each marker is created with a position, map, title, and content. The content is an SVG parsed from a string,
   * and it depends on whether the marker is active.
   *
   * The created markers are then set to the `markerElements` state.
   *
   * @returns {void}
   */
  useEffect(() => {
    const addMarkers = async () => {
      const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

      const parser = new DOMParser();

      const newMarkerElements = markers.map(marker => {
        const content = parser.parseFromString(marker.isActive ? pinActiveSvg : pinSvg, 'image/svg+xml').documentElement;

        return new AdvancedMarkerElement({
          position: { lat: marker.lat, lng: marker.lng },
          map,
          title: marker.id,
          content,
        });
      });

      // Update the markerElements state with the new markers
      setMarkerElements(newMarkerElements);
    };

    if (map && markers.length > 0) {
      addMarkers();
    }
  }, [map, markers]);

  const className = classnames('sbx-store-search', {
    'alignwide': align === 'wide',
    'sbx-store-search--loading': isSearching,
    [classNameFromEditor]: classNameFromEditor,
  });

  const storeDetails = [
    { label: __('Store #', 'starbucks'), value: selectedStore?.post_title },
    { label: __('City', 'starbucks'), value: selectedStore?.city[0].name },
    { label: __('State', 'starbucks'), value: selectedStore?.state[0].name },
    { label: __('Sessions Proposed', 'starbucks'), value: selectedStore?.meta['sbux_store-data_sessions_proposed'] },
    { label: __('Sessions Held', 'starbucks'), value: selectedStore?.meta['sbux_store-data_session_count'] },
    // BE TO DO: make sure the date in the API is in a format that can be parsed by Date.
    { label: __('Last Collective Bargaining Session', 'starbucks'), value: selectedStore?.meta['sbux_store-data_last_session_date'] },
    // BE TO DO: make sure the date in the API is in a format that can unserialized to string and then parsed by Date.
    { label: __('Next Collective Bargaining Session', 'starbucks'), value: selectedStore?.meta['sbux_store-data_next_session_date'] },
    { label: __('Bargaining Status', 'starbucks'), value: selectedStore?.meta['sbux_store-data_bargaining_status'] },
    { label: __('Union', 'starbucks'), value: selectedStore?.meta['sbux_store-data_union'] },
  ];

  return (
    <div className={className}>
      <form className="sbx-store-search__form" onSubmit={(e) => {
        e.preventDefault();
        setIsSearching(true);
        setHasSearched(true);
      }}>
        <TextInput
          id='storeSearch'
          name='storeSearch'
          label={__('Search for a store', 'starbucks')}
          onChange={e => setSearchQuery(e.target.value)}
        />
        <input type="submit" className="sb-button" value={__('Search', 'starbucks')} />
      </form>
      {isSearching ? (
        <div className='sbx-store-search__results'>
          <p>{__('Searching...', 'starbucks')}</p>
        </div>
      ) : searchResults.length > 0 ? (
        <div className='sbx-store-search__results'>
          <table className='sbx-store-search__table'>
            <thead>
              <tr>
                <th>{__('Store #', 'starbucks')}</th>
                <th>{__('City', 'starbucks')}</th>
                <th>{__('State', 'starbucks')}</th>
                <th>{__('Details', 'starbucks')}</th>
              </tr>
            </thead>
            <tbody>
            {searchResults.map((result) => {
              return (
                <tr
                  key={result.ID}
                  onMouseEnter={() => {
                    const parser = new DOMParser();
                    const marker = markerElements.find(marker => marker.title === result.post_title);
                    if (marker) {
                      marker.content = parser.parseFromString(pinActiveSvg, 'image/svg+xml').documentElement;
                    }
                  }}
                  onMouseLeave={() => {
                    const parser = new DOMParser();
                    const marker = markerElements.find(marker => marker.title === result.post_title);
                    if (marker) {
                      marker.content = parser.parseFromString(pinSvg, 'image/svg+xml').documentElement;
                    }
                  }}
                >
                  <td>{result.post_title}</td>
                  <td>{result.city[0].name}</td>
                  <td>{result.state[0].name}</td>
                  <td>
                    <button
                      className="sbx-store-search__details-button"
                      onClick={() => {
                        setSelectedStore(result);
                        dialog.current.show();
                      }}
                    >
                      {__('Learn More', 'starbucks')}
                    </button>
                  </td>
                </tr>
            )})}
            </tbody>
          </table>
          <div className='sbx-store-search__map' ref={mapContainer}></div>
          <Dialog
            id='storeDetailsDialog'
            dialogRef={instance => (dialog.current = instance)}
            title={`Store #${selectedStore?.post_title} details`}
            hideTitle={true}
          >
            <table className="sbx-store-search__store-details">
              <tbody>
                {storeDetails.map((detail, index) => (
                  <tr key={index}>
                    <th>{detail.label}</th>
                    <td>{detail.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Dialog>
        </div>
      ) : hasSearched ? (
        <div className='sbx-store-search__results'>
          <p>No results</p>
        </div>
      ) : null}
    </div>
  );
}

import React, { useState } from 'react';
import { __ } from '@wordpress/i18n';
import CaretDown from 'assets/icons/caret-down.svg';
import { Select } from 'components/Forms/Select';

export const SearchHeader = (props) => {
	const { handleFilterChange, handleClearFilters, filters, activeFilters, total } = props;

	const [expanded, setExpanded] = useState(true);

	return (
		<div className="sb-search-header">
			<div className="sb-search-header__top">
				<div className="sb-search-header__total">
					{__('Showing', 'starbucks')} {total} {__('results', 'starbucks')}
				</div>
				<div className="sb-search-header__toggle">
					<button
						aria-controls="sbSearchHeaderFilters"
						className="sb-search-header__toggle-button"
						onClick={() => {
							setExpanded(!expanded);
						}}
						data-is-toggled={!!expanded}
					>
						<span className="sb-search-header__toggle-text">
							{expanded
								? __('Hide Filters', 'starbucks')
								: __('Filters', 'starbucks')}
						</span>
						<span className="sb-search-header__toggle-icon">
							<CaretDown fill="currentColor" />
						</span>
					</button>
				</div>
			</div>
			<div
				id="sbSearchHeaderFilters"
				className="sb-search-header__filters"
				aria-hidden={!expanded}
			>
				<h2 className="sb-search-header__filters-title sb-h5">
					{__('Filters', 'starbucks')}
				</h2>
				<div className="sb-search-header__filters-wrap">
					<div className="sb-search-filters">
						<form className="sb-search-filters__form">
							<div className="sb-search-filters__wrap">
								{filters.map((filter) => {
									const { name, taxonomy, options } = filter;
									return (
										<div className="sb-search-filters__group" key={taxonomy}>
											<Select
												id="sb-search-header__filter"
												name={name}
												onChange={(e) =>
													handleFilterChange(e.target.value, taxonomy)
												}
												options={options}
												showLabel
												value={activeFilters[taxonomy]}
												nullValue="Select"
												label={name}
											/>
										</div>
									);
								})}
							</div>
							<div className="sb-search-filters__actions">
								{/* BE To Do: <button className='sb-button'>{__('Apply', 'starbucks')}</button> */}
								<button
									className="sb-button sb-button--outline"
									onClick={(e) => handleClearFilters(e)}
								>
									{__('Clear Filters', 'starbucks')}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

import { replaceBlocksWithComponents } from 'utils/blocks';
import { map as StoryDynamicGridMappings } from './StoryGridDynamic';
import { map as RelatedContentInterrupterMappings } from './RelatedContentInterrupter';
import { map as SocialInsertMappings } from './SocialInsert';
import { map as NewslettePressCenter } from './NewsletterPressCenter';
import { map as PressPhotos } from './press-photos/press-photos';
import { map as CollageMappings } from './Collage';
import { map as IconListMappings } from './IconList';
import { map as ArticleHeroMappings } from './ArticleHero';

export const blocks = {
  ...StoryDynamicGridMappings,
  ...RelatedContentInterrupterMappings,
  ...SocialInsertMappings,
  ...NewslettePressCenter,
  ...PressPhotos,
  ...CollageMappings,
  ...IconListMappings,
  ...ArticleHeroMappings,
};

export function BlockComponentsContainer({ post, ...props }) {
	const { children, wrapperProps, posts, images, pagination, showTerms } = props;
	return replaceBlocksWithComponents(
		children,
		wrapperProps || {},
		null,
		post,
		posts,
		images,
		pagination,
		showTerms,
	);
}

export function getComponentForBlock(key) {
	return blocks[key];
}

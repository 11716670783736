import reactStringReplace from 'react-string-replace';
import { TableauEmbed } from 'components/Blocks/TableauEmbed';
import * as React from 'react';

export const renderTableauEmbed = (content, reducedContent) => {
	let originalReducedContent = reducedContent;
	const tableau = Array.from(
		content.matchAll(
			/<!-- starbucks-blocks\/diversity-embed -(.+)- end:starbucks-blocks\/diversity-embed -->/gms,
		),
	).map((match) => match[1]);
	const tableauMarkup = tableau.length > 0 ? tableau[0] : null;

	if (tableauMarkup !== null && tableauMarkup.length > 0) {
		// remove whitespace from before and after string to prevent JSON.parse error
		const tableauMarkupTrimmed = tableauMarkup.replace(/(\r\n|\n|\r)/gm, '').trim();

		let markup = '';
		try {
			markup = JSON.parse(tableauMarkupTrimmed);
		} catch (e) {
			console.log('error parsing tableau markup', e);
			return originalReducedContent;
		}
		originalReducedContent = reactStringReplace(
			originalReducedContent,
			/<!-- starbucks-blocks\/diversity-embed -(.+)- end:starbucks-blocks\/diversity-embed -->/gms,
			() => {
				return <TableauEmbed embed={markup} />;
			},
		);
	}

	return originalReducedContent;
};

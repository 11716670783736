import * as React from 'react';
import { decodeEntities } from 'utils/decode-wrapper';
import ReactPlayer from 'react-player';

let pauseTimout;

/**
 * @todo document this component
 */
class VideoModal extends React.Component {
	state = {
		videoOpen: false,
		videoPlay: null,
	};

	constructor(props) {
		super(props);

		this.state = {
			videoOpen: false,
			videoPlay: null,
		};
	}

	toggleModal = () => {
		this.setState({
			videoOpen: !this.state.videoOpen,
		});
	};

	pauseVideo = () => {
		this.setState({
			videoPlay: 'pause',
		});
		this.hideOnPause(this.state.videoPlay);
	};

	hideOnPause = () => {
		const obj = this;

		pauseTimout = setTimeout(function () {
			if (obj.state.videoPlay === 'pause') {
				obj.toggleModal();
			}
		}, 5000);
	};

	clearTimer = () => {
		clearTimeout(pauseTimout);
	};

	playVideo = () => {
		const videoContainer = document.getElementById('video-modal-video');
		this.clearTimer();

		this.setState({
			videoPlay: 'play',
		});

		videoContainer.focus();
	};

	handleToggleClick = (e) => {
		e.preventDefault();
		this.toggleModal();
	};

	render() {
		if (this.props.video) {
			return (
				<div
					id="video-modal"
					className={`video-modal${this.state.videoOpen ? ' video-modal--open' : ''}`}
				>
					<button className="video-modal__toggle" onClick={this.handleToggleClick}>
						<span
							className="video-modal__toggle-triangle video-modal__toggle-triangle--init"
							aria-hidden="true"
						/>
						<span
							className="video-modal__toggle-triangle video-modal__toggle-triangle--hovered"
							aria-hidden="true"
						/>
						<span className="screen-reader-text">
							{`Play Video for ${decodeEntities(this.props.postTitle)}`}
						</span>
					</button>
					<div
						className="video-modal__video-wrapper"
						visibility={this.state.videoOpen ? 'visible' : 'hidden'}
					>
						<div className="video-modal__video-inner">
							<ReactPlayer
								id="video-modal-video"
								className="video-modal__video"
								tabIndex="0"
								url={this.props.video}
								width="100%"
								height="100%"
								playing={this.state.videoOpen}
								controls
								onEnded={this.toggleModal}
								onPause={this.pauseVideo}
								onPlay={this.playVideo}
							/>
						</div>
					</div>
				</div>
			);
		}
		return null;
	}
}

export default VideoModal;

import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import DOMPurify from 'isomorphic-dompurify';
import SmallCapsLink from './SmallCapsLink';

const CardWithOverlay = ({ img, title, url, opensInNewTap, ctaLabel, ctaAccessibleLabel }) => {
	const cardRef = useRef(null);

	let imageSrc;

	if (img && img.src) {
		imageSrc = img.src;
	}

	if (img && img.url) {
		imageSrc = img.url;
	}

	const handleClick = (e, cardRef) => {
		const cta = cardRef.current.querySelector('.sbx-overlay-card__cta a');

		// If the click target is the CTA, do nothing.
		if (cta.contains(e.target)) {
			return;
		}

		// Otherwise click the CTA.
		cta.click();
	};

	useEffect(() => {
		if (cardRef.current === null) {
			return;
		}

		cardRef.current.addEventListener('click', (e) => handleClick(e, cardRef));

		return () => {
			cardRef.current.removeEventListener('click', (e) => handleClick(e, cardRef));
		};
	}, [cardRef]);

	return (
		<div className="sbx-overlay-card" ref={cardRef}>
			{imageSrc && (
				<div className="sbx-overlay-card__image">
					<Image
						src={imageSrc}
						layout="fill"
						objectFit="cover"
						objectPosition="center"
						alt={title}
					/>
				</div>
			)}
			<div className="sbx-overlay-card__content">
				<h3
					className="sbx-overlay-card__title"
					dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
				/>
				<div className="sbx-overlay-card__cta">
					<SmallCapsLink
						href={url}
						label={ctaLabel}
						opensInNewTap={opensInNewTap}
						accessibleLabel={ctaAccessibleLabel}
					/>
				</div>
			</div>
		</div>
	);
};

CardWithOverlay.propTypes = {
	img: PropTypes.shape({
		src: PropTypes.string.isRequired,
	}),
	title: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	ctaLabel: PropTypes.string.isRequired,
	ctaAccessibleLabel: PropTypes.string.isRequired,
};

export default CardWithOverlay;

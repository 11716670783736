import { A11yDialog } from 'react-a11y-dialog'
import { __ } from '@wordpress/i18n';
import classNames from 'classnames';
import CloseIcon from './close';

const Dialog = ({ dialogRef, title, children, hideTitle = false, ...props }) => {
  let dialog = null;

  return (
    <A11yDialog
      dialogRef={instance => {
        dialogRef(instance);
        dialog = instance;
      }}
      title={title}
      closeButtonPosition = 'none'
      classNames={{
        dialog: 'sbx-dialog__content',
        title: classNames('sbx-dialog__title', {
          'visually-hidden': hideTitle,
        }),
        overlay: 'sbx-dialog__overlay',
        container: 'sbx-dialog',
        closeButton: 'sbx-dialog__close-button',
      }}
      {...props}
    >
      <button className='sbx-dialog__close-button' onClick={() => dialog.hide()}>
        <CloseIcon className='sbx-dialog__close-icon' />
        <span className='visually-hidden'>{__('Close this dialog', 'starbucks')}</span>
      </button>
      <div className='sbx-dialog__content-inner'>
        {children}
      </div>
    </A11yDialog>
  );
}

export default Dialog;
import classnames from 'classnames';
import Image from 'next/image';
import { RawHTML } from 'components/Html/Raw/html';
import { safeALT } from 'utils/safe-alt';

function defaultColumnsNumber(imagesLength) {
	return Math.min(3, imagesLength);
}

export const BlockGallery = ({
	align,
	imageCrop,
	caption,
	className: classNameFromEditor,
	anchor,
	innerBlocks,
	images = [],
	...props
}) => {
	const columns = props.columns || defaultColumnsNumber(innerBlocks.length);

	const className = classnames('wp-block-gallery', classNameFromEditor, {
		[`align${align}`]: align,
		[`has-${columns}-columns`]: columns,
		'is-cropped': imageCrop,
	});

	// This code block sets the 'sizes' attribute for responsive images based on
	// the 'align' prop. The 'sizes' attribute tells the browser how much space
	// the image will take up on the screen, so it can choose the most appropriate
	// image source from the 'srcset' attribute.

	let sizes;

	// If the alignment is 'wide', the image will take up 100% of the viewport
	// width on screens up to 768px wide, will take up a fraction of the viewport
	// width equal to 100 divided by the number of columns on screens up to 1028px
	// wide, and will be a fixed width of 1028 divided by the number of columns on
	// larger screens.
	if (align === 'wide') {
		sizes = `(max-width: 768px) 100vw, (max-width: 1028px) ${100 / columns}vw, ${1028 / columns}px`; //--w-content-wider

	// If the alignment is 'full', the image will take up 100% of the viewport 
	// width on screens up to 768px wide, and will take up a fraction of the 
	// viewport width equal to 100 divided by the number of columns on larger 
	// screens.
	} else if (align === 'full') {
		sizes = `(max-width: 768px) 100vw, ${100 / columns}vw`; //--w-content-full
	// If the alignment is neither 'wide' nor 'full', the image will take up 100% 
	// of the viewport width on screens up to 768px wide, and will be a fixed 
	// width of 700 divided by the number of columns on larger screens.
	} else {
		sizes = `(max-width: 768px) 100vw, ${700 / columns}px`; //--w-content-normal
	}	

	/**
	 * This supports both legacy and new gallery blocks. Legacy blocks provides an
	 * array of image data, whereas new gallery blocks use innerBlocks.
	 *
	 * Here we are simply mapping the data in the innerBlocks to the same format as
	 * the legacy blocks, so that we can utilize the same rendering logic for both.
	 */
	const imageItems =
		innerBlocks.length > 0
			? innerBlocks.map(({ attributes }) => ({
					alt: attributes.alt,
					caption: attributes.caption,
					fullUrl: attributes.fullUrl,
					id: attributes.id,
					link: attributes.link,
					url: attributes.url,
					height: attributes.mediaHeight,
					width: attributes.mediaWidth,
			  }))
			: images;

	return (
		<figure className={className} id={anchor}>
			{imageItems &&
				imageItems.map((data) => {
					let href;

					const { alt, caption, fullUrl, id, link, url, height, width } = data;

					let style = {
						...(imageCrop ? { objectFit: 'cover', height: '100%' } : {}),
					};

					const img = (
						<Image
							src={url.replace(/^https:/, "http:")}
							alt={safeALT(alt)}
							data-id={id}
							data-full-url={fullUrl}
							data-link={link}
							className={id ? `wp-image-${id}` : null}
							height={height}
							width={width}
							style={style}
							sizes={sizes}
						/>
					);

					const imageClassName = classnames('wp-block-gallery__item', {
						'has-caption': caption,
					});

					return (
						<figure key={id} className={imageClassName}>
							{href ? <a href={href}>{img}</a> : img}
							{caption && (
								<RawHTML
									tagName="figcaption"
									className="wp-block-gallery__item-caption"
									value={caption}
								/>
							)}
						</figure>
					);
				})}
			{caption && (
				<RawHTML
					tagName="figcaption"
					className="wp-block-gallery__caption"
					value={caption}
				/>
			)}
		</figure>
	);
};

import React, { useState } from 'react';
import Share from 'assets/icons/share.svg';

// i18n
import { __ } from '@wordpress/i18n';
import { PullquoteShareList } from './List';

/**
 * @todo document this component
 */
export const PullquoteShare = (props) => {
	const [socialOpen, setSocialOpen] = useState(false);

	/**
	 * @todo document this component
	 */
	const triggerClick = (e) => {
		e.preventDefault();
		setSocialOpen(!socialOpen);
	};

	return (
		<div className={`pullquote-share article__content ${props.classes}`}>
			<blockquote className="pullquote-share__blockquote alignwide has-text-align-wide">
				<p>{props.quote}</p>
				{props.cite && <cite>{props.cite}</cite>}
			</blockquote>

			<div
				className={`sb-social-share${socialOpen ? ' sb-social-share--open' : ''}`}
				aria-labelledby="sb-social-share-button"
				role="region"
			>
				<div className="sb-social-share__wrapper">
					<button
						id="sb-social-share-button"
						className="sb-social-share__button"
						aria-expanded="true"
						onClick={triggerClick}
					>
						<span className="screen-reader-text">
							{__('Share This Article', 'starbucks')}
						</span>
						<Share className="sb-icon sb-social-share__icon" />
					</button>

					<PullquoteShareList
						quote={props.quote}
						url={props.url}
						image={props.image}
						label="Pullquote"
					/>
				</div>
			</div>
		</div>
	);
};

import { saveAs } from 'file-saver';

/**
 * Force download image links
 */
export const forceDownload = (e, url) => {
	e.preventDefault();

	const linkHref = url;

	const linkFilename = linkHref.replace(/.*\//g, '');

	fetch(linkHref)
		.then((res) => res.blob())
		.then((blob) => saveAs(blob, linkFilename));
};

/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import md5 from 'md5';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
import { IconListItem } from './IconListItem';

export const IconList = ({ innerBlocks }) => {
	const listRef = React.createRef();

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);

		gsap.set(listRef.current.children, { opacity: 0, x: 100 });

		gsap.to(listRef.current.children, {
			scrollTrigger: listRef.current,
			opacity: 1,
			x: 0,
			stagger: 0.1,
		});
	}, [listRef]);

	if (!innerBlocks || innerBlocks.length < 1) {
		return null;
	}

	return (
		<div className="icon-list">
			<div className="icon-list__wrap">
				<ul className="icon-list__list" ref={listRef}>
					{innerBlocks.map((block) => {
						// Make sure the child block exists
						if (block?.clientId?.length < 1) {
							return null;
						}

						const {
							clientId,
							attributes: { content, icon },
						} = block;

						return (
							<IconListItem
								key={clientId}
								id={clientId}
								icon={icon}
								text={content}
							/>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export const map = {
	'starbucks-blocks/animated-icons-list': IconList,
	[md5('starbucks-blocks/animated-icons-list')]: IconList,
};

/**
 * Fetch link as blob.
 *
 * @param {string} linkHref Link.
 *
 * @returns blobl
 */
export const fetchUrlAsBlob = async (linkHref) => {
	const data = await fetch(linkHref).then((res) => res.blob());

	return data;
};

import React from 'react';
import DOMPurify from 'isomorphic-dompurify';
import Image from 'next/image';

export const IconListItem = ({ text, icon: {
	alt,
	url,
	width,
	height,
} }) => {

	return (
		<div className="icon-list__item">
			{url && (
				<div className="icon-list__icon-wrap">
					<Image
						alt={alt}
						className="icon-list__icon"
						height={height}
						sizes='80px'
						src={url}
						width={width}
					/>
				</div>
			)}
			{text && (
				<p
					className="icon-list__icon-text"
					dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
				/>
			)}
		</div>
	);
};

import React, { useState } from 'react';
import { decodeEntities } from 'utils/decode-wrapper';
import CloseIcon from 'assets/icons/close.svg';
import { Link } from 'components/Html';
import { transformLink } from 'lib/wp/cpt/shared/utils';
import { useRouter } from 'next/router';
import { __ } from '@wordpress/i18n';
/**
 * Flyout component.
 *
 * @param {object} props
 */
const Flyout = ({ content: { heading, link, thumbnail, title } }) => {
	const [visible, setVisible] = useState(true);
	const router = useRouter();
	const mainElement = React.useRef(null);

	const closeFlyout = () => {
		setVisible(false);
	};

	return (
		<div
			ref={mainElement}
			className={`flyout-post ${visible ? 'flyout-post--visible' : 'flyout-post--hidden'}`}
		>
			{thumbnail ? (
				<div className="flyout-post__thumb">
					<img src={thumbnail} alt={`thumbnail for ${title}`} />
				</div>
			) : null}
			<div className="flyout-post__content">
				<p className="flyout-post__label">{heading}</p>
				<p className="flyout-post__title">
					<Link href={transformLink(link, null, router.locale)}>
						{decodeEntities(title)}
					</Link>
				</p>
			</div>

			<button className="flyout-post__close" onClick={() => closeFlyout()}>
				<span className="visually-hidden">{__('Close', 'starbucks')}</span>
				<CloseIcon />
			</button>
		</div>
	);
};

export default Flyout;

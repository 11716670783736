/**
 * Video Utilities
 */

export const getProvider = (src) => {
	const regexp =
		/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

	return regexp.test(src) ? 'youtube' : 'wistia';
};

import classnames from 'classnames';
import { kebabCase } from 'utils/string';
import { getColorClassName } from 'utils/post';
import { RawHTML } from 'components/Html/Raw/html';
import useAnimatedText from 'hooks/useAnimatedText';

export const BlockParagraph = ({
	align,
	content,
	dropCap,
	backgroundColor,
	textColor,
	customBackgroundColor,
	customTextColor,
	fontSize,
	customFontSize,
	className: classNameFromEditor,
	anchor,
	animation = '',
}) => {
	const textClass = getColorClassName('color', textColor);

	const backgroundClass = getColorClassName('background-color', backgroundColor);

	const fontSizeClass = fontSize ? `has-${kebabCase(fontSize)}-font-size` : '';

	const className = classnames(classNameFromEditor, {
		'has-text-color': textColor || customTextColor,
		'has-background': backgroundColor || customBackgroundColor,
		'has-drop-cap': dropCap,
		[`has-text-align-${align}`]: align === 'left' || align === 'right' || align === 'center',
		[`align${align}`]: align === 'wide' || align === 'full',
		[fontSizeClass]: fontSizeClass,
		[textClass]: textClass,
		[backgroundClass]: backgroundClass,
		[`has-${kebabCase(animation)}-animation`]: animation,
	});

	const styles = {
		backgroundColor: backgroundClass ? undefined : customBackgroundColor,
		color: textClass ? undefined : customTextColor,
		fontSize: fontSizeClass ? undefined : customFontSize,
	};

	const ref = useAnimatedText(animation);

	return (
		<RawHTML
			className={className || undefined}
			id={anchor}
			ref={ref}
			style={styles}
			tagName="p"
			value={content}
		/>
	);
};

import classnames from 'classnames';
import { kebabCase } from 'utils/string';
import { RawHTML } from 'components/Html/Raw/html';
import useAnimatedText from 'hooks/useAnimatedText';
import { getColorClassName } from 'utils/post';

export const BlockHeading = (props) => {
	const {
		align,
		anchor,
		animation = '',
		className: classNameFromEditor,
		content,
		customTextColor,
		level,
		textAlign,
		textColor,
		fontFamily,
		fontSize,
	} = props;
	const tagName = `h${level}`;

	const textClass = getColorClassName('color', textColor);

	const className = classnames(classNameFromEditor, {
		'has-text-color': textColor || customTextColor,
		[`has-text-align-${align}`]: align,
		[`has-text-align-${textAlign}`]: textAlign,
		[`has-font-size-${fontSize}`]: fontSize,
		[`has-${fontFamily}-font-family`]: fontFamily,
		[`has-${kebabCase(animation)}-animation`]: animation,
		[textClass]: textClass,
	});

	const ref = useAnimatedText(animation);

	return (
		<RawHTML
			className={className || undefined}
			id={anchor}
			ref={ref}
			tagName={tagName}
			value={content}
		/>
	);
};

/* eslint-disable no-unused-vars */
import React from 'react';
import md5 from 'md5';

export const LinkedIn = ({ url, height = 500, width = 500 }) => {
	return (
		<div className="sbx-iframe-embed">
			<iframe
				src={url}
				style={{ width: `${width}px` }}
				height={height}
				width={width}
				frameBorder="0"
				allowFullScreen=""
				title="Embedded post"
			/>
		</div>
	);
};

export const map = {
	'starbucks-blocks/linkedin': LinkedIn,
	[md5('starbucks-blocks/linkedin')]: LinkedIn,
};

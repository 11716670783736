import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
import { RawHTML } from 'components/Html/Raw/html';
import { addQueryArgs, getQueryArg } from '@wordpress/url';
import { apiGet } from 'utils/api';
import { getProvider } from 'utils/video';

export const EmbedContainer = ({ html, scripts, providerNameSlug, autoplay, style }) => {
	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(false);
	const htmlElement = useRef();

	/**
	 * Inserts the element's script container into the head. Without this, several of the embed types, including
	 * Twitter and Instagram, will never be initiated.
	 */
	useEffect(() => {
		if (!htmlElement.current) {
			return;
		}

		[...htmlElement.current.getElementsByTagName('script')].forEach((element) => {
			const src = element.getAttribute('src');
			if (src) {
				const scriptTag = document.createElement('script');
				scriptTag.src = src;
				document.head.appendChild(scriptTag);
			}
		});

		setLoading(true);
	}, []);

	/**
	 * Checks whether the library for the provider is ready.
	 */
	useEffect(() => {
		if (!loading) {
			return;
		}

		const scriptIsLoaded = () => {
			let isLoaded = false;

			switch (providerNameSlug) {
				case 'instagram':
					isLoaded = 'instgrm' in window;
					break;

				case 'twitter':
					isLoaded = 'twttr' in window && 'widgets' in window.twttr;
					break;

				case 'facebook':
					isLoaded = 'FB' in window;
					break;

				default:
					isLoaded = true;
			}

			return isLoaded;
		};

		const checkLoaded = () => {
			if (scriptIsLoaded()) {
				clearInterval(loadedInterval);
				setLoading(false);
				setLoaded(true);
			}
		};

		const loadedInterval = setInterval(checkLoaded, 25);

		return () => {
			clearInterval(loadedInterval);
		};
	}, [loading, providerNameSlug]);

	useEffect(() => {
		if (!loaded) {
			return;
		}

		switch (providerNameSlug) {
			case 'instagram': {
				window.instgrm.Embeds.process();
				break;
			}

			case 'twitter': {
				window.twttr.widgets.load();
				break;
			}

			case 'facebook': {
				window.FB.XFBML.parse();
			}
		}
	}, [loaded, providerNameSlug]);

	useEffect(() => {
		const isMobile = window.matchMedia('(max-width: 767px)').matches;

		if (!autoplay || isMobile) {
			return;
		}

		const fullscreenAtts = [
			'allowfullscreen',
			'mozallowfullscreen',
			'webkitallowfullscreen',
			'msallowfullscreen',
		];

		gsap.registerPlugin(ScrollTrigger);

		const initAutoplay = (iframe, provider) => {
			const src = iframe.getAttribute('src');

			const atts = {
				autoplay: 1,
			};

			if (provider === 'wistia') {
				atts.autoplay = 'true';
				atts.silentAutoplay = 'true';
				atts.volumeControl = 'true';
				atts.volume = 0;
			} else {
				// youtube
				atts.mute = 1;
			}

			if (!getQueryArg(src, 'autoplay')) {
				// Add the necessary fullscreen attributes to iframe
				// We use toggleAttribute to simply set an empty attribute with no =""
				fullscreenAtts.forEach((att) => {
					if (!iframe.hasAttribute(att)) {
						iframe.toggleAttribute(att);
					}
				});

				iframe.setAttribute('src', addQueryArgs(src, atts));
			}
		};

		if (htmlElement && htmlElement.current) {
			const iframe = htmlElement.current.querySelector('iframe');
			let provider = null;

			// Check if we have an iframe.
			if (iframe) {
				provider = getProvider(iframe.src);
			}

			// Only proceed if wistia or youtube.
			if (provider === 'youtube' || provider === 'wistia') {
				ScrollTrigger.create({
					trigger: htmlElement.current,
					once: true,
					start: 'top 80%',
					onEnter: () => initAutoplay(iframe, provider),
				});
			}
		}
	}, [htmlElement, autoplay]);

	// Add any necessary scripts
	if (scripts && (providerNameSlug === 'facebook' || providerNameSlug === 'instagram')) {
		scripts.map((script) => (html += `<script src="${script}"></script>`));

		if (providerNameSlug === 'facebook') {
			window.jpfbembed = {
				appid: '249643311490',
				locale: 'en_US',
			};
		}
	}

	// update query parameters. See https://developers.google.com/youtube/player_parameters
	const updatedHtml = html.replace('?feature=oembed', '?feature=oembed&rel=0&enablejsapi=1');

	return (
		<div
			className="wp-block-embed__wrapper"
			ref={htmlElement}
			dangerouslySetInnerHTML={{ __html: updatedHtml }}
			style={style}
		/>
	);
};

export const BlockEmbed = (props) => {
	const {
		align,
		anchor,
		autoplay = true,
		caption,
		className: classNameFromEditor,
		providerNameSlug,
		type,
		url,
	} = props;

	const [html, setHtml] = useState();
	const [scripts, setScripts] = useState();
	const [aspectRatio, setAspectRatio] = useState('16/9');

	useEffect(() => {
		const fetchOEmbedData = async () => {
			const { json } = await apiGet(addQueryArgs('/api/embed', { url }));

			if (typeof json === 'object') {
				if ('html' in json) {
					setHtml(json.html);
				}

				if ('width' in json && 'height' in json) {
					const { width, height } = json;
					setAspectRatio(`${width}/${height}`);
				}

				if ('scripts' in json) {
					setScripts(json.scripts);
				}
			}
		};

		if (url) {
			fetchOEmbedData();
		}
	}, [url]);

	if (!url) {
		return null;
	}

	const className = classnames('wp-block-embed', classNameFromEditor, {
		[`align${align}`]: align,
		[`is-type-${type}`]: type,
		[`is-provider-${providerNameSlug}`]: providerNameSlug,
		'is-brightcove': url.search('https?://players.brightcove.net/') !== -1,
	});

	return (
		<figure className={className} id={anchor}>
			{html && (
				<EmbedContainer
					html={html}
					scripts={scripts}
					providerNameSlug={providerNameSlug}
					autoplay={autoplay}
					style={{ aspectRatio }}
				/>
			)}
			{caption && <RawHTML tagName="figcaption" value={caption} />}
		</figure>
	);
};

import { ArticleContent } from 'components/Pages/Shared/ArticleContent';
import classnames from 'classnames';

export const BlockDetails = ({
	className: classNameFromEditor,
	innerBlocks,
	showContent = false,
	summary,
}) => {
	const className = classnames('wp-block-details', classNameFromEditor);

	return (
		<details className={className} open={showContent === true}>
			<summary className="wp-block-details__summary">{summary}</summary>
			<ArticleContent
				blocks={innerBlocks}
				className="wp-block-details__content article__content"
				post={{ content: {} }}
			/>
		</details>
	);
};

import React, { useCallback, useEffect, useState } from 'react';
import { Loading } from 'components/Animation/Loading';
import { NoResults } from 'components/Search/NoResults';
import CardGrid from 'components/Posts/CardGrid';
import { addQueryArgs } from '@wordpress/url';
import { SearchHeader } from 'components/Search/HeaderCoffeeStorytelling';
import { __ } from '@wordpress/i18n';
import classnames from 'classnames';

const API_ENDPOINT = process.env.NEXT_PUBLIC_WP_API_URL;

export default function StorytellingArchive(props) {
	const { filters: availableFilters, className: classNameFromEditor, align } = props;
	const [isLoading, setIsLoading] = useState(false);
	const [results, setResults] = useState([]);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [searchFilters, setSearchFilters] = useState({});

	const className = classnames('sbx-cs-archive', classNameFromEditor, {
		[`align${align}`]: align,
	});

	/**
	 * Handle Filter Change
	 */
	const handleFilterChange = useCallback(
		(value, taxonomy) => {
			setIsLoading(true);
			// set 'paged'' back to 1 whenever we change taxonomy
			resetPage();

			if (value && taxonomy) {
				const params = {};
				params[taxonomy] = value;
				const updated = { ...searchFilters, ...params };
				setSearchFilters(updated);
				loadResults(updated);
			}
		},
		[searchFilters],
	);

	/**
	 * Utility to remove empty items
	 *
	 * @param {*} obj
	 */
	const removeEmpty = (obj) => {
		// remove all the properties of the object that are 'null' (string) - Selector component uses the string 'null' for default null value
		Object.keys(obj).forEach((key) => {
			if (obj[key] === '' || obj[key] === 'null') {
				delete obj[key];
			}
		});

		return obj;
	};

	const handleClearFilters = (e) => {
		resetPage();
		loadResults();
		setSearchFilters({
			theme: 'null',
			stage: 'null',
			role: 'null',
			region: 'null',
		});
		e.preventDefault();
	};

	const handleLoadMore = () => {
		const params = {
			page: page + 1,
		};
		setPage(page + 1);
		loadResults({ ...searchFilters, ...params }, true);
	};

	const resetPage = () => {
		setPage(1);
	};

	// load the initial results
	useEffect(() => {
		loadResults();
	}, []);

	const loadResults = (params, append = false) => {
		let filteredParams = {};
		if (params) {
			filteredParams = removeEmpty(params);
		}

		const url = addQueryArgs('/api/storytelling', filteredParams);

		fetch(url)
			.then((res) => {
				setTotal(res.headers.get('x-wp-total'));
				return res.json();
			})
			.then((res) => {
				if (append) {
					setResults(results.concat(parsePosts(res.posts)));
				} else {
					setResults(parsePosts(res.posts));
				}
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const parsePosts = (results) => {
		const parsedPosts = results.map((post) => {
			return {
				id: post.id,
				title: post.title.rendered,
				url: post.link,
				type: post.type,
				subtype: post.subtype,
				link: post.link,
				image: post.image,
				date: post.date,
				hide_date: post.hide_date,
			};
		});
		return parsedPosts;
	};

	return (
		<div className={className}>
			<div className="sbx-cs-archive__filters">
				<SearchHeader
					total={total}
					term=""
					handleFilterChange={handleFilterChange}
					handleClearFilters={handleClearFilters}
					filters={availableFilters}
					activeFilters={searchFilters}
				/>
			</div>

			{isLoading && <Loading />}

			{!isLoading && (
				<div className="sbx-cs-archive__cards">
					{total > 0 ? (
						<CardGrid stories={results} enforceNoMedia />
					) : (
						<NoResults query={false} menu="" showTips={false} />
					)}
				</div>
			)}

			{total > results.length && (
				<div className="sbx-cs-archive__footer">
					<button className="sb-button" onClick={() => handleLoadMore()}>
						{__('Load More', 'starbucks')}
					</button>
				</div>
			)}
		</div>
	);
}

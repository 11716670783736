/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import md5 from 'md5';
import gsap, { Power4 } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
import { Icons } from 'assets/icons';
import { ArticleContent } from 'components/Pages/Shared/ArticleContent';

export const AnimatedSVGContainer = ({ innerBlocks, iconOne, iconTwo, post }) => {
	const svgContainerRef = useRef(null);
	const svgOneRef = useRef(null);
	const svgTwoRef = useRef(null);
	const IconOne = Icons[iconOne];
	const IconTwo = Icons[iconTwo];

	gsap.registerPlugin(ScrollTrigger);

	useEffect(() => {
		if (svgOneRef.current === null || svgTwoRef.current === null) {
			return;
		}

		const svgPaths = [
			svgOneRef.current.querySelectorAll('path'),
			svgTwoRef.current.querySelectorAll('path'),
		];

		gsap.fromTo(
			svgPaths,
			{
				opacity: 0,
				scale: 0,
				transformOrigin: 'center center',
			},
			{
				duration: 1,
				ease: Power4.easeOut,
				opacity: 1,
				scale: 1,
				stagger: 0.1,
				scrollTrigger: {
					trigger: svgContainerRef.current,
					start: 'top 80%',
				},
			},
		);
	}, []);

	return (
		<div className="sbx-icon-container" ref={svgContainerRef}>
			<div className="sbx-icon-container__icons">
				{IconOne && (
					<div
						className="sbx-icon-container__icon sbx-icon-container__icon--one"
						ref={svgOneRef}
					>
						<IconOne />
					</div>
				)}

				{IconTwo && (
					<div
						className="sbx-icon-container__icon sbx-icon-container__icon--two"
						ref={svgTwoRef}
					>
						<IconTwo />
					</div>
				)}
			</div>

			{innerBlocks && (
				<div className="sbx-icon-container__content">
					<ArticleContent
						blocks={innerBlocks}
						post={post}
						className="sbx-icon-container__blocks article__content"
					/>
				</div>
			)}
		</div>
	);
};

export const map = {
	'starbucks-blocks/animated-svg-container': AnimatedSVGContainer,
	[md5('starbucks-blocks/animated-svg-container')]: AnimatedSVGContainer,
};

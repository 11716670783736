/* eslint-disable no-undef */

import classnames from 'classnames';
import { ArticleContent } from 'components/Pages/Shared/ArticleContent';
import ReactPlayer from 'react-player';

export const BlockGroup = (props) => {
	const {
		align,
		anchor,
		backgroundColor,
		backgroundImage,
		backgroundVideo,
		className: classNameFromEditor,
		innerBlocks,
		layout,
		tagName,
		textColor,
	} = props;

	const Tag = tagName;

	const className = classnames('wp-block-group', classNameFromEditor, {
		'has-background-color': backgroundColor,
		'has-background-img': !!backgroundImage?.url,
		'has-background-video': !!backgroundVideo,
		'has-text-color': textColor,
		'is-layout-unconstrained': layout?.type === 'default',
		'is-layout-constrained': layout?.type === 'constrained',
		'has-custom-content-size': layout?.type === 'constrained' && layout?.contentSize,
		[`align${align}`]: align,
		[`has-${backgroundColor}-background-color`]: backgroundColor,
		[`has-${textColor}-color`]: textColor,
	});

	const style = {};

	if (backgroundImage?.url) {
		style['--background-image'] = `url(${backgroundImage?.url})`;
	}

	const innerStyle = {};

	if (layout && layout?.contentSize !== 'undefined') {
		innerStyle.maxWidth = layout.contentSize;
	}

	return (
		<Tag id={anchor} className={className} style={style}>
			{backgroundVideo && (
				<ReactPlayer
					url={backgroundVideo}
					playing
					muted
					loop
					className="wp-block-group__background-video"
				/>
			)}
			<ArticleContent
				className="wp-block-group__inner-container article__content"
				style={innerStyle}
				blocks={innerBlocks}
				post={{ content: {} }}
			/>
		</Tag>
	);
};

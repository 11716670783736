import { useContext, useEffect } from 'react';
import { __ } from '@wordpress/i18n';
import md5 from 'md5';
import { PostContentContext } from 'components/Posts/Content';

/**
 * @todo document this function
 */
const RelatedContentInterrupter = () => {
	const context = useContext(PostContentContext);

	const { isRelatedPostsInterrupterRendered, renderRelatedPosts, currentPost, setContext } =
		context || {};

	const { disable_interrupter, original, press_type } = currentPost || {};

	// don't render this component:
	const disableInterrupter =
		// if the interrupter is set to disabled via metabox
		disable_interrupter === 'true' ||
		// or we're on a production
		// (original is the taxonomy name for Starbucks productions)
		(!!original && !!original.length) ||
		// or this is a view press type
		// 148 is the term ID for "views"
		(!!press_type && !!press_type.length && !!press_type.includes(148));

	useEffect(() => {
		return () => {
			// on unmount, set is rendered to false
			if (setContext) {
				setContext({ isRelatedPostsInterrupterRendered: false });
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (setContext && !isRelatedPostsInterrupterRendered && !disableInterrupter) {
			setContext({ isRelatedPostsInterrupterRendered: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRelatedPostsInterrupterRendered]);

	// don't show this component if the interrupter is disabled
	if (disableInterrupter) {
		return null;
	}

	return renderRelatedPosts
		? renderRelatedPosts({
				variation: 'interrupter',
				title: __('More Like This', 'starbucks'),
		  })
		: null;
};

export default RelatedContentInterrupter;

export const hash = md5('starbucks-blocks/related-posts-interrupter');

export const map = {
	'starbucks-blocks/related-posts-interrupter': RelatedContentInterrupter,
	[hash]: RelatedContentInterrupter,
	hashString: hash,
};

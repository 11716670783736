import * as React from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import Facebook from 'assets/icons/facebook.svg';
import Twitter from 'assets/icons/twitter.svg';

// i18n
import { __ } from '@wordpress/i18n';

/**
 * @todo document this function
 * @param {*} props
 */
export const PullquoteShareList = ({ label, quote, url }) => {
	const twitterAccount = process.env.REACT_APP_TWITTER_ACCOUNT
		? process.env.REACT_APP_TWITTER_ACCOUNT
		: 'StarbucksNews';

	return (
		<ul className="sb-social-share__list">
			<li className="sb-social-share__item">
				<FacebookShareButton
					className="sb-social-share__link  sb-social-share__link--facebook"
					resetButtonStyle={false}
					url={url}
					quote={quote}
					data-social-category="Share"
					data-social-action="Facebook"
					data-social-label={label}
				>
					<Facebook className="sb-icon sb-social-share__icon" />
					<span className="screen-reader-text">
						{__('Share on Facebook', 'starbucks')}
					</span>
				</FacebookShareButton>
			</li>
			<li className="sb-social-share__item">
				<TwitterShareButton
					className="sb-social-share__link  sb-social-share__link--twitter"
					url={url}
					resetButtonStyle={false}
					title={quote}
					via={twitterAccount}
					data-social-category="Share"
					data-social-action="Twitter"
					data-social-label={label}
				>
					<Twitter className="sb-icon sb-social-share__icon" />
					<span className="screen-reader-text">
						{__('Share on Twitter', 'starbucks')}
					</span>
				</TwitterShareButton>
			</li>
		</ul>
	);
};

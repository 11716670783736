/* eslint-disable no-unused-vars */
import React from 'react';
import md5 from 'md5';
import Image from 'next/image';

export const ArticleGallery = (props) => {
	const { innerBlocks, layout = 'three-images' } = props;

	if (!innerBlocks || innerBlocks.length < 1) {
		return null;
	}

	return (
		<div className={`article-gallery is-style-${layout}`}>
			{innerBlocks.map((block, i) => {
				// Make sure the child block exists
				if (block?.clientId?.length < 1) {
					return null;
				}

				const {
					clientId,
					attributes: { media, focalPoint },
				} = block;

				const focalPointStyle = {};

				if (focalPoint) {
					focalPointStyle.objectPosition = `${focalPoint?.x * 100}% ${
						focalPoint?.y * 100
					}%`;
				}

				if (media?.url) {
					return (
						<div
							key={clientId}
							className={`article-gallery__item article-gallery__item--${i + 1}`}
						>
							<Image
								src={media.url}
								alt={media?.alt}
								style={focalPointStyle}
								height={media?.height}
								width={media?.width}
							/>
						</div>
					);
				}
			})}
		</div>
	);
};

export const map = {
	'starbucks-blocks/article-gallery': ArticleGallery,
	[md5('starbucks-blocks/article-gallery')]: ArticleGallery,
};

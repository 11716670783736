import React, { Component } from 'react';
import Image from 'next/image';
import ReactPlayer from 'react-player';
import VideoModal from 'components/Modal/Video';

const heroImageWidth = 1400;
const verticalImageWidth = 520;

class PostHero extends Component {
	state = {
		videoOpen: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			videoOpen: false,
		};
	}

	heroImage = () => {
		const { image } = this.props;
		const { video } = this.props;

		let minWidth = heroImageWidth;
		let imageWidth = 0;

		imageWidth = image.width;

		if (this.props.orientation === 'vertical') {
			minWidth = verticalImageWidth;
		}

		if (minWidth > imageWidth) {
			return;
		}

		return (
			<div className="article-hero__image-contain">
				{this.props.children}

				<Image
					layout="fill"
					objectFit="cover"
					objectPosition="center"
					src={image.src}
					alt={image.alt}
					priority
					sizes="(max-width: 1440px) 100vw, 1440px"
					className={this.props.className}
				/>

				<VideoModal
					video={video}
					videoOpen={this.state.videoOpen}
					postTitle={this.props.postTitle}
				/>
			</div>
		);
	};

	render() {
		const { image } = this.props;

		if (this.props.wistiaUrl) {
			return (
				<ReactPlayer url={this.props.wistiaUrl} className="video-heading" playing muted />
			);
		}

		const imageWidth = image?.width ?? 0;
		let minWidth = heroImageWidth;

		if (this.props.orientation === 'vertical') {
			minWidth = verticalImageWidth;
		}

		if (minWidth > imageWidth) {
			return null;
		}

		return (
			<div
				className={`article-hero${
					this.props.location ? ` article-hero--${this.props.location}` : ''
				}${this.props.video ? ' article-hero--video' : ''}`}
			>
				{this.heroImage()}
			</div>
		);
	}
}

export default PostHero;

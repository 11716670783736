import * as React from 'react';
import { Link } from 'components/Html';
import { transformLink } from 'lib/wp/cpt/shared/utils';

/**
 * @todo document this component
 */
export class StickySubNav extends React.Component {
	state = {
		scrolled: null,
		topPos: 0,
		originTop: null,
	};

	constructor(props) {
		super(props);

		this.state = {
			scrolled: null,
			topPos: 0,
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		const offset = window.pageYOffset + 20;
		const nav = document.querySelector('.sticky-sub-nav');
		const siteHeader = document.querySelector('.sb-site-header');
		const topPos = siteHeader.clientHeight;

		const articleHero = document.querySelector('.article-hero');
		const articleHeader = document.querySelector('.article-header');

		const navTop = articleHero
			? articleHero.clientHeight + articleHeader.clientHeight
			: articleHeader.clientHeight;

		if (offset >= navTop - nav.clientHeight) {
			this.setState({
				scrolled: true,
				topPos,
			});
		} else {
			this.setState({
				scrolled: false,
				topPos: 0,
			});
		}
	};

	render() {
		const style = {
			top: `${this.state.topPos}px`,
		};

		const { navItems } = this.props;

		return (
			<div
				style={style}
				className={`sticky-sub-nav ${
					this.state.scrolled ? 'sticky-sub-nav--is-fixed' : ''
				} `}
			>
				<div className="sticky-sub-nav__wrap">
					<div className="sticky-sub-nav__title">Partners</div>
					<ul className="sticky-sub-nav__items">
						{navItems.map((item) => (
							<li
								key={item.name}
								className={`sticky-sub-nav__item ${
									item.current ? 'sticky-sub-nav__item--current' : ''
								}`}
							>
								<Link
									href={transformLink(
										item.url,
										item.type || null,
										this.props.locale || '',
									)}
									className="sticky-sub-nav__link"
								>
									{item.name}
								</Link>
							</li>
						))}
					</ul>
					<div />
				</div>
			</div>
		);
	}
}

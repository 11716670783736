import React from 'react';
import Image from 'next/image';
import md5 from 'md5';
import SignUpForm from 'components/Newsletter';
import ChevronRight from 'assets/icons/chevron-right.svg';
import { useRouter } from 'next/router';
import { __ } from '@wordpress/i18n';

/**
 * Newsletter Press Center block
 */
export const NewsletterPressCenter = (props) => {
	const {
		attributes: { pressCenterTitle, pressCenterContent, pressCenterCta, pressCenterLink },
	} = props;
	const router = useRouter();

	// TODO: make this a Gutenberg block option
	const showNewsletterSignup = !['en-ca', 'fr', 'emea', 'asia'].includes(router.locale);

	return (
		<div className="newsletter-press-center">
			{showNewsletterSignup ? (
				<div className="newsletter-press-center__newsletter">
					<div className="newsletter-press-center__content">
						<h3 className="newsletter-press-center__title">
							{__('Stay in touch', 'starbucks')}
						</h3>
						<SignUpForm />
					</div>
					<div className="newsletter-press-center__image">
						<Image
							src="/images/stay-in-touch.png"
							alt="Person reading the news"
							width="392"
							height="304"
						/>
					</div>
				</div>
			) : null}
			<div
				className={
					!showNewsletterSignup
						? 'newsletter-press-center__press-center newsletter-press-center__press-center--fullwidth'
						: 'newsletter-press-center__press-center'
				}
			>
				<a href={pressCenterLink} className="newsletter-press-center__press-center-link">
					<div className="newsletter-press-center__content">
						<h3
							className="newsletter-press-center__title"
							dangerouslySetInnerHTML={{ __html: pressCenterTitle }}
						/>
						<p dangerouslySetInnerHTML={{ __html: pressCenterContent }} />
						<div className="wp-block-button is-style-link">
							<span
								className="wp-block-button__link"
								dangerouslySetInnerHTML={{ __html: pressCenterCta }}
							/>
							<ChevronRight className="sb-icon sb-signup__icon" aria-hidden="true" />
						</div>
					</div>
					<div className="newsletter-press-center__image">
						<Image
							src="/images/coffee-fill-illustration.png"
							alt="Coffee Cup Illustration"
							width="392"
							height="304"
						/>
					</div>
				</a>
			</div>
		</div>
	);
};

export const map = {
	'starbucks-blocks/newsletter-press-center': NewsletterPressCenter,
	[md5('starbucks-blocks/newsletter-press-center')]: NewsletterPressCenter,
};

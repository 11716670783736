import { useState } from 'react';
import classNames from 'classnames';
import { TranslatedContentItem } from './TranslatedContentItem';

export const TranslatedContent = (props) => {
	const { locale, writingDirection, className: classNameFromEditor, innerBlocks } = props;

	const className = classNames('sbx-translated-content', classNameFromEditor);

	const [activeTab, setActiveTab] = useState(innerBlocks[0]?.clientId);

	return (
		<div className={className} lang={locale} dir={writingDirection}>
			<div className="sbx-translated-content__header">
				{innerBlocks.map(({ attributes: { label }, clientId }) => {
					return (
						<button
							aria-hidden={activeTab === clientId}
							className="sbx-translated-content__toggle sb-button"
							key={clientId}
							onClick={() => {
								setActiveTab(clientId);
							}}
						>
							{label}
						</button>
					);
				})}
			</div>
			<div className="sbx-translated-content__inner">
				{innerBlocks.map((block) => {
					const {
						attributes: {
							className: classNameFromEditor,
							label,
							locale,
							writingDirection,
						},
						clientId,
						innerBlocks,
					} = block;

					return (
						<TranslatedContentItem
							key={clientId}
							isActive={activeTab === clientId}
							label={label}
							locale={locale}
							writingDirection={writingDirection}
							className={classNameFromEditor}
							innerBlocks={innerBlocks}
						/>
					);
				})}
			</div>
		</div>
	);
};

import React from 'react';
import md5 from 'md5';
import Image from 'next/image';
import classnames from 'classnames';
import { RawHTML } from 'components/Html/Raw/html';

export const StoryGrid = (props) => {
	const {
		className: classNameFromEditor,
		hideDate,
		hideCategory,
		hideImage = false
	} = props;

	const className = classnames('sb-card-grid', 'post-grid', classNameFromEditor);
	
	const itemClass = classnames('sb-card-grid-item', 'post-grid-item', {
		'post-grid-item-sm-50 post-grid-item-md-33': className.includes('3-col'),
		'post-grid-item--gutter-sm post-grid-item-sm-50': className.includes('2-col'),
	});

	/**
	 * Logic to handle the 'sizes' prop based on the block's className. 'sizes' should be different.
	 */
	const getSizes = (className = classNameFromEditor || '' ) => {
		let sizes = '';
		if (className.includes('is-style-1-col')) {
			sizes="(max-width: 1008px) 100vw, 1008px"
		} else if (className.includes('is-style-1-col-page-header')) {
			sizes = '(max-width: 767px) 100vw, (max-width: 1024px) 482px, 680px';
		} else if (className.includes('is-style-2-col-title-over-image')) {
			sizes = "(max-width: 991px) 100vw, 502px";
		} else if (className.includes('is-style-2-col')) {
			sizes = "(max-width: 575px) 100vw, (max-width: 1077px) 50vw, 502px";
		} else if (className.includes('is-style-3-col-title-over-image') || className.includes('is-style-3-col')) {
			sizes = "(max-width: 991px) 100vw, 323px";
		}

		return sizes;
	}

	const posts = typeof props?.posts === 'string' ? JSON.parse(props.posts) : [];

	return (
		<div className={className}>
			{posts.length > 0 &&
				posts.map((post) => {
					const {
						id,
						title,
						image: { sizes = '', srcset, src, alt, height, width } = {},
						date,
						date_prop: dateProp,
						excerpt,
						link,
						primary_category: primaryCategory,
					} = post;

					return (
						<div key={id} className={itemClass}>
							<a
								className="sb-card-grid-item__link"
								aria-label={title}
								itemProp="url"
								href={link}
							>
								{!hideImage && (
									<figure
										className="sb-card-grid-item__image-fit sb-image-fit"
										itemProp="image"
										itemScope=""
										itemType="http://schema.org/ImageObject"
									>
										<div className="sb-card-grid-item__image-contain sb-image-fit__image-contain">
											<Image
												alt={alt}
												className="sb-card-grid-item__image sb-image-fit__image"
												height = {height}
												loading='lazy'
												sizes={getSizes()}
												src = {src}
												width = {width}
											/>
										</div>
									</figure>
								)}
								<div className="sb-card-grid-item__title-contain">
									<div className="sb-card-grid-item__meta-cat sb-card-grid-item__meta-cat--narrow">
										<p>
											{primaryCategory && !hideCategory && (
												<RawHTML
													tagName="span"
													className="sb-card-grid-item__meta-cat-text"
													value={primaryCategory}
												/>
											)}
										</p>
									</div>

									{classNameFromEditor === 'is-style-1-col-page-header' ? (
										<>
											<RawHTML
												tagName="h1"
												className="sb-card-grid-item__title"
												value={title}
											/>
											{!hideDate && (
												<p className="sb-post-grid-item__published">
													<time
														itemProp="datePublished"
														content={dateProp}
													>
														{date}
													</time>
												</p>
											)}
											<RawHTML
												tagName="p"
												className="sb-post-grid-item__excerpt"
												value={excerpt}
											/>
											<p className="sb-post-grid-item__readmore">Read More</p>
										</>
									) : (
										<>
											<RawHTML
												tagName="h2"
												className="sb-card-grid-item__title"
												value={title}
											/>
											{!hideDate && (
												<p className="sb-post-grid-item__published">
													<time
														itemProp="datePublished"
														content={dateProp}
													>
														{date}
													</time>
												</p>
											)}
										</>
									)}
								</div>
							</a>
						</div>
					);
				})}
		</div>
	);
};

export const map = {
	'starbucks-blocks/story-grid': StoryGrid,
	[md5('starbucks-blocks/story-grid')]: StoryGrid,
};

import { useRef, useEffect } from 'react';
import { gsap, Power4 } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';

const useAnimatedText = (type) => {
	const textRef = useRef(null);

	useEffect(() => {
		if (!type) {
			return;
		}

		gsap.registerPlugin(ScrollTrigger);

		const initAnimatedText = async () => {
			const Splitting = (await import('splitting')).default;
			const { words } = Splitting({ target: textRef.current, by: 'words' })[0];

			const animationConfig = {
				flyInByWord: {
					from: {
						y: 20,
						opacity: 0,
					},
					to: {
						y: 0,
						opacity: 1,
						duration: 0.5,
						ease: Power4.easeOut,
						stagger: 0.1,
					},
				},
				// Anymation types can be extended here.
			};

			const animation = animationConfig[type];

			// Create the GSAP timeline
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: textRef.current,
					start: 'top 80%',
				},
			});

			tl.fromTo(words, animation.from, animation.to);
		};

		initAnimatedText();
	}, [type]);

	return textRef;
};

export default useAnimatedText;

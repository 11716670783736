import React from 'react';
import md5 from 'md5';
import SocialNav from 'components/Social/Nav';

export const SocialInsert = (props) => {
	const {
		attributes: { content },
	} = props;

	return (
		<div className="social-block">
			<h3 className="social-block__title">{content}</h3>
			<SocialNav context="block" />
		</div>
	);
};

export const map = {
	'starbucks-blocks/social-insert': SocialInsert,
	[md5('starbucks-blocks/social-insert')]: SocialInsert,
};

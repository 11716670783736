import * as React from 'react';
import { decodeEntities } from 'utils/decode-wrapper';
import SocialShare from 'components/Social/Share';

/**
 * @todo document this function
 * @param {*} props
 */
export const StickySocialShare = ({ title, url, image, twitterTitle }) => {
	return (
		<SocialShare
			postTitle={decodeEntities(title)}
			postUrl={url}
			image={image}
			twitterTitle={twitterTitle}
		/>
	);
};

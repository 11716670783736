import PropTypes from 'prop-types';
import DownloadIcon from 'assets/icons/download.svg';

const DownloadLinkWithIcon = ({ download = true, href, label, target = '_blank' }) => {
	return (
		<a
			className="sbx-download-cta"
			download={download}
			href={href}
			rel={target === '_blank' ? 'noopener noreferrer' : null}
			target={target}
		>
			<DownloadIcon className="sbx-download-cta__icon" aria-hidden="true" />
			{label}
		</a>
	);
};

export default DownloadLinkWithIcon;

DownloadLinkWithIcon.propTypes = {
	label: PropTypes.string.isRequired,
	href: PropTypes.string.isRequired,
};

/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import md5 from 'md5';
import DOMPurify from 'isomorphic-dompurify';
import classnames from 'classnames';
import Image from 'next/image';
import SocialNav from '../Pages/Post/Shared/SocialNav';

export const ArticleHero = ({
	backgroundColor,
	byline,
	innerBlocks,
	post,
	postDate,
	readTime,
	showAuthor = true,
	showDate = true,
	showReadTime = true,
	socialIcons = true,
	subhead,
	title,
	align,
}) => {
	const backgroundColors = [
		{ name: 'light-green', color: '#eaf3f0' },
		{ name: 'dark-green', color: '#1e3832' },
		{ name: 'max-green-yellow', color: '#e0df4e' },
	];

	const className = classnames(
		'sb-article-hero',
		{ 'sb-article-hero--light-green': backgroundColor === backgroundColors[0].color },
		{ 'sb-article-hero--dark-green': backgroundColor === backgroundColors[1].color },
		{ 'sb-article-hero--max-green-yellow': backgroundColor === backgroundColors[2].color },
		{ [`align${align}`]: align === 'wide' || align === 'full' },
	);

	const listRef = React.createRef();

	if (!innerBlocks || innerBlocks.length < 1) {
		return null;
	}

	return (
		<div className={className}>
			<div className="sb-article-hero__inner">
				<div className="sb-article-hero__content">
					<h1 className="sb-article-hero__title">{title}</h1>

					{(showAuthor || showDate || showReadTime) && (
						<div className="sb-article-hero__meta">
							{showAuthor && <p className="sb-article-hero__byline">{byline}</p>}
							{showDate && <p className="sb-article-hero__date">{postDate}</p>}
							{showReadTime && (
								<p className="sb-article-hero__read-time">{readTime}</p>
							)}
						</div>
					)}

					<div className="sb-article-hero__excerpt">
						<p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subhead) }} />
					</div>

					{socialIcons && (
						<div className="sb-article-hero__social">
							<SocialNav post={post} />
						</div>
					)}
				</div>
				<div className="sb-article-hero__images">
					{innerBlocks.map((block, i) => {
						// Make sure the child block exists
						if (block?.clientId?.length < 1) {
							return null;
						}

						const {
							clientId,
							attributes: { media, focalPoint },
						} = block;

						const focalPointStyle = {};

						if (focalPoint) {
							focalPointStyle.objectPosition = `${focalPoint?.x * 100}% ${
								focalPoint?.y * 100
							}%`;
						}

						if (media?.url) {
							return (
								<div
									key={clientId}
									className={`sb-article-hero__image sb-article-hero__image-${
										i + 1
									}`}
								>
									<Image
										src={media.url}
										alt={media?.alt}
										style={focalPointStyle}
										height={media?.height}
										width={media?.width}
									/>
								</div>
							);
						}
					})}
				</div>
			</div>
		</div>
	);
};

export const map = {
	'starbucks-blocks/article-hero': ArticleHero,
	[md5('starbucks-blocks/article-hero')]: ArticleHero,
};

import React from 'react';
import { __ } from '@wordpress/i18n';
import md5 from 'md5';
import { Link } from 'components/Html';
import ChevronRightThin from 'assets/icons/chevron-right-thin.svg';
import PressPhotosImage from './press-photos-image';

/**
 * Newsletter Press Center block
 */
export const PressCenter = (props) => {
	let { headline, mediaItems, rows } = props.attributes;

	const IsJsonString = (str) => {
		if (typeof str !== 'string') {
			return false;
		}
		try {
			const json = JSON.parse(str);
			return json;
		} catch (e) {
			return false;
		}
	};

	if (typeof headline === 'undefined') {
		headline = __('Press Photos', 'starbucks');
	}

	const mediaItemsArray = IsJsonString(mediaItems);

	// remove items based on number of rows
	if (mediaItemsArray.length > rows * 4) {
		mediaItemsArray.splice(rows * 4, mediaItemsArray.length - rows * 4);
	}

	return (
		<div className="press-photos">
			<div className="press-photos__top">
				<h2 className="press-photos__title">{headline}</h2>
				<div className="press-photos__view-all sb-block-link-contain">
					<Link href="/multimedia/" className="sb-block-link sb-block-link--all">
						{__('Multimedia library', 'starbucks')}

						<span className="screen-reader-text">
							{__('Multimedia library', 'starbucks')}
						</span>

						<ChevronRightThin
							className="sb-icon sb-block-link__icon"
							aria-hidden="true"
						/>
					</Link>
				</div>
			</div>
			<div className="press-photos__items">
				{mediaItemsArray.length > 0 &&
					mediaItemsArray.map((mediaItem, index) => {
						if (Object.keys(mediaItem).length > 0) {
							return <PressPhotosImage key={index} media={mediaItem} />;
						}

						return '';
					})}
			</div>
		</div>
	);
};

export const map = {
	'starbucks-blocks/press-photos': PressCenter,
	[md5('starbucks-blocks/press-photos')]: PressCenter,
};

import classnames from 'classnames';
import { useState, useEffect } from 'react';
import { ArticleContent } from 'components/Pages/Shared/ArticleContent';
import { noop } from 'utils/noop';
import { getColorClassName } from 'utils/post';
import { safeALT } from 'utils/safe-alt';
import { client } from 'utils/api';

const DEFAULT_MEDIA_WIDTH = 50;
const API_ENDPOINT = process.env.NEXT_PUBLIC_WP_API_URL;

function imageFillStyles(url, focalPoint) {
	return url
		? {
				backgroundImage: `url(${url})`,
				backgroundPosition: focalPoint
					? `${focalPoint.x * 100}% ${focalPoint.y * 100}%`
					: '50% 50%',
		  }
		: {};
}

export const BlockMediaText = ({
	align,
	innerBlocks,
	backgroundColor,
	customBackgroundColor,
	isStackedOnMobile,
	mediaAlt,
	mediaPosition,
	mediaType,
	mediaUrl,
	mediaWidth,
	mediaId,
	verticalAlignment,
	imageFill,
	focalPoint,
	linkClass,
	href,
	linkTarget,
	post,
	rel,
	className,
	anchor,
}) => {
	const newRel = rel || undefined;
	const [poster, setPoster] = useState(undefined);
	useEffect(() => {
		if (mediaType === 'video') {
			const { origin } = new URL(API_ENDPOINT);

			client(`${origin}media/${mediaId}`).then(({ json }) => {
				if (json.cover_image) {
					setPoster(json.cover_image);
				}
			});
		}
	}, [mediaId, mediaType]);

	let image = (
		<img
			src={mediaUrl}
			alt={safeALT(mediaAlt)}
			className={mediaId && mediaType === 'image' ? `wp-image-${mediaId}` : null}
			loading="lazy"
		/>
	);

	if (href) {
		image = (
			<a className={linkClass} href={href} target={linkTarget} rel={newRel}>
				{image}
			</a>
		);
	}

	const mediaTypeRenders = {
		image: () => image,
		video: () => <video controls src={mediaUrl} poster={poster} playsInline />,
	};

	const backgroundClass = getColorClassName('background-color', backgroundColor);

	const extraClassNames = classnames(className, {
		[`align${align}`]: align,
		'has-media-on-the-right': mediaPosition === 'right',
		'has-background': backgroundClass || customBackgroundColor,
		[backgroundClass]: backgroundClass,
		'is-stacked-on-mobile': isStackedOnMobile,
		[`is-vertically-aligned-${verticalAlignment}`]: verticalAlignment,
		'is-image-fill': imageFill,
	});

	const backgroundStyles = imageFill ? imageFillStyles(mediaUrl, focalPoint) : {};

	let gridTemplateColumns;

	if (mediaWidth !== DEFAULT_MEDIA_WIDTH) {
		gridTemplateColumns =
			mediaPosition === 'right' ? `auto ${mediaWidth}%` : `${mediaWidth}% auto`;
	}

	const style = {
		backgroundColor: backgroundClass ? undefined : customBackgroundColor,
		gridTemplateColumns,
	};

	return (
		<div className={`wp-block-media-text ${extraClassNames}`} style={style} id={anchor}>
			<figure className="wp-block-media-text__media" style={backgroundStyles}>
				{(mediaTypeRenders[mediaType] || noop)()}
			</figure>
			<div className="wp-block-media-text__content">
				<ArticleContent blocks={innerBlocks} post={post} className="" />
			</div>
		</div>
	);
};

/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import md5 from 'md5';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
import cn from 'classnames';
import { ScrollingContentSection } from './ScrollingContentSection';

export const ScrollingContentWrapper = (props) => {
	const { innerBlocks, align, className: classNameFromEditor } = props;

	const sectionsRef = React.createRef();

	const backgrounds = innerBlocks.map((block) => {
		return {
			color: block.attributes.backgroundColor,
			image: block.attributes.backgroundImage?.url,
		};
	});

	gsap.registerPlugin(ScrollTrigger);

	useEffect(() => {
		const sections = [
			...sectionsRef.current.querySelectorAll('.sbx-scrollable-sections__item'),
		];
		const backgrounds = [
			...sectionsRef.current.querySelectorAll('.sbx-scrollable-sections__background'),
		];
		const headerHeight = document.querySelector('.sb-site-header').offsetHeight;

		for (let i = 0; i < sections.length; i++) {
			const section = sections[i];
			const background = backgrounds[i];

			gsap.to(background, {
				scrollTrigger: {
					trigger: section,
					start: `top-=${headerHeight} 50%`,
					end: `bottom top+=${headerHeight}`,
					toggleActions: 'play none none reverse',
				},
				opacity: 1,
				eaque: 'none',
			});
		}
	}, [sectionsRef]);

	const classes = cn('sbx-scrollable-sections', classNameFromEditor, {
		alignfull: align === 'full',
	});

	return (
		<div className={classes} ref={sectionsRef}>
			<div className="sbx-scrollable-sections__backgrounds">
				{backgrounds.map(({ image, color }, index) => (
					<div
						key={index}
						className="sbx-scrollable-sections__background"
						style={{
							backgroundImage: `url(${image})`,
							backgroundColor: color,
							opacity: index === 0 ? 1 : 0,
						}}
					/>
				))}
			</div>
			<div className="sbx-scrollable-sections__items">
				{innerBlocks.map((block, index) => {
					return (
						<ScrollingContentSection
							innerBlocks={block.innerBlocks}
							key={index}
							{...block.attributes}
						/>
					);
				})}
			</div>
		</div>
	);
};

export const map = {
	'starbucks-blocks/scrolling-content-wrapper': ScrollingContentWrapper,
	[md5('starbucks-blocks/scrolling-content-wrapper')]: ScrollingContentWrapper,
};

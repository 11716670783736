import { Link } from 'components/Html';
import classnames from 'classnames';
import { getColorClassName } from 'utils/post';
import { RawHTML } from 'components/Html/Raw/html';

export const PostTitle = (props) => {
	const {
		className: classNameFromEditor,
		fontFamily,
		fontSize,
		isLink,
		level,
		linkTarget,
		post,
		rel,
		textAlign,
		textColor,
	} = props;

	const Tag = `h${level}`;

	const textClass = getColorClassName('color', textColor);

	const className = classnames('wp-block-post-title', classNameFromEditor, {
		'has-text-color': textColor,
		[`has-text-align-${textAlign}`]: textAlign,
		[`has-font-size-${fontSize}`]: fontSize,
		[`has-${fontFamily}-font-family`]: fontFamily,
		[textClass]: textClass,
	});

	const url = new URL(post.link);

	return (
		<Tag className={className}>
			{isLink ? (
				<Link href={url.pathname} target={linkTarget} rel={rel}>
					<RawHTML tagName="span" value={post.title} />
				</Link>
			) : (
				<RawHTML tagName="span" value={post.title} />
			)}
		</Tag>
	);
}

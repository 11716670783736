import { useEffect, useRef } from 'react';
import { initCarousels, initLightboxCarousels } from 'utils/carousels';

/**
 * Initialize frontend packages to run.
 * i.e. Carousels, Lightbox, Autoplay, etc..
 *
 * @param {object} post Post object from API response
 */
export default function usePackages(post) {
	const ref = useRef(null);

	// Carousels
	useEffect(() => {
		const init = async () => {
			await Promise.all([
				import('flickity'),
				import('flickity-fullscreen'),
				import('flickity-imagesloaded'),
			]).then((imports) => {
				const node = ref.current;
				if (node) {
					const [flickity] = imports;
					initCarousels(node, flickity.default);
					initLightboxCarousels(node, flickity.default);
				}
			});
		};

		init();
	}, [ref, post.id]);

	return ref;
}

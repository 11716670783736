import { RawHTML } from 'components/Html/Raw/html';
import classnames from 'classnames';

export const BlockPullquote = ({
	value,
	citation,
	anchor,
	className: classNameFromEditor,
	align,
}) => {
	const className = classnames('wp-block-pullquote', classNameFromEditor, {
		[`align${align}`]: align,
	});

	return (
		<figure className={className} id={anchor}>
			<blockquote className="wp-block-pullquote__blockquote">
				<RawHTML value={value} tagName="p" className="wp-block-pullquote__quote" />
				{citation && (
					<p>
						<RawHTML
							className="wp-block-pullquote__cite"
							tagName="cite"
							value={citation}
						/>
					</p>
				)}
			</blockquote>
		</figure>
	);
};

/* eslint-disable no-unused-vars */
import React from 'react';
import md5 from 'md5';
import { ArticleContent } from 'components/Pages/Shared/ArticleContent';

export const ScrollingContentSection = (props) => {
	const { innerBlocks, backgroundImage = '', backgroundColor = '' } = props;

	return (
		<div className="sbx-scrollable-sections__item">
			<div className="sbx-scrollable-sections__item-inner">
				<ArticleContent blocks={innerBlocks} post={{ content: {} }} />
			</div>
		</div>
	);
};

export const map = {
	'starbucks-blocks/scrolling-content-section': ScrollingContentSection,
	[md5('starbucks-blocks/scrolling-content-section')]: ScrollingContentSection,
};

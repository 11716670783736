/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Image from 'next/image';

const ImageElement = (props) => {
	const {
		source_url = '',
		id = '',
		className = '',
		alt_text = '',
		media_details: {
			width = 0,
			height = 0,
		}
	} = props;

	console.log(props)

	return (
		<Image
			src={source_url}
			alt={alt_text}
			className={`image-${id} ${className}`}
			width={width}
			height={height}
		/>
	);
};

const ThreeImageElement = (props) => {
	const { leftImage, rightImage, centerImage } = props;

	return (
		<div className="three-image-layout">
			<ImageElement {...leftImage} className="left-image" />
			<ImageElement {...centerImage} className="center-image" />
			<ImageElement {...rightImage} className="right-image" />
		</div>
	);
};

export default ThreeImageElement;

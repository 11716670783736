import * as React from 'react';
import { __ } from '@wordpress/i18n';
import { forceDownload } from 'lib/wp/utils/force-download';
import Pdf from 'assets/icons/pdf.svg';

/**
 * @todo document this function
 * @param {*} props
 */
export default function AssetItems(props) {
	const { imageThumbnail, imageLowRes, imageHighRes, imageAltText, type, url, filename } = props;

	return (
		<div className="asset-download__item">
			{type === 'application' ? (
				<Pdf
					className="asset-download__item-image"
					aria-hidden="true"
					height={150}
					width={150}
				/>
			) : (
				<img
					src={imageThumbnail}
					alt={imageAltText}
					className="asset-download__item-image"
				/>
			)}
			{
				// maybe pdf, assumed image if not.
				type === 'application' ? (
					<a
						href={url}
						target="_blank"
						rel="noreferrer"
						className="asset-download__item-link asset-download__item-link--high asset-download__item-link--low"
					>
						{filename}
					</a>
				) : (
					<div className="asset-download__item-links">
						<a
							onClick={(e) => forceDownload(e, imageLowRes)}
							href={imageLowRes}
							className="asset-download__item-link asset-download__item-link--low"
						>
							{__('Low', 'starbucks')}
						</a>

						<span className="asset-download__item-divider">|</span>

						<a
							onClick={(e) => forceDownload(e, imageHighRes)}
							href={imageHighRes}
							className="asset-download__item-link asset-download__item-link--high"
						>
							{__('High', 'starbucks')}
						</a>
					</div>
				)
			}
		</div>
	);
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DownloadIcon from 'assets/icons/download.svg';

const DownloadButtonWithIcon = React.forwardRef(
	({ onClick, onKeyDown, label, className, ...props }, ref) => {
		return (
			<button
				className={classNames('sbx-download-cta', className)}
				onClick={onClick}
				onKeyDown={onKeyDown}
				ref={ref}
				{...props}
			>
				<DownloadIcon className="sbx-download-cta__icon" aria-hidden="true" />
				{label}
			</button>
		);
	},
);

export default DownloadButtonWithIcon;

DownloadButtonWithIcon.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	onKeyDown: PropTypes.func,
	className: PropTypes.string,
};

import * as React from 'react';
import PropTypes from 'prop-types';
import SocialShareList from 'components/Social/Share/List';
import { __ } from '@wordpress/i18n';

export default function SocialNav({ post, label, ...rest }) {
	const featuredImage = post.featured_media?.id ? post.featured_media : {};
	const twitterTitleContent = post?.seo_twitter?.twitter_title?.content
		? post.seo_twitter.twitter_title?.content
		: '';

	return (
		<div {...rest} className="article-header__social">
			<span className="article-header__social-label">{__('Share', 'starbucks')}</span>

			<SocialShareList
				postTitle={post?.title}
				postUrl={post.link}
				image={featuredImage}
				twitterTitle={twitterTitleContent}
				label={label}
			/>
		</div>
	);
}

SocialNav.propTypes = {
	post: PropTypes.object.isRequired,
	label: PropTypes.string,
};

/* eslint-disable no-unused-vars */
import React from 'react';
import md5 from 'md5';
import { RawHTML } from 'components/Html/Raw/html';
import Image from 'next/image';

export const Banner = (props) => {
	const {
		heading,
		text,
		ctaEnabled,
		ctaButtonText,
		ctaURL,
		bgMediaURL,
		default_media: defaultMediaURL,
	} = props;

	return (
		<div className="banner">
			<div className="banner__main">
				<RawHTML tagName="h1" className="banner__heading" value={heading} />
				{text && <RawHTML tagName="div" className="banner__content" value={text} />}

				{ctaEnabled && (
					<div className="banner__cta">
						<a href={ctaURL}>{ctaButtonText}</a>
					</div>
				)}
			</div>

			<div className="banner__image">
				{bgMediaURL ? (
					<Image src={bgMediaURL} layout="fill" alt=""/>
				) : (
					<Image src={defaultMediaURL} layout="fill" alt=""/>
				)}
			</div>
		</div>
	);
};

export const map = {
	'starbucks-blocks/banner': Banner,
	[md5('starbucks-blocks/banner')]: Banner,
};

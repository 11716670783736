import * as React from 'react';
import { sprintf, __ } from '@wordpress/i18n';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import Facebook from 'assets/icons/facebook.svg';
import Twitter from 'assets/icons/twitter.svg';
import Linkedin from 'assets/icons/linkedin.svg';
import Mail from 'assets/icons/mail.svg';
import { useRouter } from 'next/router';

/**
 * @todo document this function
 * @param {*} props
 */
export default function SocialShareList(props) {
	const router = useRouter();
	const { locale } = router;

	const isJapanSite = locale === 'ja';

	const twitterAccount = process.env.NEXT_PUBLIC_TWITTER_ACCOUNT
		? process.env.NEXT_PUBLIC_TWITTER_ACCOUNT
		: 'StarbucksNews';

	return (
		<ul className="sb-social-share__list">
			<li className="sb-social-share__item">
				<FacebookShareButton
					className="sb-social-share__link  sb-social-share__link--facebook"
					resetButtonStyle={false}
					url={props.postUrl}
					data-social-category="Share"
					data-social-action="Facebook"
					data-social-label={props.label}
				>
					<Facebook className="sb-icon sb-social-share__icon" />
					<span className="screen-reader-text">
						{__('Share on Facebook', 'starbucks')}
					</span>
				</FacebookShareButton>
			</li>

			<li className="sb-social-share__item">
				<TwitterShareButton
					className="sb-social-share__link  sb-social-share__link--twitter"
					url={props.postUrl}
					resetButtonStyle={false}
					via={twitterAccount}
					title={props.twitterTitle}
					data-social-category="Share"
					data-social-action="Twitter"
					data-social-label={props.label}
				>
					<Twitter className="sb-icon sb-social-share__icon" />
					<span className="screen-reader-text">
						{__('Share on Twitter', 'starbucks')}
					</span>
				</TwitterShareButton>
			</li>

			{!isJapanSite && !process.env.NEXT_PUBLIC_HIDE_LINKEDIN && (
				<li className="sb-social-share__item">
					<LinkedinShareButton
						className="sb-social-share__link  sb-social-share__link--linkedin"
						url={props.postUrl}
						resetButtonStyle={false}
						data-social-category="Share"
						data-social-action="LinkedIn"
						data-social-label={props.label}
					>
						<Linkedin className="sb-icon sb-social-share__icon" />
						<span className="screen-reader-text">
							{__('Share on LinkedIn', 'starbucks')}
						</span>
					</LinkedinShareButton>
				</li>
			)}

			{!isJapanSite && !process.env.NEXT_PUBLIC_HIDE_MAIL && (
				<li className="sb-social-share__item">
					<a
						href={`mailto:?subject=${encodeURIComponent(
							props.postTitle,
						)}&body=${encodeURIComponent(
							sprintf(
								__('Read this from Starbucks Stories: %s %s', 'starbucks'),
								props.postTitle,
								props.postUrl,
							),
						)}`}
						className="sb-social-share__link  sb-social-share__link--mail"
						target="_blank"
						rel="noopener noreferrer"
						data-social-category="Share"
						data-social-action="Email"
						data-social-label={props.label}
					>
						<Mail className="sb-icon sb-social-share__icon" />
						<span className="screen-reader-text">
							{__('Share in email', 'starbucks')}
						</span>
					</a>
				</li>
			)}
		</ul>
	);
}

import { useState } from 'react';
import { __ } from '@wordpress/i18n';
import md5 from 'md5';
import classNames from 'classnames';
import { ArticleContent } from 'components/Pages/Shared/ArticleContent';

export const ExpandableContent = ({
	expandLabel = __('Expand', 'starbucks'),
	className: classNameFromEditor,
	innerBlocks,
}) => {
	const [expanded, setExpanded] = useState(false);

	const className = classNames('sbx-expandable-content', classNameFromEditor);

	return (
		<div className={className}>
			<div
				className="sbx-expandable-content__header sbx-expandable-content__header--center"
				aria-hidden={expanded}
			>
				<button
					className="sbx-expandable-content__toggle sb-button"
					onClick={() => setExpanded(!expanded)}
				>
					{expandLabel}
				</button>
			</div>
			<div className="sbx-expandable-content__inner" aria-hidden={!expanded}>
				<ArticleContent
					className="article__content"
					blocks={innerBlocks}
					post={{ content: {} }}
					aria-hidden={!expanded}
				/>
			</div>
		</div>
	);
};

export const map = {
	'starbucks-blocks/expandable-content': ExpandableContent,
	[md5('starbucks-blocks/expandable-content')]: ExpandableContent,
};

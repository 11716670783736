import React, { useCallback, useEffect } from 'react';
import { gsap } from 'gsap';
import cn from 'classnames';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
import SplitText from 'components/SplitText';

import BgIllustration from 'assets/icons/em-landscape.svg';
import LeftIllustration from 'assets/icons/em-floral-3.svg';
import RightIllustration from 'assets/icons/em-floral-4.svg';
import { titleAnimation, paragraphAnimation, fadeInAnimation } from 'utils/animations';
import md5 from 'md5';
import { ArticleContent } from 'components/Pages/Shared/ArticleContent';

export const ParallaxBackgroundContainer = (props) => {
	const { innerBlocks, className } = props;

	gsap.registerPlugin(ScrollTrigger);
	const animationRef = React.createRef();

	const initComponentCallback = useCallback(() => {
		const initComponent = () => {
			const animationTimeline = gsap.timeline({
				scrollTrigger: {
					id: `#earthMonthFooter`,
					trigger: animationRef.current,
					scrub: false,
					pin: false,
					start: 'top bottom-=100px',
					end: 'top top+=90px',
				},
			});

			const aniHills = animationRef.current.querySelectorAll('.em-landscape_svg__hill');
			const aniFlowers = animationRef.current.querySelectorAll('.em-landscape_svg__floral');
			const aniDots = animationRef.current.querySelectorAll('.em-landscape_svg__dots path');

			gsap.set(aniHills, { transformOrigin: 'bottom center' });

			animationTimeline.fromTo(
				aniHills,
				{
					scale: 0,
					y: 60,
					autoAlpha: 0,
				},
				{
					scale: 1,
					y: 0,
					autoAlpha: 1,
					ease: 'elastic.out(1, 1)',
					duration: 1.5,
					stagger: 0.1,
				},
			);

			gsap.set(aniFlowers, { transformOrigin: 'bottom center' });

			animationTimeline.fromTo(
				aniFlowers,
				{
					scale: 0,
					autoAlpha: 0,
				},
				{
					scale: 1,
					autoAlpha: 1,
					ease: 'elastic.out(1, 1)',
					duration: 1.5,
					stagger: 0.1,
				},
			);

			animationTimeline.fromTo(
				aniDots,
				{
					scale: 0,
					autoAlpha: 0,
				},
				{
					scale: 1,
					autoAlpha: 1,
					ease: 'circ.out',
					duration: 1.5,
					stagger: 0.01,
				},
			);

			const aniTextEls = animationRef.current.querySelectorAll(
				'.earth-month-footer__content h2 .split-text__inner',
			);

			animationTimeline.fromTo(aniTextEls, titleAnimation.from, titleAnimation.to, 'textIn');

			const aniParagraphTextEls = animationRef.current.querySelectorAll(
				'.earth-month-footer__content p .split-text__inner',
			);

			animationTimeline.fromTo(
				aniParagraphTextEls,
				paragraphAnimation.from,
				paragraphAnimation.to,
				'textIn',
			);

			const aniFadeIn = animationRef.current.querySelectorAll('.js-animation-fadein');

			animationTimeline.fromTo(aniFadeIn, fadeInAnimation.from, fadeInAnimation.to);

			const aniFlowersLeft = animationRef.current.querySelectorAll(
				'.em-floral-3_svg__floral',
			);

			gsap.set(aniFlowersLeft, { transformOrigin: 'bottom left' });
			animationTimeline.fromTo(
				aniFlowersLeft,
				{
					scale: 0,
					autoAlpha: 0,
				},
				{
					scale: 1,
					autoAlpha: 1,
					ease: 'elastic.out(1, 0.5)',
					duration: 1.5,
					stagger: 0.1,
				},
			);

			const aniDotsLeft = animationRef.current.querySelectorAll(
				'.em-floral-3_svg__dots path',
			);
			animationTimeline.fromTo(
				aniDotsLeft,
				{
					scale: 0,
					autoAlpha: 0,
				},
				{
					scale: 1,
					autoAlpha: 1,
					ease: 'circ.out',
					duration: 1.5,
					stagger: 0.01,
				},
			);

			const aniFlowersRight = animationRef.current.querySelectorAll(
				'.em-floral-4_svg__floral',
			);
			gsap.set(aniFlowersRight, { transformOrigin: 'bottom right' });
			animationTimeline.fromTo(
				aniFlowersRight,
				{
					scale: 0,
					autoAlpha: 0,
				},
				{
					scale: 1,
					autoAlpha: 1,
					ease: 'elastic.out(1, 0.5)',
					duration: 1.5,
					stagger: 0.1,
				},
				'-=1',
			);

			const aniDotsRight = animationRef.current.querySelectorAll(
				'.em-floral-4_svg__dots path',
			);
			animationTimeline.fromTo(
				aniDotsRight,
				{
					scale: 0,
					autoAlpha: 0,
				},
				{
					scale: 1,
					autoAlpha: 1,
					ease: 'circ.out',
					duration: 1.5,
					stagger: 0.01,
				},
			);

			animationTimeline.addLabel('earthMonthFooter');

			return animationTimeline;
		};
		initComponent();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const unloadComponent = (timeline) => {
		if (typeof timeline?.kill === 'function') {
			timeline.kill();
		}
	};

	useEffect(() => {
		const timeline = initComponentCallback();

		return () => {
			unloadComponent(timeline);
		};
	}, [initComponentCallback]);

	const classNames = cn('earth-month-footer', className);

	return (
		<div id="earthMonthFooter" ref={animationRef} className={classNames}>
			<div className="earth-month-footer__background">
				<BgIllustration />

				<div className="earth-month-footer__flowers-left">
					<LeftIllustration />
				</div>
				<div className="earth-month-footer__flowers-right">
					<RightIllustration />
				</div>
			</div>

			<div className="earth-month-footer__content">
				<div className="earth-month-footer__wrap sb-content-wrap">
					<ArticleContent post={{ content: {} }} blocks={innerBlocks} />
				</div>
			</div>
		</div>
	);
};

export const map = {
	'starbucks-blocks/parallax-background-container': ParallaxBackgroundContainer,
	[md5('starbucks-blocks/parallax-background-container')]: ParallaxBackgroundContainer,
};

/* eslint-disable no-unused-vars */
import React from 'react';
import CardWithOverlay from 'components/Content/CardWithOverlay';

export const StorytellingFeaturedContent = ({ posts }) => {
	return (
		<div className="sbx-cs-featured-content">
			{posts.map((post, index) => {
				const { title, link: url, thumbnail: img } = post;

				return (
					<CardWithOverlay
						img={img}
						title={title}
						url={url}
						ctaLabel="Read More"
						ctaAccessibleLabel={` about ${title}`}
						key={index}
					/>
				);
			})}
		</div>
	);
};

import React from 'react';
import classnames from 'classnames';

/**
 * Text Input Control.
 * 
 * @param {Object} props
 */
const TextInput = (props) => {
  const {
    id,
    label,
    name,
    onChange,
    value,
    description,
    type = 'text',
    hideLabel = false,
  } = props;

  const lableClassName = classnames('sb-input-control__label', {
    'visually-hidden': hideLabel,
  });

  return (
    <div className="sb-input-control" key={id}>
      <label htmlFor={id} className={lableClassName}>
        {label}
      </label>
      <input
        id={id}
        type={type}
        className="sb-input-control__input"
        value={value}
        name={name}
        onChange={(e) => onChange(e)}
      />
      {description && <span className="sb-input-control__description">
        {description}
      </span>}
    </div>
  )
}

export default TextInput;
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, createRef } from 'react';
import md5 from 'md5';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
import Image from 'next/image';
import classnames from 'classnames';
import { HorizontalScrollItem } from './HorizontalScrollItem';

export const HorizontalScrollWrapper = (props) => {
	const {
		aspectRatioHeight,
		aspectRatioWidth,
		backgroundColorSlug,
		className: classNameFromEditor,
		image,
		innerBlocks,
		showImage,
		subtitle,
		title,
	} = props;

	const scrollerRef = createRef();
	const scrollerWrapRef = createRef();
	const scrollerWrapItemsRef = createRef();

	useEffect(() => {
		const scroller = scrollerRef.current;
		const scrollerWrap = scrollerWrapRef.current;
		const scrollerWrapItems = scrollerWrapItemsRef.current;

		gsap.registerPlugin(ScrollTrigger);

		const scrollAmount = 0;
		const gap = 80;
		const headerHeight = document.querySelector('.sb-site-header').offsetHeight;

		ScrollTrigger.matchMedia({
			'(min-width: 768px)': () => {
				const animationTimeline = gsap.timeline({
					scrollTrigger: {
						trigger: scrollerWrap,
						invalidateOnRefresh: true,
						pin: true,
						scrub: 2,
						start: `top-=${headerHeight + 20} top`,
						end: `bottom top+=${headerHeight}`,
					},
				});

				animationTimeline.to(scrollerWrapItems, {
					x: () => {
						const items = [
							...scrollerWrapItems.querySelectorAll('.sb-xscroller__item'),
						];
						const scrollAmount =
							items.reduce((acc, item) => {
								return acc + item.offsetWidth + gap;
							}, 0) -
							scrollerWrapItems.offsetWidth -
							gap;
						return scrollAmount * -1;
					},
				});
			},
		});

		const resizeObserver = new ResizeObserver(() => {
			ScrollTrigger.refresh();
		});

		resizeObserver.observe(document.body);

		// Clean up the observer on component unmount
		return () => {
			resizeObserver.disconnect();
		};
	}, [scrollerRef, scrollerWrapItemsRef, scrollerWrapRef]);

	const className = classnames({
		'sb-xscroller': true,
		'sb-xscroller--has-background-color': backgroundColorSlug,
		[`sb-xscroller--${backgroundColorSlug}`]: backgroundColorSlug,
		[`has-aspect-ratio`]: aspectRatioWidth && aspectRatioHeight,
		[classNameFromEditor]: classNameFromEditor,
	});

	let style = {};

	if (aspectRatioWidth && aspectRatioHeight) {
		style = {
			'--image-aspect-ratio': `${aspectRatioWidth}/${aspectRatioHeight}`,
		};
	}

	return (
		<div ref={scrollerRef} className={className} style={style}>
			<div className="sb-xscroller__header">
				{showImage && (
					<Image
							alt={image.alt}
							className="sb-xscroller__image"
							height={image.height}
							src={image.url}
							width={image.width}
							sizes="250px"
					/>
				)}
				<h2 className="sb-xscroller__title">{title}</h2>
				<p className="sb-xscroller__subtitle">{subtitle}</p>
			</div>
			<hr className="sb-xscroller__divider" />
			<div ref={scrollerWrapRef} className="sb-xscroller__wrap">
				<div ref={scrollerWrapItemsRef} className="sb-xscroller__items">
					{innerBlocks.map(({ innerBlocks, clientId: id }) => {
						return <HorizontalScrollItem key={id} innerBlocks={innerBlocks} />;
					})}
				</div>
			</div>
		</div>
	);
};

export const map = {
	'starbucks-blocks/horizontal-scroll-wrapper': HorizontalScrollWrapper,
	[md5('starbucks-blocks/horizontal-scroll-wrapper')]: HorizontalScrollWrapper,
};

import classnames from 'classnames';
import { getColorClassName } from 'utils/post';

export const BlockSeparator = (props) => {
	const { color, customColor, className, opacity } = props;
	const backgroundClass = getColorClassName('background-color', color);

	const colorClass = getColorClassName('color', color);

	const separatorClasses = classnames(className, 'wp-block-separator', {
		'has-css-opacity': opacity,
		'has-text-color has-background': color || customColor,
		[backgroundClass]: backgroundClass,
		[colorClass]: colorClass,
	});

	const separatorStyle = {
		backgroundColor: backgroundClass ? undefined : customColor,
		color: colorClass ? undefined : customColor,
	};

	return <hr className={separatorClasses} style={separatorStyle} />;
};

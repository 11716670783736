import * as React from 'react';
import Share from 'assets/icons/share.svg';

import { __ } from '@wordpress/i18n';
import SocialShareList from './List';

/**
 * @todo document this component
 */
class SocialShare extends React.Component {
	state = {
		socialOpen: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			socialOpen: false,
		};
	}

	triggerClick = (e) => {
		e.preventDefault();

		this.setState({
			socialOpen: !this.state.socialOpen,
		});
	};

	render() {
		const { style } = this.props;

		return (
			<div
				id="sb-social-share"
				className={`sb-social-share${
					this.state.socialOpen ? ' sb-social-share--open' : ''
				}`}
				aria-labelledby="sb-social-share-button"
				role="region"
				style={style || {}}
			>
				<div className="sb-social-share__wrapper">
					<button
						id="sb-social-share-button"
						className="sb-social-share__button"
						aria-expanded="true"
						onClick={(e) => {
							this.triggerClick(e);
						}}
					>
						<span className="screen-reader-text">
							{__('Share This Article', 'starbucks')}
						</span>
						<Share className="sb-icon sb-social-share__icon" />
					</button>

					<SocialShareList
						postTitle={this.props.postTitle}
						postUrl={this.props.postUrl}
						image={this.props.image}
						twitterTitle={this.props.twitterTitle}
						label="Sidebar"
					/>
				</div>
			</div>
		);
	}
}

export default SocialShare;

export const titleAnimation = {
	from: {
		y: 100,
	},
	to: {
		y: 0,
		ease: 'circ.out',
		duration: 0.7,
		stagger: 0.05,
	},
};

export const paragraphAnimation = {
	from: {
		autoAlpha: 0,
	},
	to: {
		autoAlpha: 1,
		duration: 1.5,
		stagger: 0.01,
	},
};

export const imageWrapAnimation = {
	from: {
		autoAlpha: 0,
		scale: 1.1,
		y: 150,
	},
	to: {
		autoAlpha: 1,
		scale: 1,
		y: 0,
		ease: 'circ.out',
		duration: 1,
		stagger: 0.1,
	},
};

export const imageAnimation = {
	from: {
		scale: 1.2,
		autoAlpha: 0,
	},
	to: {
		scale: 1,
		autoAlpha: 1,
		ease: 'circ.out',
		duration: 1,
		stagger: 0.1,
	},
};

export const bounceAnimation = {
	from: {
		scale: 0,
		rotation: 30,
		autoAlpha: 0,
	},
	to: {
		scale: 1,
		rotation: 0,
		autoAlpha: 1,
		ease: 'elastic.out(1, 0.5)',
		duration: 1.5,
		stagger: 0.1,
	},
};

export const fadeInAnimation = {
	from: {
		y: 20,
		autoAlpha: 0,
	},
	to: {
		y: 0,
		autoAlpha: 1,
		ease: 'circ.out',
		duration: 0.5,
		stagger: 0.1,
	},
};

export const slideInLeftAnimation = {
	from: {
		autoAlpha: 0,
		x: 100,
	},
	to: {
		autoAlpha: 1,
		x: 0,
		ease: 'circ.out',
		duration: 0.75,
		stagger: 0.3,
	},
};

export const flyInTopLeftAnimation = {
	from: {
		autoAlpha: 0,
		x: 20,
		y: -20,
	},
	to: {
		autoAlpha: 1,
		x: 0,
		y: 0,
		ease: 'circ.out',
		duration: 1,
		stagger: 0.5,
	},
};

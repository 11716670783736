import Image from 'next/image';
import { __ } from '@wordpress/i18n';
import { RawHTML } from 'components/Html/Raw/html';
import { Link } from 'components/Html';

export const ProductRecommend = (props) => {
	const {
		title,
		description,
		url,
		mediaURL, // LEGACY, DELETE AFTER MIGRATION
		media
	} = props;

	return (
		<div className="product-recommend">
			{/* LEGACY, DELETE AFTER MIGRATION */}
			{mediaURL && (
				<div className="product-recommend__image">
					<Image
						alt={title} // TODO: add alt text to the block.
						fill="true"
						loading='lazy'
						sizes='(max-width: 991px) 100vw, 320px'
						src={mediaURL}
						style={{ objectFit: "cover" }}
						priority={false}
					/>
				</div>
			)}
			{/* NEW, KEEP AFTER MIGRATION */}
			{media && (
				<div className="product-recommend__image">
					<Image
						alt={media?.alt}
						fill="true"
						loading='lazy'
						sizes="(max-width: 991px) 100vw, 320px"
						src={media.url}
						style={{objectFit: "cover"}}
						priority={false}
					/>
				</div>
			)}

			<div className="product-recommend__content">
				{title && (
					<h3 className="product-recommend__title">
						{url ? (
							<Link href={url}>
								<span className="visually-hidden">
									{__('Product Recommendation: ', 'starbucks')}
								</span>
								{title}
							</Link>
						) : (
							<>
								<span className="visually-hidden">
									{__('Product Recommendation: ', 'starbucks')}
								</span>
								{title}
							</>
						)}
					</h3>
				)}
				<RawHTML
					tagName="div"
					value={description}
					className="product-recommend__description"
				/>
			</div>
		</div>
	);
};

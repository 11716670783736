import { render } from 'react-dom';  // eslint-disable-line react/no-deprecated
import React, { useEffect } from 'react';
import { PullquoteShare } from 'components/Social/Share/Pullquote/Share';
import { HighlightShare } from 'components/Social/Share/Pullquote/Highlight';

export function ShareableContent({ children, url }) {
	useEffect(() => {
		[...document.querySelectorAll('.shareable-quote')].forEach((element) => {
			const p = element.querySelector('p');
			if (!p) {
				return;
			}

			const quote = p.textContent;

			const cite = element.querySelector('cite');
			let citeText = '';
			if (cite) {
				citeText = cite.textContent;
			}

			const classes = [...element.classList].slice(1).join(' ');

			render(
				<PullquoteShare quote={quote} url={url} cite={citeText} classes={classes} />,
				element.parentNode,
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		[...document.querySelectorAll('.shareable-highlight')].forEach((element) => {
			render(<HighlightShare quote={element.textContent} url={url} />, element);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return children;
}

import PropTypes from 'prop-types';
import { RawHTML } from 'components/Html/Raw/html';
import classnames from 'classnames';
import Image from 'next/image';

export const PullQuoteImage = (props) => {
	const { media = {}, mediaPosition, quote } = props;

	const className = classnames('pull-quote-image', `media-position-${mediaPosition || 'right'}`);

	return (
		<div className={className}>
			<div className="pull-quote-image__media">
				<Image
					src={media.url}
					alt={media.alt}
					width={media.width}
					height={media.height}
					sizes="(max-width: 991px) 100%, (min-width: 992px) 645px"
				/>
			</div>
			<RawHTML tagName="div" value={quote} className="pull-quote-image__content" />
		</div>
	);
};

PullQuoteImage.propTypes = {
	media: PropTypes.shape({
		url: PropTypes.string,
		alt: PropTypes.string,
		width: PropTypes.number,
		height: PropTypes.number,
	}),
	mediaURL: PropTypes.string,
	quote: PropTypes.string.isRequired,
	className: PropTypes.string,
};

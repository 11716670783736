import md5 from 'md5';
import React, { Component } from 'react';
import { withRouter } from 'next/router';
import CardGrid from 'components/Posts/CardGrid';
import Pagination from 'components/Pagination';

export class StoryGridDynamic extends Component {
	render() {
		const {
			posts,
			images,
			pagination,
			attributes: { className },
		} = this.props;

		// Need to test for 3-col as 2-col is default so className won't always be defined.
		const isThreeCol = className && className.split(' ').includes('is-style-3-col');

		return (
			<>
				<CardGrid stories={posts} images={images} context={isThreeCol ? '' : 'originals'} />

				{pagination?.pages && <Pagination {...pagination} />}
			</>
		);
	}
}

export const map = {
	'starbucks-blocks/story-grid-dynamic': withRouter(StoryGridDynamic),
	[md5('starbucks-blocks/story-grid-dynamic')]: withRouter(StoryGridDynamic),
};

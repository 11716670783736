import { ArticleContent } from 'components/Pages/Shared/ArticleContent';
import { getColorClassName } from 'utils/post';
import { RawHTML } from 'components/Html/Raw/html';
import classNames from 'classnames';

export const BlockList = ({
	anchor,
	className: classNameFromEditor,
	customTextColor,
	innerBlocks,
	ordered,
	reversed,
	start,
	textColor,
	values,
}) => {
	const TagName = ordered ? 'ol' : 'ul';
	const textClass = getColorClassName('color', textColor);

	// 6.1 and greater
	if (Array.isArray(innerBlocks) && innerBlocks.length > 0) {
		const listProps = {
			className: classNames('wp-block-list', classNameFromEditor, {
				'is-ordered': ordered,
				'has-text-color': textColor || customTextColor,
				[textClass]: textClass,
			}),
			reversed,
			id: anchor,
			start,
		};

		return (
			<TagName {...listProps}>
				{innerBlocks.map(({ innerBlocks: elInnerBlocks, clientId, attributes }) => {
					// Nested list
					if (Array.isArray(elInnerBlocks) && elInnerBlocks.length > 0) {
						return (
							<li key={clientId}>
								<RawHTML value={attributes.content} tagName="span" />
								<ArticleContent
									className=""
									blocks={elInnerBlocks}
									post={{ content: {} }}
								/>
							</li>
						);
					}

					return <RawHTML value={attributes.content} tagName="li" key={clientId} />;
				})}
			</TagName>
		);
	}

	// Old behaviour previous to 6.1
	return (
		<RawHTML
			tagName={TagName}
			value={values}
			className={classNames(classNameFromEditor)}
			id={anchor}
		/>
	);
};

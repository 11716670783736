import md5 from 'md5';
import CardWithOverlay from 'components/Content/CardWithOverlay'; /* eslint-disable no-unused-vars */

export const OverlayCard = ({
	title,
	media,
	ctaLabel,
	ctaLink = { url: '', openInNewTab: false },
}) => {
	const { url, openInNewTab } = ctaLink;
	return (
		<CardWithOverlay
			img={media}
			title={title}
			url={url}
			openInNewTab={openInNewTab}
			ctaLabel={ctaLabel}
			ctaAccessibleLabel={`Read more about ${title}`}
		/>
	);
};

export const map = {
	'starbucks-blocks/overlay-card': OverlayCard,
	[md5('starbucks-blocks/overlay-card')]: OverlayCard,
};

/* eslint-disable no-unused-vars */
import React from 'react';
import md5 from 'md5';
import { RawHTML } from 'components/Html/Raw/html';

export const Recipe = (props) => {
	const {
		title,
		servings,
		ingredients = [],
		ingredientsTitle = 'Ingredients',
		directions,
		directionsTitle = 'Directions',
	} = props;

	return (
		<section className="hrecipe recipe" itemScope itemType="https://schema.org/Recipe">
			<header className="recipe__header">
				<RawHTML tagName="h3" className="recipe__title" value={title} />
				{servings && (
					<div className="recipe__meta">
						<RawHTML tagName="div" className="recipe__servings" value={servings} />
					</div>
				)}
			</header>

			<div className="recipe__content">
				{ingredients && (
					<div className="recipe__ingredients">
						<RawHTML
							tagName="h4"
							className="recipe__ingredients-title"
							value={ingredientsTitle}
						/>

						{ingredients.map((group, index) => {
							const { heading = '', items = '' } = group;

							items.replaceAll(
								'<li>',
								'<li className="recipe__ingredients-item" itemprop="recipeIngredient">',
							);

							return (
								<>
									<RawHTML
										tagName="h6"
										className="recipe__ingredients-group-heading"
										value={heading}
									/>
									<RawHTML
										tagName="ul"
										className="recipe__ingredients-items"
										value={items}
									/>
								</>
							);
						})}
					</div>
				)}
				{directions && (
					<div className="recipe__directions">
						<RawHTML
							tagName="h4"
							className="recipe__directions-title"
							value={directionsTitle}
						/>
						<RawHTML
							tagName="div"
							className="recipe__directions-content"
							value={directions}
							itemProp="recipeInstructions"
						/>
					</div>
				)}
			</div>
		</section>
	);
};

export const map = {
	'starbucks-blocks/recipe': Recipe,
	[md5('starbucks-blocks/recipe')]: Recipe,
};
